import { DiaryStep } from '../types';

export let DiarySteps: DiaryStep[] = [
    {
        id: "331e7233-63c6-427a-800b-e04ef26e5310",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Find out what your family crest is from Sir Renitee.",
        quests: []
    }, {
        id: "0c688d21-00c2-4adb-8cb4-f2af6980e11e",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Climb over the western Falador wall.",
        quests: []
    }, {
        id: "20816b14-42c5-4908-8bbb-eead1da6bb49",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Browse Sarah's Farming Shop.",
        quests: []
    }, {
        id: "bea160f9-99c8-4c37-8072-4b657ad7680a",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Get a haircut from the Falador hairdresser.",
        quests: []
    }, {
        id: "9f6f5ccb-9ce3-4c51-95a3-198aea7ef164",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Fill a bucket from the pump north of Falador West Bank.",
        quests: []
    }, {
        id: "5381fdc3-66c5-4f31-acff-bd4773cfc1eb",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Kill a duck in Falador Park.",
        quests: []
    }, {
        id: "eda0da1a-2a79-4b30-9740-118501554503",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Make a mind tiara.",
        quests: []
    }, {
        id: "456c41c2-a97e-457e-afc0-a09177404c0e",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Take the boat to Entrana.",
        quests: []
    }, {
        id: "cb3d9aab-914d-49e3-ac0d-bef09968119c",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Repair a broken strut in the Motherlode Mine.",
        quests: []
    }, {
        id: "69e25021-d6a3-490b-9ec6-1d62a0a9a669",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Claim a security book from the Security Guard upstairs at Port Sarim jail.",
        quests: []
    }, {
        id: "a28ebcc2-0b5d-497a-98e8-af3a50933eaf",
        tier: 1,
        diary: "falador",
        difficulty: "easy",
        task: "Smith some Blurite limbs on Doric's anvil.",
        quests: ["The Knight's Sword", "Doric's Quest"]
    }, {
        id: "f6d93bbc-28ee-4239-a001-4144c7246091",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Light a bullseye lantern at the Chemist's in Rimmington.",
        quests: []
    }, {
        id: "7d2c3714-f0c9-4f5a-9af6-e5f37e99c162",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Telegrab some Wine of zamorak at the Chaos Temple by the Wilderness",
        quests: []
    }, {
        id: "a363f08f-cbe9-4a85-877b-9001cecbef6f",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Unlock the crystal chest in Taverley.",
        quests: []
    }, {
        id: "1e895532-2658-4f1b-800a-be103a73f2dc",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Place a Scarecrow in the Falador farm flower patch.",
        quests: []
    }, {
        id: "10d1f5ca-1bff-4a5f-bc70-6c95ffe5f8cc",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Kill a Mogre at Mudskipper Point.",
        quests: ["Skippy and the Mogres"]
    }, {
        id: "5e88f8fc-79a7-4a5b-a500-12f204b39e1e",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Visit the Port Sarim Rat Pits.",
        quests: ["Ratcatchers"]
    }, {
        id: "cb7732b0-5135-475d-b22d-5297581d9aca",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Grapple up and then jump off the north Falador wall.",
        quests: []
    }, {
        id: "e3dcdc1e-2520-4d63-8be0-5cefe4ba96aa",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Pickpocket a Falador guard.",
        quests: []
    }, {
        id: "93becf91-e5a6-4a8b-bd69-0843850e3677",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Pray at the Altar of Guthix in Taverley whilst wearing full Initiate armour.",
        quests: ["Recruitment Drive"]
    }, {
        id: "b5149e65-18e7-4813-a46c-2875c50c5255",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Mine some gold ore at the Crafting Guild.",
        quests: []
    }, {
        id: "ae0d7973-24c4-4db4-98ec-99bcd4ff88f6",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Squeeze through the crevice in the Dwarven Mines.",
        quests: []
    }, {
        id: "76177bec-f900-412b-966b-f78202b68e0f",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Chop and burn some willow logs in Taverley",
        quests: []
    }, {
        id: "731cb34d-0dd6-4eac-8141-5602dcb81cfe",
        tier: 2,
        diary: "falador",
        difficulty: "medium",
        task: "Craft a basket on the Falador farm loom.",
        quests: []
    }, {
        id: "9a7ab965-f5ce-48d6-8518-ab88084ae04a",
        tier: 1,
        diary: "falador",
        difficulty: "medium",
        task: "Teleport to Falador.",
        weight: 3,
        quests: []
    }, {
        id: "26c4c41c-1112-4495-8e25-82c114e21ecf",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Craft 140 mind runes simultaneously.",
        quests: []
    }, {
        id: "878029b0-77dc-4a97-9435-5a5f2c3bff1d",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Change your family crest to the Saradomin symbol.",
        quests: []
    }, {
        id: "23c839a0-1e60-4f96-830e-01bce0d7adfe",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Kill the Giant Mole beneath Falador Park.",
        weight: 3,
        quests: []
    }, {
        id: "8d3ca2f8-5385-4d38-a97e-4f12e623c62c",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Kill a Skeletal Wyvern in the Asgarnia Ice Dungeon.",
        quests: []
    }, {
        id: "0b63d2fb-2718-411a-8db1-d7cb72a60e0a",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Complete a lap of the Falador Rooftop Agility Course.",
        weight: 3,
        quests: []
    }, 
    // {
    //     id: "f9a3e90b-7222-4516-af57-81fd636ce4e0",
    //     tier: 3,
    //     diary: "falador",
    //     difficulty: "hard",
    //     task: "Enter the Mining Guild wearing full Prospector.",
    //     quests: []
    // }, 
    {
        id: "751b85d4-a6ba-4d52-9b31-9f2a6c6a1060",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Kill the blue dragon under the Heroes' Guild.",
        quests: ["Heroes' Quest"]
    }, {
        id: "d064ae33-d447-403c-bde6-9509efefb706",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Crack a wall safe within Rogues' Den.",
        quests: []
    }, {
        id: "b43b5639-0b7b-4e2c-8fbb-7287e2d99bb5",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Recharge your Prayer in the Port Sarim church while wearing full Proselyte.",
        quests: ["The Slug Menace"]
    }, {
        id: "45c6e53b-4cb9-4de6-81c1-88b45fbd4fb6",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Enter the Warriors' Guild.",
        weight: 5,
        quests: []
    }, {
        id: "b8e8a45e-2185-429e-a6d7-2224cf6a0b9a",
        tier: 3,
        diary: "falador",
        difficulty: "hard",
        task: "Equip a Dwarven helmet within the Dwarven Mines.",
        quests: ["Grim Tales"]
    }, {
        id: "ef6f5b76-0fc1-46e2-905c-c2ba6494ef05",
        tier: 5,
        diary: "falador",
        difficulty: "elite",
        task: "Craft 252 air runes simultaneously.",
        quests: []
    }, {
        id: "04e15ad3-bae2-46ea-aea6-423f5756deca",
        tier: 5,
        diary: "falador",
        difficulty: "elite",
        task: "Purchase a white 2h sword from Sir Vyvin.",
        quests: ["Wanted!"]
    }, {
        id: "b932e30f-441d-4f9c-b965-8827eceed8f6",
        tier: 5,
        diary: "falador",
        difficulty: "elite",
        task: "Find at least 3 magic roots at once when digging up your magic tree in Falador.",
        quests: []
    }, {
        id: "f441e15a-1c48-4705-96c5-9ef2e05f8a5b",
        tier: 4,
        diary: "falador",
        difficulty: "elite",
        task: "Perform a Cape of Accomplishment or Quest point cape emote at the top of White Knights' Castle.",
        quests: ["All"]
    }, {
        id: "2c2263d1-9a2c-4198-9ed1-583a53f3986b",
        tier: 5,
        diary: "falador",
        difficulty: "elite",
        task: "Jump over the strange floor in Taverley Dungeon.",
        quests: []
    }, {
        id: "6df6bb5b-e217-4f6c-82aa-972027288523",
        tier: 5,
        diary: "falador",
        difficulty: "elite",
        task: "Mix a Saradomin brew in Falador East Bank.",
        quests: []
    }, {
        id: "d18f96a2-3539-41fc-83d5-af06c803d3d9",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Have Wizard Cromperty teleport you to the Rune essence mine.",
        quests: ["Rune Mysteries"]
    }, {
        id: "fa52dfad-39a4-45ff-9ca1-0c7a719818f5",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Steal a cake from the East Ardougne market stalls.",
        quests: []
    }, {
        id: "87ab9e1f-2fc7-472e-991c-ab0e061bae6a",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Sell silk to the Silk trader in East Ardougne for 60 coins each.",
        quests: []
    }, {
        id: "8ed10fbf-ab7f-4dcb-9960-3141dc15facc",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Use the altar in East Ardougne's church.",
        quests: []
    }, {
        id: "6a83b858-9d01-4b1c-b9e8-1bd139078df4",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Go out fishing on the Fishing Trawler.",
        quests: []
    }, {
        id: "2c405635-6e6f-415a-b3ab-78b9200cb710",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Enter the Combat Training Camp north of West Ardougne.",
        quests: ["Biohazard"]
    }, {
        id: "24fb962d-cde7-46f9-ab83-d117827a0472",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Have Tindel Marchant identify a rusty sword for you.",
        quests: []
    }, {
        id: "8951b771-edda-42db-bb98-9c24a2585164",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Use the Ardougne lever to teleport to the Wilderness.",
        quests: []
    }, {
        id: "721db6bd-ada7-4e09-8a94-2e5c97d43cf0",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "View Aleck's Hunter Emporium shop in Yanille.",
        quests: []
    }, {
        id: "16e242a5-eeb1-4a1b-9af1-a0fa9c44d7ce",
        tier: 1,
        diary: "ardougne",
        difficulty: "easy",
        task: "Check what pets you have insured with Probita in East Ardougne.",
        quests: []
    }, {
        id: "a2005aa7-9e4c-4e54-b995-551c87ea787b",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Enter the unicorn pen in Ardougne Zoo using Fairy rings.",
        quests: ["Fairytale II - Cure a Queen"]
    }, {
        id: "8b351fe0-3171-4d5b-84d9-0033bbc39812",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Grapple over Yanille's south wall and jump off.",
        quests: []
    }, {
        id: "392026f7-409c-44e9-afcf-f7e1a306c6e9",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Harvest some strawberries from the Ardougne farming patch.",
        quests: []
    }, {
        id: "9fe17cdc-4d1a-4415-b8ee-818fdeb83831",
        tier: 1,
        diary: "ardougne",
        difficulty: "medium",
        task: "Cast the Ardougne Teleport spell.",
        quests: ["Plague City"]
    }, {
        id: "2c46b909-6ecb-4746-8a42-1a852e7c2964",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Travel to Castle Wars by Hot Air Balloon.",
        quests: ["Enlightened Journey"]
    }, {
        id: "a34a52cd-b79d-4383-8697-0f06f869ea90",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Claim buckets of sand from Bert in Yanille.",
        quests: ["The Hand in the Sand"]
    }, {
        id: "280f300b-3529-4af2-9866-9e4dd1fe36bb",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Catch any fish on the Fishing Platform.",
        quests: ["Sea Slug"]
    }, {
        id: "195573a1-e908-4b29-8d1a-d414018c43cc",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Pickpocket the master farmer north of East Ardougne.",
        quests: []
    }, {
        id: "9cf70d2e-fed2-464c-908e-83ca203fb487",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Collect some cave nightshade from the Skavid caves.",
        quests: ["Watchtower"]
    }, {
        id: "ac52281e-4acf-4999-bcdf-7930b5a88361",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Kill a swordchick in the Tower of Life.",
        quests: ["Tower of Life"]
    }, {
        id: "56097336-8cee-4282-a3a1-8578da0f342e",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Equip an Iban's upgraded staff or upgrade an Iban's staff.",
        quests: ["Underground Pass"]
    }, {
        id: "99a7f3c8-8752-4170-b68a-cd5d56896e59",
        tier: 2,
        diary: "ardougne",
        difficulty: "medium",
        task: "Visit the island east of the Necromancer Tower.",
        quests: ["Fairytale II - Cure a Queen"]
    }, {
        id: "3aee5c44-6a9e-48b6-92c4-66923abc5e9f",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Recharge some jewellery at the Totem pole in the Legends' Guild.",
        quests: ["Legends' Quest"]
    }, {
        id: "d5dc15e3-40c4-4eb5-8970-8cb6a98f599f",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Enter the Magic Guild.",
        weight: 5,
        quests: []
    }, {
        id: "43be8d8c-2626-4769-b55d-29032dad5d06",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Attempt to steal from a chest in Ardougne Castle.",
        quests: []
    }, {
        id: "4befebf6-e7cf-45ff-ba49-6cc5c86206c1",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Have a zookeeper put you in Ardougne Zoo's monkey cage.",
        quests: ["Monkey Madness I"]
    }, {
        id: "cb25c8ac-1de4-4466-ad43-276ac0c527a8",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Teleport to the Watchtower.",
        weight: 3,
        quests: ["Watchtower"]
    }, {
        id: "4ce61c97-d408-4ae8-af3b-deee5cbb2006",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Catch a Red Salamander.",
        quests: []
    }, {
        id: "43a49e5c-013b-4730-a0bc-af17fd0a2676",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Check the health of a palm tree near Tree Gnome Village.",
        quests: []
    }, {
        id: "7b2da173-9632-4e8a-a080-f0f9a725100c",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Pick some poison ivy berries from the patch south of East Ardougne.",
        quests: []
    }, {
        id: "fa820230-3d32-4de6-978e-f877564d50f4",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Smith a Mithril platebody near Ardougne.",
        quests: []
    }, {
        id: "7e6c1dac-5092-4e4e-8f9d-0bc09cb061f5",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Enter your POH from Yanille.",
        quests: []
    }, {
        id: "f13e88d0-84e9-4a3d-b1dd-1a203c47d17b",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Smith a Dragon sq shield in West Ardougne.",
        quests: ["Legends' Quest"]
    }, {
        id: "e7ab5597-bcd3-4a56-92dd-fe1b7831e407",
        tier: 3,
        diary: "ardougne",
        difficulty: "hard",
        task: "Craft some death runes at the Death altar.",
        quests: ["Mourning's End Part II"]
    }, {
        id: "28d37f3b-d0ad-4d40-91fc-27f776c174b4",
        tier: 5,
        diary: "ardougne",
        difficulty: "elite",
        task: "Catch a manta ray in the Fishing Trawler and cook it in Port Khazard.",
        quests: []
    }, {
        id: "71bb18dc-279d-4672-ac6a-ec7763846e18",
        tier: 5,
        diary: "ardougne",
        difficulty: "elite",
        task: "Attempt to picklock the door to the basement of Yanille Agility dungeon.",
        quests: []
    }, {
        id: "d7931e27-9cc5-47e4-8a37-256ca3fb0334",
        tier: 4,
        diary: "ardougne",
        difficulty: "elite",
        task: "Pickpocket a hero.",
        quests: []
    }, {
        id: "ebd36736-d6bd-4f8a-b8bf-c127d48245cf",
        tier: 5,
        diary: "ardougne",
        difficulty: "elite",
        task: "Make a rune crossbow yourself from scratch within Witchaven or Yanille.",
        quests: []
    }, {
        id: "73e7abb0-af05-4745-87a5-78dccaa6f154",
        tier: 4,
        diary: "ardougne",
        difficulty: "elite",
        task: "Imbue a salve amulet at Nightmare Zone or equip a salve amulet(i).",
        weight: 5,
        quests: ["Haunted Mine"]
    }, {
        id: "409f2264-b4b9-44ea-b2ae-25665a2382c9",
        tier: 5,
        diary: "ardougne",
        difficulty: "elite",
        task: "Pick some torstol from the patch north of East Ardougne.",
        quests: []
    }, {
        id: "7629c595-c7c1-4ba8-b305-4c27e1afca60",
        tier: 5,
        diary: "ardougne",
        difficulty: "elite",
        task: "Complete a lap of Ardougne's rooftop agility course.",
        quests: []
    }, {
        id: "de8f130a-9e7c-47dd-b1d0-d43e470833db",
        tier: 5,
        diary: "ardougne",
        difficulty: "elite",
        task: "Cast Ice Barrage on another player within Castle Wars.",
        quests: ["Desert Treasure"]
    }, {
        id: "184fc7d9-badd-4a36-a825-8c4ee0ec4ae5",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Catch a golden warbler.",
        quests: []
    }, {
        id: "1ec42ee1-d5ca-46f3-a4b3-1200ff8cab1b",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Mine five clay in the north-eastern desert.",
        quests: []
    }, {
        id: "82510b19-c730-4656-833c-9042400f1b68",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Enter the Kalphite Hive.",
        quests: []
    }, {
        id: "4d8065a4-8fd6-413c-97c7-4bd592340d4f",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Enter the Desert with a set of desert robes equipped.",
        quests: []
    }, {
        id: "8e0b920f-9f7f-4db1-a366-456c5345ef48",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Kill a vulture.",
        quests: []
    }, {
        id: "a5ed6ea4-fba1-484a-958a-7a033986c27d",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Have the Nardah herbalist clean a herb for you.",
        quests: []
    }, {
        id: "293c236a-d8e9-40a4-9f44-d4793cc6a941",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Collect 5 potato cacti from the Kalphite Hive.",
        quests: []
    }, {
        id: "ce646446-2a21-468a-9957-8ecfbb598a7c",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Sell some artefacts to Simon Templeton.",
        quests: ["Icthlarin's Little Helper"]
    }, {
        id: "4f1e3e9b-f931-4580-b058-c0abf1e23ba3",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Open the sarcophagus in the first room of Pyramid Plunder.",
        quests: ["Icthlarin's Little Helper"]
    }, {
        id: "1be75332-60de-4020-a09b-8da067567db6",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Cut a desert cactus open to fill a waterskin.",
        quests: []
    }, {
        id: "63b51f91-48cb-479d-8194-dc29b51d960c",
        tier: 1,
        diary: "desert",
        difficulty: "easy",
        task: "Travel from the Shantay Pass to Pollnivneach by magic carpet.",
        quests: []
    }, {
        id: "4afc2bd8-2d59-4627-8e0c-c6161b343991",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Climb to the summit of the Agility Pyramid.",
        quests: []
    }, {
        id: "0e755a82-a4c4-499f-818d-f2792c7a6009",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Slay a desert lizard.",
        quests: []
    }, {
        id: "f4400487-61ee-453d-8ab7-076ca91e6dea",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Catch an orange salamander.",
        quests: []
    }, {
        id: "23a04356-cebc-407e-bba1-871cdb80e25e",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Steal a Phoenix feather from the Desert phoenix.",
        quests: []
    }, {
        id: "2d14881b-5a05-4f13-a652-a4e86258b46c",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Travel to Uzer via magic carpet.",
        quests: ["The Golem"]
    }, {
        id: "f0ba86f4-13bc-4bc4-bd6b-ff8576ef4ada",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Travel to the desert via the Eagle transport system.",
        quests: ["Eagles' Peak"]
    }, {
        id: "0852a24c-92fa-4434-aacb-1885cdff8e1c",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Pray at the Elidinis Statuette in Nardah.",
        quests: ["Spirits of the Elid"]
    }, {
        id: "7ad5e031-f92b-429e-9264-f9472723503b",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Create a combat potion in the desert.",
        quests: []
    }, {
        id: "47f6437a-05d8-4c8b-952d-9dea22587bfe",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Teleport to Enakhra's Temple with the Camulet.",
        quests: ["Enakhra's Lament"]
    }, {
        id: "b88b8724-2914-4b9c-b914-4db1362dc4a3",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Visit the genie.",
        quests: ["Spirits of the Elid"]
    }, {
        id: "9fef6f03-e3c0-4219-98fc-8d1e6e4e2c34",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Enter your Player-owned house from Pollnivneach.",
        quests: []
    }, {
        id: "1c5569ef-fe00-414f-8cc9-ce584dc3bb5e",
        tier: 2,
        diary: "desert",
        difficulty: "medium",
        task: "Chop some teak logs near Uzer.",
        quests: []
    }, {
        id: "3dd8a795-ccb3-48ae-bf41-399615a664c4",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Knock out and pickpocket a Menaphite Thug.",
        weight: 3,
        quests: ["The Feud"]
    }, {
        id: "36a9da97-1d97-46e3-bba3-5d36f4d09a3a",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Mine some granite.",
        quests: []
    }, {
        id: "2a6098b0-1678-4ace-aae9-3897b233cbaa",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Refill your waterskins in the Desert using Lunar spells.",
        quests: ["Dream Mentor"]
    }, {
        id: "c099d3d8-4efc-4c16-b35d-1434e6ed5d1d",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Kill the Kalphite Queen.",
        quests: []
    }, {
        id: "d9acbe37-55af-45fa-99fd-d01212f6026a",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Complete a lap of the Pollnivneach Rooftop Course.",
        quests: []
    }, {
        id: "77a0d731-38d2-4726-9c48-fe08aeeb3bbd",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Slay a Dust devil in the desert cave with a Slayer helmet equipped.",
        weight: 2,
        quests: ["Desert Treasure", "Cabin Fever"]
    }, {
        id: "bf8bad47-3028-4320-ad3c-698674100944",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Activate Ancient Magicks at the altar in the Jaldraocht Pyramid.",
        weight: 5,
        quests: ["Desert Treasure"]
    }, {
        id: "965dbf2a-948d-4816-b3dc-cb4257055244",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Defeat a locust rider with Keris.",
        quests: ["Contact!"]
    }, {
        id: "48a097b3-43b9-44ab-849b-dac4579055c5",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Burn some yew logs on the Nardah Mayor's balcony.",
        quests: []
    }, {
        id: "a0c83982-952b-4fe8-b228-d32c97f302de",
        tier: 3,
        diary: "desert",
        difficulty: "hard",
        task: "Create a Mithril platebody in Nardah.",
        quests: []
    }, {
        id: "b674e382-6bfe-4a12-8d6c-482f8679c954",
        tier: 5,
        diary: "desert",
        difficulty: "elite",
        task: "Bake a wild pie at the Nardah clay oven.",
        quests: []
    }, {
        id: "e6d53d2e-a40b-412a-972b-9df3f21c47e6",
        tier: 5,
        diary: "desert",
        difficulty: "elite",
        task: "Cast Ice Barrage against a foe in the Desert.",
        quests: ["Desert Treasure"]
    }, {
        id: "e31976c8-b2fa-4370-9362-8cb92bcf7948",
        tier: 5,
        diary: "desert",
        difficulty: "elite",
        task: "Fletch some Dragon darts at the Bedabin Camp.",
        quests: ["The Tourist Trap"]
    }, {
        id: "847ca278-69cd-4bb6-96c8-6ca08a8ea9b1",
        tier: 5,
        diary: "desert",
        difficulty: "elite",
        task: "Steal from the Grand Gold Chest in the final room of Pyramid Plunder.",
        quests: ["Icthlarin's Little Helper"]
    }, {
        id: "b2fe86d3-5da0-4277-a92e-d0529be5eac3",
        tier: 5,
        diary: "desert",
        difficulty: "elite",
        task: "Restore at least 85 Prayer points when praying at the altar in Sophanem.",
        quests: ["Icthlarin's Little Helper"]
    }, {
        id: "d6c73e50-b0ae-4e30-8be3-5245ddbd92f8",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Catch a cerulean twitch",
        quests: []
    }, {
        id: "4787f2dd-b5b1-47ad-a548-2f7acfc96fbe",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Change your boots at Yrsa's Shoe Store.",
        quests: ["The Fremennik Trials"]
    }, {
        id: "bbceeaab-e545-44e6-972a-86911be08aeb",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Kill 5 Rock Crabs.",
        quests: []
    }, {
        id: "75baad67-6f5b-46d0-a09e-9c5f7986d9be",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Craft a tiara from scratch in Rellekka.",
        quests: ["The Fremennik Trials"]
    }, {
        id: "de29ed6c-502a-468a-921a-8c5b952b1aa4",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Browse the Stonemason's shop",
        quests: ["The Giant Dwarf"]
    }, {
        id: "2d43d205-dd8f-4883-bce2-4ffebda9a242",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Collect 5 snape grass on Waterbirth Island.",
        quests: []
    }, {
        id: "e97d6d26-69a3-4345-ba22-7ddf6e1017fd",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Steal from the Keldagrim crafting or baker's stall",
        quests: ["The Giant Dwarf"]
    }, {
        id: "0d14ea0c-380c-4291-8d74-5d14cf574bf4",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Fill a bucket with water at the Rellekka well. Found near entrance, south east of long hall.",
        quests: []
    }, {
        id: "73b71430-4b58-4c01-bedc-163cfbe29f31",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Enter the Troll Stronghold.",
        quests: ["Troll Stronghold", "Death Plateau"]
    }, {
        id: "8535ae99-e618-43e5-82a7-8c5192db48c5",
        tier: 1,
        diary: "fremennik",
        difficulty: "easy",
        task: "Chop and burn some oak logs in the Fremennik Province.",
        quests: []
    }, {
        id: "0dccb2d3-4220-48fa-a9ef-9badbf7f6f7f",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Slay a brine rat.",
        quests: ["Olaf's Quest"]
    }, {
        id: "25f14ee2-3099-4513-8096-19bbca0c685f",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Travel to the Snowy Hunter Area via Eagle.",
        quests: ["Eagles' Peak"]
    }, {
        id: "31625469-281a-4a89-b0ac-f821ed436c78",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Mine some coal in Rellekka.",
        quests: ["The Fremennik Trials"]
    }, {
        id: "82dd4964-3ff6-40ef-abad-1146bc98404a",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Steal from the Rellekka fish stalls",
        quests: ["The Fremennik Trials"]
    }, {
        id: "b5dd5e44-1fc6-464f-9bb0-97f1f6fff0f7",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Travel to Miscellania by fairy ring.",
        quests: ["The Fremennik Trials", "Fairytale II - Cure a Queen"]
    }, {
        id: "31d637fe-88b8-442d-83c6-affd3f186477",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Catch a Snowy knight.",
        quests: []
    }, {
        id: "9ce66c88-5126-4b03-a659-99a35a1f11d2",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Pick up your pet rock from your POH menagerie.",
        quests: ["The Fremennik Trials"]
    }, {
        id: "ee7f95c1-180b-4424-8889-13159e7ef3f9",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Visit the Lighthouse from Waterbirth Island.",
        quests: ["Horror from the Deep", "The Fremennik Trials"]
    }, {
        id: "4cfaac58-b77d-4f75-861f-6d549d742148",
        tier: 2,
        diary: "fremennik",
        difficulty: "medium",
        task: "Mine some gold at the Arzinian Mine.",
        quests: ["Between a Rock..."]
    }, {
        id: "7641e31b-93c9-4ff3-8a41-00197d5d6cfd",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Teleport to Trollheim.",
        weight: 3,
        quests: ["Eadgar's Ruse"]
    }, {
        id: "953b69e8-6a59-492a-b2e6-678640acf3ad",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Catch a Sabre-toothed kyatt.",
        quests: []
    }, {
        id: "319767a8-d760-43eb-a8a5-5444d687b2c0",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Mix a Super defence potion in the Fremennik Province.",
        quests: []
    }, {
        id: "69bc689b-8807-4068-bd1b-fa757b7b69da",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Steal from the Keldagrim Gem Stall.",
        quests: ["The Giant Dwarf"]
    }, {
        id: "861dde1f-2bda-4210-aa48-69c85e3d388f",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Craft a Fremennik shield on Neitiznot.",
        quests: ["The Fremennik Isles"]
    }, {
        id: "df103001-8d2b-4a8a-8e09-1dcf6f5fb90b",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Mine 5 adamantite ores on Jatizso.",
        quests: ["The Fremennik Isles"]
    }, {
        id: "8c894cca-257f-4ef6-ba92-1d707726b379",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Obtain 100% support from your kingdom subjects.",
        weight: 5,
        quests: ["Throne of Miscellania"]
    }, {
        id: "788d644d-8bd7-4509-a37b-dbd60d317e83",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Teleport to Waterbirth Island.",
        quests: ["Lunar Diplomacy"]
    }, {
        id: "ee66c586-ec66-43ef-be19-33495b075ea6",
        tier: 3,
        diary: "fremennik",
        difficulty: "hard",
        task: "Obtain the Blast Furnace Foreman's permission to use the Blast Furnace for free.",
        weight: 2,
        quests: ["The Giant Dwarf"]
    }, {
        id: "27448dfe-80b2-4d35-8e55-1afcdb97807f",
        tier: 4,
        diary: "fremennik",
        difficulty: "elite",
        task: "Kill each of the Dagannoth Kings.",
        weight: 2,
        quests: []
    }, {
        id: "c593b60d-850f-4522-8748-739d16750f33",
        tier: 5,
        diary: "fremennik",
        difficulty: "elite",
        task: "Craft 56 astral runes at once.",
        quests: ["Lunar Diplomacy"]
    }, {
        id: "bad98ff5-28d6-4128-b3f2-a03e3507e4c0",
        tier: 4,
        diary: "fremennik",
        difficulty: "elite",
        task: "Create a dragonstone amulet in the Neitiznot furnace.",
        quests: ["The Fremennik Isles"]
    }, {
        id: "749380d6-9dc4-41d8-b940-a85480073361",
        tier: 5,
        diary: "fremennik",
        difficulty: "elite",
        task: "Complete a lap of the Rellekka Rooftop Course.",
        quests: []
    }, {
        id: "2291cd7f-3df1-4cc7-8517-c64e7c01adce",
        tier: 4,
        diary: "fremennik",
        difficulty: "elite",
        task: "Kill each of the God Wars Dungeon generals.",
        weight: 2,
        quests: ["Troll Stronghold"]
    }, {
        id: "b3243137-8e6e-45d3-a176-d828ec29e71a",
        tier: 4,
        diary: "fremennik",
        difficulty: "elite",
        task: "Slay a Spiritual mage within the God Wars Dungeon.",
        quests: ["Troll Stronghold"]
    }, {
        id: "5029627d-41af-4545-bb2f-f646140ed3c4",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Catch a mackerel at Catherby.",
        quests: []
    }, {
        id: "e616b0a0-59ca-47a8-b672-959e8f2710ed",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Buy a candle from the candle maker in Catherby.",
        quests: []
    }, {
        id: "3f4453da-16ce-495e-82b1-4aba6434fe59",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Collect 5 flax from the Seers' Village flax field.",
        quests: []
    }, {
        id: "77688ba8-df5b-4018-9671-7958266b4a62",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Play the Church organ in the Seer's Village church.",
        quests: []
    }, {
        id: "231309d0-0ae2-46ef-b8e8-3bea03bb9bdd",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Plant jute seeds in the farming patch north of McGrubor's Wood.",
        quests: []
    }, {
        id: "81f59be7-6acd-4b4f-b528-5588761b2cca",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Have Galahad make you a cup of tea.",
        quests: []
    }, {
        id: "c519e140-0959-4211-af18-a1c171620b94",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Defeat one of each elemental in the Elemental workshop",
        quests: ["Elemental Workshop I"]
    }, {
        id: "c559275e-f71a-4228-a78e-6b8534f6507e",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Get a pet fish from Harry in Catherby.",
        quests: []
    }, {
        id: "05b0e91c-1ad7-450a-98ad-640fb2505219",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Buy a stew from the Seers' Village pub.",
        quests: []
    }, {
        id: "aab2e7c0-e041-4bd8-be48-e1fd27c3d741",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Speak to Sherlock between the Sorcerer's Tower and Keep Le Faye.",
        quests: []
    }, {
        id: "931468a5-f61f-42e4-ad5c-ba7a775bfe47",
        tier: 1,
        diary: "kandarin",
        difficulty: "easy",
        task: "Cross the Coal truck log shortcut.",
        quests: []
    }, {
        id: "4f5b0123-c532-468e-8a66-d0bd96be366c",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Complete a lap of the Barbarian agility course.",
        quests: []
    }, {
        id: "3810c42c-5648-447c-9e51-c5297856940d",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Create a Superantipoison potion from scratch in the Seers/Catherby area.",
        quests: []
    }, {
        id: "a75d7ec8-958f-4590-b27e-77863d21cc0a",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Enter the Ranging Guild.",
        quests: []
    }, {
        id: "159a91e1-f13b-4024-b108-60eb04807dff",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Use the grapple shortcut to get from the water obelisk to Catherby shore.",
        quests: []
    }, {
        id: "3254c105-2bc1-46de-991e-78d2db457350",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Catch and cook a bass in Catherby.",
        quests: []
    }, {
        id: "c66bebac-4243-4108-b17a-fb21ebb86e29",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Teleport to Camelot.",
        weight: 3,
        quests: []
    }, {
        id: "656f950d-27f8-41a0-a79a-555cee85539c",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "String a maple shortbow in Seers' Village bank.",
        quests: []
    }, {
        id: "ce3074c4-4595-4b76-99e6-87dccc7610ac",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Pick some limpwurt root from the farming patch in Catherby.",
        quests: []
    }, {
        id: "90ddfd28-7c5d-4412-bf21-f7987d03b236",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Create a mind helmet.",
        quests: ["Elemental Workshop II"]
    }, {
        id: "93f51c22-66fd-4ce4-955a-06cbe66e4435",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Kill a fire giant in the Waterfall Dungeon.",
        quests: ["Waterfall Quest"]
    }, {
        id: "33282c4d-58b4-4849-aa3e-acbf5b912009",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Complete a wave of Barbarian Assault.",
        quests: []
    }, {
        id: "cde1b41b-a6f1-4090-9690-1cef7e24c5f7",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Steal from the chest in Hemenster.",
        quests: []
    }, {
        id: "fdf8d5de-459e-4774-97ee-b625e6afcc77",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Travel to McGrubor's Wood by Fairy ring.",
        quests: ["Fairytale II - Cure a Queen"]
    }, {
        id: "9db6d2ff-b7ef-47e1-ab1f-efbe2dc86241",
        tier: 2,
        diary: "kandarin",
        difficulty: "medium",
        task: "Mine some coal near the coal trucks.",
        quests: []
    }, {
        id: "8f502b77-6813-4d39-819b-a7465c3b1a81",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Catch a leaping sturgeon.",
        weight: 3,
        quests: []
    }, {
        id: "1b48c93a-539e-400c-8b55-9fc902f5f37d",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Complete a lap of the Seers' Village Rooftop Course.",
        weight: 6,
        quests: []
    }, {
        id: "a09f7444-cebd-43ca-82b9-dc0bdcb2232f",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Create a yew longbow from scratch around Seers' Village.",
        quests: []
    }, {
        id: "8e41fc54-01f3-4766-8b94-c5fd565f6f17",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Enter the Seers' Village courthouse with Piety turned on.",
        quests: ["King's Ransom"]
    }, {
        id: "7f27a4e0-2d83-421b-9781-70e3a6734c57",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Charge a water orb.",
        quests: []
    }, {
        id: "5223538a-1a7f-4cab-8027-9bb801662048",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Burn some maple logs with a bow in Seers' Village.",
        quests: []
    }, {
        id: "6d83e899-9e0b-4fe0-944b-0c20d4ddf2c1",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Kill a shadow hound in the Shadow Dungeon.",
        quests: ["Desert Treasure"]
    }, {
        id: "232d0676-29e9-4849-8da8-f33d02d5c16e",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Kill a Mithril dragon.",
        quests: []
    }, {
        id: "2dc1e48b-afe9-4cd2-8244-d0c78f898a6a",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Purchase and equip a granite body from Barbarian Assault.",
        quests: []
    }, {
        id: "92c3a51f-13a7-47f9-a581-ce06d13fc307",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Have the Seers estate agent decorate your house with Fancy Stone.",
        quests: []
    }, {
        id: "5a90f944-f6ac-4737-b9a5-e5e401ea0e05",
        tier: 3,
        diary: "kandarin",
        difficulty: "hard",
        task: "Smith an adamant spear at Otto's Grotto.",
        quests: ["Tai Bwo Wannai Trio"]
    }, {
        id: "1b0d0c66-f56c-478c-a444-3b37f0954057",
        tier: 5,
        diary: "kandarin",
        difficulty: "elite",
        task: "Read the blackboard at Barbarian Assault after reaching level 5 in every role.",
        quests: []
    }, {
        id: "8bdbddcc-0f0d-4dce-92c2-4f892d1df488",
        tier: 4,
        diary: "kandarin",
        difficulty: "elite",
        task: "Pick some dwarf weed from the herb patch at Catherby.",
        quests: []
    }, {
        id: "7c583a87-ccfd-4dde-a168-30b577536c17",
        tier: 4,
        diary: "kandarin",
        difficulty: "elite",
        task: "Fish and cook 5 sharks in Catherby using the Cooking gauntlets.",
        quests: ["Family Crest"]
    }, {
        id: "b6e0143c-252b-46f7-80d5-07aa40d40f91",
        tier: 5,
        diary: "kandarin",
        difficulty: "elite",
        task: "Mix a Stamina mix on top of the Seers' Village bank.",
        quests: []
    }, {
        id: "9ea8a725-5fe9-4fb3-9ad0-94a6ad5cbb03",
        tier: 5,
        diary: "kandarin",
        difficulty: "elite",
        task: "Smith a rune hasta at Otto's Grotto.",
        quests: []
    }, {
        id: "8ddd1e91-5a99-463d-aa4c-d3dccc0e1f97",
        tier: 5,
        diary: "kandarin",
        difficulty: "elite",
        task: "Construct a pyre ship from magic logs.",
        quests: []
    }, {
        id: "1690653f-a76c-42f2-b991-44c5dfed52f7",
        tier: 5,
        diary: "kandarin",
        difficulty: "elite",
        task: "Teleport to Catherby.",
        quests: ["Lunar Diplomacy"]
    }, {
        id: "55968cb7-7976-4c9d-9fff-a9c48d90c934",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Pick 5 bananas from the plantation located east of the volcano.",
        quests: []
    }, {
        id: "cb195725-5676-4cbd-afed-74a9666e144a",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Use the rope swing to travel to the Moss Giant Island north-west of Karamja.",
        quests: []
    }, {
        id: "2b8f3b86-d314-4245-8e5b-4fbe119223bd",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Mine some gold from the rocks on the north-west peninsula of Karamja.",
        quests: []
    }, {
        id: "452227d1-c2ef-45cb-b58d-0771e36fd42b",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Travel to Port Sarim via the dock, east of Musa Point.",
        quests: []
    }, {
        id: "443bb9e8-7eaf-4a98-85f9-70e3c734aa33",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Travel to Ardougne via the port near Brimhaven.",
        quests: []
    }, {
        id: "3460be2b-d119-4a84-ba25-d80a363c9c63",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Explore Cairn Isle to the west of Karamja.",
        quests: []
    }, {
        id: "513cb940-94bb-46a4-9c99-d3a61f82a4f1",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Use the fishing spots north of the banana plantation.",
        quests: []
    }, {
        id: "5db54f58-6791-469b-b1a2-a8b3e637817f",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Collect 5 seaweed from anywhere on Karamja.",
        quests: []
    }, {
        id: "1d07e387-e2da-457c-831f-d4c969922c5d",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Attempt the TzHaar Fight Pits or Fight Cave.",
        quests: []
    }, {
        id: "7d43051b-3bac-448b-9445-b1c9528326a9",
        tier: 1,
        diary: "karamja",
        difficulty: "easy",
        task: "Kill a jogre in the Pothole Dungeon.",
        quests: []
    }, {
        id: "95d49976-df66-4d65-b352-80b547c02a5f",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Claim a ticket from the Agility arena in Brimhaven.",
        quests: []
    }, {
        id: "69ee6308-ce85-4cdd-a168-3b9902d56bc2",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Discover hidden wall in the dungeon below the volcano.",
        quests: ["Dragon Slayer"]
    }, {
        id: "22ca3260-3973-456b-898e-06f97799b66d",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Visit the Isle of Crandor via the dungeon below the volcano.",
        quests: ["Dragon Slayer"]
    }, {
        id: "c856d5de-e999-46b7-807a-ea93539c596c",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Use Vigroy and Hajedy's cart service.",
        quests: ["Shilo Village"]
    }, {
        id: "b9d93616-035a-43d8-a877-544e65a4692d",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Earn 100% favour in the Tai Bwo Wannai Cleanup minigame.",
        quests: ["Jungle Potion"]
    }, {
        id: "18c59c66-69db-4e9b-92f5-e4082c9a28c9",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Cook a Spider on stick.",
        quests: ["Jungle Potion"]
    }, {
        id: "5b60cde5-42d4-46f7-9cea-4da1d3d815cc",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Charter the Lady of the Waves from south of Cairn Isle to Port Khazard.",
        quests: ["Shilo Village"]
    }, {
        id: "2668fcd5-64b7-4cbc-b9a2-be25b7b5d3be",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Cut a log from a teak tree.",
        quests: ["Jungle Potion"]
    }, {
        id: "71526153-c31a-4d90-8a7a-8a16316b1d4c",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Cut a log from a mahogany tree.",
        quests: ["Jungle Potion"]
    }, {
        id: "2eb3a571-39b0-47d8-a432-02678f61352a",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Catch a karambwan.",
        weight: 6,
        quests: ["Tai Bwo Wannai Trio"]
    }, {
        id: "73b232be-5627-4af5-9718-676267ef9dfa",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Exchange gems with Safta Doc in the house with an anvil in the northern part of Tai Bwo Wannai for a machete.",
        quests: ["Jungle Potion"]
    }, {
        id: "37ed7aac-e2d2-4bab-90b6-41a2c63b44c0",
        tier: 1,
        diary: "karamja",
        difficulty: "medium",
        task: "Use the gnome glider to travel to Karamja.",
        quests: ["The Grand Tree"]
    }, {
        id: "86a484d4-62f2-46d9-816e-ecc998971f30",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Grow a healthy fruit tree in the patch near Brimhaven.",
        quests: []
    }, {
        id: "b26821a6-7d21-4231-81ce-91d241e37765",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Trap a Horned Graahk.",
        quests: []
    }, {
        id: "f0f748f0-8601-4390-bb61-ee18e5bfbe88",
        tier: 1,
        diary: "karamja",
        difficulty: "medium",
        task: "Chop the vines to gain deeper access to Brimhaven Dungeon.",
        quests: []
    }, {
        id: "e602cf90-0ed5-4f06-9492-8d2773e04a74",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Cross the lava using the stepping stones within Brimhaven Dungeon.",
        quests: []
    }, {
        id: "a215033b-90ab-46fb-8b67-17f326e9da60",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Climb the stairs within Brimhaven Dungeon.",
        quests: []
    }, {
        id: "3b39d717-674d-43a6-bd03-ed8c8c93dda4",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Charter a ship from the shipyard in the far east of Karamja.",
        quests: ["The Grand Tree"]
    }, {
        id: "df9a7afb-73c5-4025-86f6-84e3b9f23f4f",
        tier: 2,
        diary: "karamja",
        difficulty: "medium",
        task: "Mine a red topaz from a gem rock.",
        quests: ["Jungle Potion"]
    }, {
        id: "e03bb4b1-76f7-413c-9068-341046a7dab5",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Become the champion of the Fight Pit.",
        quests: []
    }, {
        id: "3d468098-d7a3-47c4-8b70-3624ef9db9e2",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Kill a Ket-Zek in the Fight Caves.",
        quests: []
    }, {
        id: "abe7d8a9-984b-4868-923b-e6f7cb1a004b",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Eat an oomlie wrap.",
        quests: []
    }, {
        id: "7025e2cf-3338-4aff-99b6-a56fef6d812c",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Craft some nature runes.",
        quests: []
    }, {
        id: "83b5c448-5ca4-45c2-a9fa-3499bd4170a0",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Cook a raw karambwan thoroughly.",
        weight: 6,
        quests: ["Tai Bwo Wannai Trio"]
    }, {
        id: "ee4f5871-65e1-4ab1-acca-e434526a0f78",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Kill a deathwing in the dungeon under the Kharazi Jungle.",
        quests: ["Legends' Quest"]
    }, {
        id: "32fe42dd-392a-45e6-9958-5eaa3ea468b4",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Use the crossbow shortcut south of the volcano.",
        quests: []
    }, {
        id: "f178d036-b975-4d90-9873-68caad082c35",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Collect 5 palm leaves.",
        quests: ["Legends' Quest"]
    }, {
        id: "bd4bca47-1943-402c-94fa-195248bf3bfc",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Be assigned a Slayer task by Duradel in Shilo Village.",
        quests: ["Shilo Village"]
    }, {
        id: "3e0495b9-68ca-4e1b-a488-ac701bdc6d5e",
        tier: 3,
        diary: "karamja",
        difficulty: "hard",
        task: "Kill a metal dragon in Brimhaven Dungeon.",
        quests: []
    }, {
        id: "0047abd5-d27c-4894-ab02-1a3c7cd8468c",
        tier: 5,
        diary: "karamja",
        difficulty: "elite",
        task: "Craft 56 nature runes at once.",
        quests: []
    }, {
        id: "777873ea-994a-4584-9112-6ad65dbf3f4d",
        tier: 4,
        diary: "karamja",
        difficulty: "elite",
        task: "Equip a fire cape or infernal cape in the Tzhaar city.",
        quests: []
    }, {
        id: "be72aaf4-df9b-4db3-a1b7-70b63e1e3197",
        tier: 4,
        diary: "karamja",
        difficulty: "elite",
        task: "Check the health of a palm tree in Brimhaven.",
        quests: []
    }, {
        id: "307711cf-e099-4ae4-adbf-b596cc3d014f",
        tier: 5,
        diary: "karamja",
        difficulty: "elite",
        task: "Create an antivenom potion whilst standing in the horse shoe mine.",
        quests: []
    }, {
        id: "f055251e-558b-4386-a6f8-249c98c803dc",
        tier: 3,
        diary: "karamja",
        difficulty: "elite",
        task: "Check the health of your Calquat tree patch.",
        quests: []
    }, {
        id: "48da96b5-170e-414f-89bf-699a450cf191",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Mine some iron ore at the Mount Karuulm mine.",
        quests: []
    }, {
        id: "13a1515c-ff80-49e0-8806-8636fbc31568",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Kill a Sand Crab.",
        quests: []
    }, {
        id: "abd75548-d366-4f14-a525-c9e8adcdd97f",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Hand in a book at the Arceuus Library.",
        quests: []
    }, {
        id: "91d1dd85-0836-46c2-9eea-981966d035b4",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Steal from a Hosidius Fruit Stall.",
        quests: []
    }, {
        id: "249314fb-02d7-4b1d-a0f5-e0aa2f3de3d4",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Browse the Warrens General Store.",
        quests: ["The Queen of Thieves"]
    }, {
        id: "98ff8a5b-e3e6-432f-a197-b68f713c7f66",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Take a boat to Land's End.",
        quests: []
    }, {
        id: "bfc36d19-27a3-498f-b0c7-8d32a74b50b5",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Pray at the altar on the top floor of the Kourend Castle.",
        quests: []
    }, {
        id: "92748561-09c1-4d93-98ae-e1491ccfa9a4",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Dig up some saltpetre. West of the Hosidius House Portal.",
        quests: []
    }, {
        id: "ea076866-b46f-4454-a890-0514c37b7224",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Enter your player-owned house from Hosidius.",
        quests: []
    }, {
        id: "0f2f01e3-59de-44a0-bf49-739b6e7e3a52",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Heal a wounded Shayzien soldier.",
        quests: []
    }, {
        id: "ec6b7182-bea7-4a18-8068-3163ead0c576",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Create a strength potion in the The Deeper Lode pub in Lovakengj.",
        quests: ["Druidic Ritual"]
    }, {
        id: "76d4de9c-7ddf-49d4-8c13-f3fb679140d9",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "easy",
        task: "Fish a Trout from the River Molch.",
        quests: []
    }, {
        id: "bc2a0950-f1ca-4426-b637-f6871322770a",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Travel to the fairy ring south of Mount Karuulm.",
        quests: ["Fairytale II - Cure a Queen"]
    }, {
        id: "dbfc3bf0-a5a3-464a-8c6a-ffb590cf185d",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Kill a lizardman.",
        quests: []
    }, {
        id: "80c64293-6cdf-4138-8621-af7e8ea3eb25",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Use Kharedst's memoirs to teleport to all five cities in Great Kourend.",
        quests: ["The Depths of Despair", "The Queen of Thieves", "Tale of the Righteous", "The Forsaken Tower", "The Ascent of Arceuus"]
    }, {
        id: "fa4b74de-2e56-41a6-8854-12ec68f5bc41",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Mine some volcanic sulphur.",
        quests: []
    }, {
        id: "49fe96c9-fe34-4a8e-86f1-210267b9dbf4",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Enter the Farming Guild.",
        quests: []
    }, {
        id: "6cb8273c-30ac-446a-8ec3-796e4ec0fb60",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Switch to the Arceuus spellbook via Tyss.",
        quests: []
    }, {
        id: "5e9ee649-ba11-4e40-989d-e98e4425b64e",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Repair a crane within Port Piscarilius.",
        quests: []
    }, {
        id: "f714ca14-362b-45c7-800e-654b47d3bb3c",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Deliver some intelligence to Captain Ginea.",
        quests: []
    }, {
        id: "1ebe5192-d4fe-4adc-9120-0d0d3456dbb0",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Catch a Bluegill on Lake Molch.",
        quests: []
    }, {
        id: "03ba20fa-5701-48ee-a749-6422da536c8b",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Use the boulder leap shortcut in the Arceuus essence mine.",
        quests: []
    }, {
        id: "f45b9e83-9469-4d54-876d-e3b1439308e3",
        tier: 1,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Subdue the Wintertodt.",
        quests: []
    }, {
        id: "1946ec95-9f9a-4694-b4a5-0014d9dc6bb7",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Catch a chinchompa in the Kourend Woodland.",
        quests: ["Eagles' Peak"]
    }, {
        id: "10b0179e-3f21-4679-972d-a1693ac40719",
        tier: 2,
        diary: "kourend & kebos",
        difficulty: "medium",
        task: "Chop some mahogany logs north of the Farming Guild.",
        quests: []
    }, {
        id: "b628d353-7a59-4c77-8599-766a9a6ac2bd",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Enter the Woodcutting Guild.",
        weight: 5,
        quests: []
    }, {
        id: "c869692a-3375-49f4-8265-d673599616a3",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Smelt an adamantite bar in The Forsaken Tower.",
        quests: ["The Forsaken Tower"]
    }, {
        id: "58c49112-0b68-41c3-be90-6b0b7df4d22d",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Kill a lizardman shaman in the Lizardman Temple.",
        quests: []
    }, {
        id: "4feaa5aa-1006-4751-9dc2-4a1bd7346a43",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Mine some Lovakite ore.",
        quests: []
    }, {
        id: "543a041c-5c28-44b4-98bd-2be7a022d577",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Plant some Logavano seeds at the Tithe Farm.",
        quests: []
    }, {
        id: "42127e54-d5c8-4c6b-bdc7-e045fa316122",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Kill a zombie in the Shayzien Crypts.",
        quests: []
    }, {
        id: "53dc1085-9051-4b9c-9ea7-d61f2ddd2178",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Teleport to Xeric's Heart using Xeric's talisman.",
        quests: ["Defeating the Culinaromancer"]
    }, {
        id: "c0b466e6-0e59-46ca-8c6c-206b84f4e972",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Deliver an artefact to Captain Khaled.",
        quests: []
    }, {
        id: "abd47929-9f6c-449d-8af8-370c810ad9b9",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Kill a wyrm in the Karuulm Slayer Dungeon.",
        quests: []
    }, {
        id: "f5e9e870-27b6-436b-94c2-524e492f1d59",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "hard",
        task: "Cast Monster Examine on a mountain troll south of Mount Quidamortem.",
        quests: ["Dream Mentor"]
    }, {
        id: "b9b6f377-5da9-4ab8-889a-41d3b7807528",
        tier: 4,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Craft one or more blood runes.",
        quests: []
    }, {
        id: "bd8d659f-8066-4259-852d-c0352c182c33",
        tier: 5,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Chop some redwood logs.",
        quests: []
    }, {
        id: "e908ebdf-fa4e-4977-b59e-7869d0d47aa0",
        tier: 3,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Defeat Skotizo in the Catacombs of Kourend.",
        weight: 3,
        quests: []
    }, {
        id: "9a15d945-2469-43a6-aec4-13761db355a1",
        tier: 4,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Catch an anglerfish and cook it whilst in Great Kourend.",
        quests: []
    }, {
        id: "f7f215f6-9f8d-40d9-9f36-bb3fd976a04b",
        tier: 5,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Kill a hydra in the Karuulm Slayer Dungeon.",
        quests: []
    }, {
        id: "d203d331-4772-4797-b7fe-4a27fdd4c896",
        tier: 4,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Create an Ape Atoll teleport tablet.",
        quests: []
    }, {
        id: "68a55d3a-a2f1-481d-a1ed-06232ab0e7e6",
        tier: 4,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Complete a raid in the Chambers of Xeric.",
        weight: 5,
        quests: []
    }, {
        id: "f2e721ee-3fcb-4c14-a8e7-efdde70b2019",
        tier: 5,
        diary: "kourend & kebos",
        difficulty: "elite",
        task: "Create your own battlestaff from scratch within the Farming Guild.",
        quests: []
    }, {
        id: "88d20a2f-e14f-4a33-b22c-f088f7fe289e",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Complete a lap of the Draynor Village Rooftop Course.",
        quests: []
    }, {
        id: "80ab3aaf-39ae-4671-bf99-5d7a4138fa7d",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Slay a cave bug in the Lumbridge Swamp Caves.",
        quests: []
    }, {
        id: "f0768db2-3761-4eec-9de9-20a8d782feee",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Have Sedridor teleport you to the Rune essence mine.",
        weight: 3,
        quests: ["Rune Mysteries"]
    }, {
        id: "d8a903da-cfe5-4757-869f-f9c7137b8720",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Craft some water runes.",
        quests: []
    }, {
        id: "a334f3f7-2fa6-49d5-981a-013344c4988c",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Learn your age from Hans in Lumbridge.",
        quests: []
    }, {
        id: "9b4092f8-5184-4d83-a458-3786453bc147",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Pickpocket a man or woman in Lumbridge.",
        quests: []
    }, {
        id: "f743b4cf-aee6-4ccd-add5-006cdd33d989",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Chop and burn some oak logs in Lumbridge.",
        quests: []
    }, {
        id: "c4328380-98ed-47bc-b2fb-51390e0aebe8",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Kill a zombie in the Draynor Sewers.",
        quests: []
    }, {
        id: "d2c3cb69-fecc-4825-a85f-ff1844bb18bb",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Catch some anchovies in Al-Kharid.",
        quests: []
    }, {
        id: "42997162-0802-4f85-97ae-f9bad96a8f94",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Bake some bread on the Lumbridge castle kitchen range.",
        quests: ["Cook's Assistant"]
    }, {
        id: "bebdaf76-80fb-47d8-91d2-6564696281c9",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Mine some iron ore at the Al-Kharid mine.",
        quests: []
    }, {
        id: "16fe6ac3-ff03-44cf-9719-22bf396cfe0e",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "easy",
        task: "Enter the H.A.M. Hideout.",
        quests: []
    }, {
        id: "564fce1f-0d9c-4868-980f-cf16b1880710",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Complete a lap of the Al Kharid Rooftop Course.",
        quests: []
    }, {
        id: "ccf29405-0218-40a4-9a57-7d4a3530650e",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Grapple across the River Lum.",
        quests: []
    }, {
        id: "8c187bdd-e23f-4fe8-8317-1ea570ba77c9",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Purchase an upgraded device from Ava.",
        quests: ["Animal Magnetism"]
    }, {
        id: "8c0de90f-f8a5-4710-bc0c-9948f394b9ec",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Travel to the Wizards' Tower by Fairy ring.",
        quests: ["Fairytale II - Cure a Queen"]
    }, {
        id: "12d4a318-e842-45ae-a3d3-9122ec9be172",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Cast the Lumbridge Teleport spell.",
        weight: 3,
        quests: []
    }, {
        id: "9387147b-f231-4ca7-bfaa-71d61f6f44b4",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Catch some salmon in Lumbridge.",
        quests: []
    }, {
        id: "1a5ca1f0-b250-42f2-a4db-307d49b53ed0",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Craft a coif in the Lumbridge cow pen.",
        quests: []
    }, {
        id: "b2ebcbbc-1ad5-4bc4-bf7c-d85081c7ef1a",
        tier: 1,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Chop some willow logs in Draynor Village.",
        quests: []
    }, {
        id: "5503adc5-7c80-4708-9a09-08e6f2f2ad82",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Pickpocket Martin the Master Gardener.",
        quests: []
    }, {
        id: "2ab884da-5917-4e88-8187-e944cb1504d1",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Get a Slayer task from Chaeldar.",
        quests: ["Lost City"]
    }, {
        id: "ebf9bbae-9939-4712-8a08-58e543203933",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Catch an essence or eclectic impling in Puro-Puro.",
        quests: ["Lost City"]
    }, {
        id: "d68e6ef6-82f8-4973-bfe0-4029bd9e0c85",
        tier: 2,
        diary: "lumbridge & draynor",
        difficulty: "medium",
        task: "Craft some lava runes at the fire altar in Al Kharid.",
        quests: []
    }, {
        id: "a71da0f1-5f28-49c4-85f5-8f73e1d26d69",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Cast Bones to Peaches in Al Kharid Palace.",
        quests: []
    }, {
        id: "d50261f9-657c-4aec-ae96-7e85355dc80c",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Squeeze past the jutting wall on your way to the cosmic altar.",
        quests: ["Lost City"]
    }, {
        id: "b5480158-9a82-4676-ae64-06f13a76ca14",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Craft 56 cosmic runes simultaneously.",
        quests: ["Lost City"]
    }, {
        id: "02de7632-1947-49e7-89cf-ad6688318807",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Travel from Lumbridge to Edgeville on a waka canoe.",
        weight: 3,
        quests: []
    }, {
        id: "e3fb718d-b9fb-458b-8531-72a105a15684",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Collect at least 100 Tears of Guthix in one visit.",
        weight: 5,
        quests: ["Tears of Guthix"]
    }, {
        id: "8ab89963-fae2-4f33-93a7-73ce48d84bc2",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Take the train from Dorgesh-Kaan to Keldagrim.",
        quests: ["Another Slice of H.A.M."]
    }, {
        id: "638b8fbf-97c4-4d0d-9286-4aefbb7af90d",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Pick some belladonna from the farming patch at Draynor Manor.",
        quests: []
    }, {
        id: "e2e53b90-24fb-4ac3-93f3-dbedcb092440",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Light your mining helmet in the Lumbridge Castle basement.",
        quests: []
    }, {
        id: "cd92ec92-cd8f-460e-b1f3-3d96334b5100",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Recharge your prayer at Duel Arena with Smite activated.",
        quests: []
    }, {
        id: "30bffa23-3368-4883-8595-91e24ede47ee",
        tier: 3,
        diary: "lumbridge & draynor",
        difficulty: "hard",
        task: "Craft, string and enchant an amulet of power in Lumbridge.",
        quests: []
    }, {
        id: "1e948835-7f70-4155-a00e-9db4ab9bbbbd",
        tier: 4,
        diary: "lumbridge & draynor",
        difficulty: "elite",
        task: "Steal from a Dorgesh-Kaan rich chest.",
        quests: ["Death to the Dorgeshuun"]
    }, {
        id: "714826d7-6012-4c20-9669-630308a79dd2",
        tier: 4,
        diary: "lumbridge & draynor",
        difficulty: "elite",
        task: "Pickpocket Movario on the Dorgesh-Kaan Agility Course.",
        quests: ["Death to the Dorgeshuun", "Temple of Ikov"]
    }, {
        id: "40ae131c-7e41-4d4c-9c72-3011b4adf545",
        tier: 4,
        diary: "lumbridge & draynor",
        difficulty: "elite",
        task: "Chop some magic logs at the Mage Training Arena.",
        quests: []
    }, {
        id: "613bc870-d8d2-4884-937a-2f2e7fa69170",
        tier: 5,
        diary: "lumbridge & draynor",
        difficulty: "elite",
        task: "Smith an adamant platebody down in Draynor Sewer.",
        quests: []
    }, {
        id: "f620bceb-edbc-4d64-992d-f3d9cb0a83cb",
        tier: 4,
        diary: "lumbridge & draynor",
        difficulty: "elite",
        task: "Craft 140 or more water runes at once.",
        quests: []
    }, {
        id: "647b58bb-ec70-4aa1-820c-5af73a7793ee",
        tier: 4,
        diary: "lumbridge & draynor",
        difficulty: "elite",
        task: "Perform the Quest point cape emote in the Wise Old Man's house.",
        quests: ["All"]
    }, {
        id: "70746b39-ee4d-475a-9f47-dffa1a29f7b2",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Craft any snelm from scratch in Morytania.",
        quests: ["Priest in Peril"]
    }, {
        id: "bb735ec3-9306-489f-932d-c3e0b9c5635a",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Cook a thin snail on the Port Phasmatys range.",
        quests: ["Ghosts Ahoy"]
    }, {
        id: "70aa9f71-05af-43c6-8f34-6cf2bb643b69",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Get a Slayer task from Mazchna.",
        quests: ["Priest in Peril"]
    }, {
        id: "5944e55b-e406-42a7-91f6-e615903a99d2",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Kill a banshee in the Slayer Tower.",
        quests: ["Priest in Peril"]
    }, {
        id: "9cfa34ba-63e1-4434-ad02-cac72d851c95",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Have Sbott tan something for you.",
        quests: ["Priest in Peril"]
    }, {
        id: "607f15fe-b93f-4b8e-964d-b7eb00a174c7",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Enter Mort Myre Swamp.",
        quests: ["Nature Spirit"]
    }, {
        id: "b3e94e0a-6265-445f-adb0-693ddf6ed55d",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Kill a ghoul.",
        quests: ["Priest in Peril"]
    }, {
        id: "8fba8dde-0d83-4437-ba76-2cdde3a0dc0b",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Place a Scarecrow in the Morytania Flower patch.",
        quests: ["Priest in Peril"]
    }, {
        id: "a3f89eb1-4e7b-4315-84c9-0cdb464aafaa",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Offer some Bonemeal at the Ectofuntus.",
        quests: ["Priest in Peril"]
    }, {
        id: "6f9d1c66-e5b9-44cd-b098-4bc3e0a2c4bb",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Kill a Werewolf in its human form using the Wolfbane dagger.",
        quests: ["Priest in Peril"]
    }, {
        id: "77f65aa0-3147-4877-b680-098e90ebc7bb",
        tier: 1,
        diary: "morytania",
        difficulty: "easy",
        task: "Restore your prayer points at the Nature Grotto.",
        quests: ["Nature Spirit"]
    }, {
        id: "6b3f6bbe-9121-441f-b392-6b9d04a88a60",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Catch a swamp lizard.",
        quests: ["Priest in Peril"]
    }, {
        id: "f2412367-fc57-458c-8ee1-deadec39b7a8",
        tier: 1,
        diary: "morytania",
        difficulty: "medium",
        task: "Complete a lap of the Canifis Rooftop Agility Course.",
        quests: ["Priest in Peril"]
    }, {
        id: "37c257ef-d1c2-4865-8f4f-fb7b6c00dcae",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Obtain some bark from a hollow tree.",
        quests: ["Priest in Peril"]
    }, {
        id: "f58926d7-8c07-4fef-bd60-e94e19e3b6f1",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Travel to Dragontooth Island.",
        quests: ["Priest in Peril"]
    }, {
        id: "331f9341-bb8b-4de0-8434-24e7e9dd7f54",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Kill a terror dog.",
        quests: ["Haunted Mine", "Lair of Tarn Razorlor"]
    }, {
        id: "154e2593-c075-4ea1-a91f-825ac91080c6",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Complete a game of Trouble Brewing.",
        quests: ["Cabin Fever"]
    }, {
        id: "c4fd3d6a-c901-481b-b735-c63551261c24",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Board the Swamp boaty at the Hollows.",
        quests: ["Priest in Peril"]
    }, {
        id: "77cea7b5-7d25-45fa-96b8-94be8a5f3df1",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Make a batch of cannonballs at the Port Phasmatys furnace.",
        quests: ["Dwarf Cannon", "Priest in Peril"]
    }, {
        id: "95e98df2-d943-4e25-887a-5cb4694cf043",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Kill a fever spider on Braindeath Island.",
        quests: ["Rum Deal", "Priest in Peril"]
    }, {
        id: "5cfbcb18-bd23-4052-9a29-3712229dc0d0",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Use an ectophial to return to Port Phasmatys.",
        quests: ["Ghosts Ahoy"]
    }, {
        id: "44d25419-d1a6-41d0-a195-3aadceefa1dd",
        tier: 2,
        diary: "morytania",
        difficulty: "medium",
        task: "Mix a Guthix balance potion while in Morytania.",
        quests: ["In Aid of the Myreque"]
    }, {
        id: "62e38dff-359d-4f05-973c-eab615a8fe32",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Enter the Kharyrll portal in your POH through a Portal Chamber.",
        quests: ["Desert Treasure"]
    }, {
        id: "3638d94e-5bc5-41ea-8d25-a060f84a4c12",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Climb the advanced spike chain within Slayer Tower.",
        quests: ["Priest in Peril"]
    }, {
        id: "a264aed6-6aa9-44aa-b288-22b755638c4c",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Harvest some watermelon from the allotment patch on Harmony Island.",
        quests: ["The Great Brain Robbery", "Priest in Peril"]
    }, {
        id: "4b79da5d-2b6e-47be-b32f-a7559061dd9c",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Chop and burn some mahogany logs on Mos Le'Harmless.",
        quests: ["Cabin Fever"]
    }, {
        id: "2f4174c8-ebaf-40c2-a6ea-b62b8301ce85",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Complete a temple trek with a hard companion.",
        quests: ["In Aid of the Myreque"]
    }, {
        id: "002b4e4c-0ebb-48fa-aa9c-011e71b5fb40",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Kill a cave horror.",
        weight: 5,
        quests: ["Cabin Fever"]
    }, {
        id: "25d01e8d-4c51-46b6-a7d6-df8c908c5e46",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Harvest some Bittercap mushrooms from the patch in Canifis.",
        quests: ["Priest in Peril"]
    }, {
        id: "06cd2d2e-a2f0-4eb3-8035-1c2782794a8f",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Pray at the Altar of Nature in the Nature Grotto with Piety activated.",
        quests: ["Nature Spirit", "King's Ransom"]
    }, {
        id: "50589c3b-77c3-40cd-9143-f356dd783810",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Use the shortcut to get to the bridge over the Salve.",
        quests: ["Priest in Peril"]
    }, {
        id: "77f53a1e-4650-42bc-a311-f838fdc84180",
        tier: 3,
        diary: "morytania",
        difficulty: "hard",
        task: "Mine some mithril ore in the Abandoned Mine.",
        quests: ["Haunted Mine"]
    }, {
        id: "1e6b4241-35de-4bd4-aa0f-96a17c0718fa",
        tier: 5,
        diary: "morytania",
        difficulty: "elite",
        task: "Catch a shark in Burgh de Rott with your bare hands.",
        quests: ["In Aid of the Myreque"]
    }, {
        id: "d87af804-8ea0-4e58-a098-073c743d22f2",
        tier: 4,
        diary: "morytania",
        difficulty: "elite",
        task: "Cremate any Shade remains on a Magic or Redwood pyre.",
        quests: ["Shades of Mort'ton"]
    }, {
        id: "7fa7cce4-13c0-4320-a1e9-84e2a87ae00b",
        tier: 4,
        diary: "morytania",
        difficulty: "elite",
        task: "Fertilize the Morytania herb patch using Lunar spells.",
        quests: ["Lunar Diplomacy", "Priest in Peril"]
    }, {
        id: "6bdf3ce1-ecfb-4024-84d5-2abfe87d7572",
        tier: 5,
        diary: "morytania",
        difficulty: "elite",
        task: "Craft a black dragonhide body in Canifis bank.",
        quests: ["Priest in Peril"]
    }, {
        id: "cf6cae2a-8453-4652-927c-564cfff8c588",
        tier: 5,
        diary: "morytania",
        difficulty: "elite",
        task: "Kill an abyssal demon in the Slayer Tower.",
        quests: ["Priest in Peril"]
    },
    {
        id: "f329f85b-bf97-460b-ae7e-a40907edc1fa",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Browse Thessalia's store.",
        quests: []
    }, {
        id: "117c959b-5993-4ebf-a76d-c62596ee266f",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Have Aubury teleport you to the essence mine.",
        quests: ["Rune Mysteries"]
    }, {
        id: "c0e82f95-6e9e-4ace-975f-baa14430e875",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Mine some iron in the south east mining patch near Varrock.",
        quests: []
    }, {
        id: "07c7da13-adb3-4897-a351-790ef84bd8e7",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Make a normal plank at the Sawmill.",
        quests: []
    }, {
        id: "8d8a47fa-662e-4770-8bf1-0098c2e747ed",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Enter the second level of the Stronghold of Security.",
        quests: []
    }, {
        id: "4785c44a-ba48-4e0c-8a7e-449999b2b1c0",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Jump over the fence south of Varrock.",
        quests: []
    }, {
        id: "db499a1a-a017-4541-9d9a-9ff50eb3b38a",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Chop down a dying tree in the Lumber Yard.",
        quests: []
    }, {
        id: "4506f2af-cbf6-4063-bcd0-67cc36009d1e",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Buy a newspaper.",
        quests: []
    }, {
        id: "1760d2f3-b9dc-47dd-a211-48bbc87751eb",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Give a dog a bone!",
        quests: []
    }, {
        id: "4c6e5195-2443-4732-a29b-3be74120097f",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Spin a bowl on the pottery wheel and fire it in the oven in Barbarian Village.",
        quests: []
    }, {
        id: "22e1f5eb-971f-4c03-a26d-bf2834198e4b",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Speak to Haig Halen after obtaining at least 50 Kudos.",
        quests: ["The Dig Site"]
    }, {
        id: "407e9819-bd93-4f76-a4e4-57b1ddac2c0e",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Craft some earth runes.",
        quests: []
    }, {
        id: "45e86dab-c9ab-4544-8795-23f7eeb89a6e",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Catch some trout in the River Lum at Barbarian Village.",
        quests: []
    }, {
        id: "861a1f16-28a3-475d-b3ec-55e2fdf3a620",
        tier: 1,
        diary: "varrock",
        difficulty: "easy",
        task: "Steal from the tea stall in Varrock.",
        quests: []
    }, {
        id: "f99bbff2-d918-4641-bd61-b7e798201d85",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Have the Apothecary in Varrock make you a Strength potion.",
        quests: []
    }, {
        id: "be32063e-b62d-4746-b2f5-ec2f8aebc6b0",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Enter the Champions' Guild.",
        quests: []
    }, {
        id: "a6f8bc78-8f88-4273-b479-6ddaf59925e1",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Select a colour for your kitten.",
        quests: ["Garden of Tranquillity", "Gertrude's Cat"]
    }, {
        id: "0b469bcf-d0ef-4ef7-8d8a-852cae47b49c",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Use the Spirit tree in the north-eastern corner of Grand Exchange.",
        quests: ["Tree Gnome Village"]
    }, {
        id: "6c51964a-e868-4451-a7aa-58ce44831fd7",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Perform the 4 emotes from the Stronghold of Security.",
        quests: []
    }, {
        id: "355b8610-0827-46c7-9c5f-81920f7f2cc1",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Enter the Tolna dungeon after completing A Soul's Bane.",
        quests: ["A Soul's Bane"]
    }, {
        id: "d2233101-d708-4c8e-bcd9-66936bf97382",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Teleport to the digsite using a Digsite pendant.",
        weight: 2,
        quests: ["The Dig Site"]
    }, {
        id: "5ddbdd28-b3bf-466c-9aaf-ed9883f76614",
        tier: 1,
        diary: "varrock",
        difficulty: "medium",
        task: "Cast the teleport to Varrock spell.",
        weight: 3,
        quests: []
    }, {
        id: "52d3e6b1-9168-48cf-84bc-913fcd496ef3",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Get a Slayer task from Vannaka.",
        quests: []
    }, {
        id: "3d751e49-f4b7-4730-81ba-9a70739ce23d",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Make 20 mahogany planks in one go.",
        quests: []
    }, {
        id: "e5228145-bb82-4c9f-ac2a-96d978ebe3fa",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Pick a white tree fruit.",
        quests: ["Garden of Tranquillity"]
    }, {
        id: "2ea09da5-bc5c-4844-8315-5b56be20782b",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Use the balloon to travel from Varrock.",
        quests: ["Enlightened Journey"]
    }, {
        id: "0928caca-07cd-4424-b241-4602c51c0420",
        tier: 2,
        diary: "varrock",
        difficulty: "medium",
        task: "Complete a lap of the Varrock Rooftop Course.",
        quests: []
    }, {
        id: "efde35e8-32f7-4479-a3c0-49af2aa856f5",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Trade furs with the Fancy Dress Seller for a Spottier cape and equip it.",
        quests: []
    }, {
        id: "6d7bcdce-c8ba-44a1-a25a-ecfc12dfa609",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Speak to Orlando Smith when you have achieved 153 Kudos.",
        quests: ["Curse of the Empty Lord"]
    }, {
        id: "793817eb-70c4-488e-ae1b-c7514170fa0f",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Make a Waka canoe near Edgeville.",
        weight: 3,
        quests: []
    }, {
        id: "7b0c88a8-d3b7-460f-ad2f-5da12e6bfce5",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Teleport to Paddewwa.",
        quests: ["Desert Treasure"]
    }, {
        id: "e39c169e-f8b3-4269-858f-29071e412ebf",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Teleport to Barbarian Village with a Skull sceptre.",
        weight: 3,
        quests: []
    }, {
        id: "08d09129-2d4c-4d84-b502-84fb1bbda84e",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Chop some yew logs in Varrock and burn them at the top of the Varrock church.",
        weight: 3,
        quests: []
    }, {
        id: "5ccc9db6-0a30-44ba-a67c-cf8a7a63d6af",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Have the Varrock estate agent decorate your house with Fancy Stone.",
        quests: []
    }, {
        id: "13a11ae2-7caf-4344-88c0-a71731eea8d5",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Collect at least 2 yew roots from the tree patch in Varrock Palace.",
        quests: []
    }, {
        id: "f8bb2e6d-23ff-44a5-ab87-c50a9ea73452",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Pray at the altar in Varrock Palace with Smite active.",
        weight: 2,
        quests: []
    }, {
        id: "8daa21dd-26f3-4f47-8ade-fe984011a4ad",
        tier: 3,
        diary: "varrock",
        difficulty: "hard",
        task: "Squeeze through the obstacle pipe in Edgeville Dungeon.",
        weight: 5,
        quests: []
    }, {
        id: "a1e01b1b-9bba-4695-b7ac-20005a9add1a",
        tier: 5,
        diary: "varrock",
        difficulty: "elite",
        task: "Create a Super combat potion in Varrock West Bank.",
        quests: ["Druidic Ritual"]
    }, {
        id: "603166a6-51db-4ad0-aac2-86b2ef43ac66",
        tier: 5,
        diary: "varrock",
        difficulty: "elite",
        task: "Use Lunar magic to make 20 mahogany planks in the Varrock Lumber Yard.",
        quests: ["Dream Mentor"]
    }, {
        id: "67d0471d-33ed-41e4-805d-27da71445a26",
        tier: 5,
        diary: "varrock",
        difficulty: "elite",
        task: "Bake a summer pie in the Cooking Guild.",
        quests: []
    }, {
        id: "7739fa34-c882-4209-b078-8f97d0e524d5",
        tier: 5,
        diary: "varrock",
        difficulty: "elite",
        task: "Smith and fletch ten rune darts within Varrock.",
        quests: ["The Tourist Trap"]
    }, {
        id: "1d56692a-5b3c-4858-bf0e-ccc09e71680a",
        tier: 5,
        diary: "varrock",
        difficulty: "elite",
        task: "Craft 100 or more earth runes simultaneously.",
        quests: []
    }, {
        id: "6d9f797b-068a-4468-ae08-4ffead26884e",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Catch a copper longtail.",
        quests: []
    }, {
        id: "84d13898-0582-493f-ac10-434540dbdfa0",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Complete a novice game of Pest Control.",
        quests: []
    }, {
        id: "ddf6b108-1ad1-4c22-b06a-d66bfc7d1b38",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Mine some iron ore near Piscatoris.",
        quests: []
    }, {
        id: "cf241ef7-ee57-42cd-bf07-0f3d3190c204",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Complete a lap of the Gnome Agility Course.",
        quests: []
    }, {
        id: "30994f9e-25c7-4ae4-a21f-d126dd60a220",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Score a goal in a Gnome Ball match.",
        quests: []
    }, {
        id: "304235b2-f1ca-4071-910f-14364772bbbe",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Claim any Chompy bird hat from Rantz.",
        quests: ["Big Chompy Bird Hunting"]
    }, {
        id: "c3526da3-d307-4c85-941b-4d198bcd8da7",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Teleport to Pest Control using the Minigame teleports.",
        quests: []
    }, {
        id: "117180aa-c1fc-4988-83fd-48449545f4ff",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Collect a swamp toad at the Gnome Stronghold.",
        quests: []
    }, {
        id: "055306cd-3457-4e6b-a654-8d486727c5fa",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Have Brimstail teleport you to the Essence mine.",
        quests: ["Rune Mysteries"]
    }, {
        id: "27055ab0-0d11-4068-8467-399844dc5a36",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Fletch an oak shortbow in the Gnome Stronghold.",
        quests: []
    }, {
        id: "b0ba7ce8-4bc9-4de8-8950-eb5b54852d1b",
        tier: 1,
        diary: "western provinces",
        difficulty: "easy",
        task: "Kill a terrorbird in the terrorbird enclosure.",
        quests: []
    }, {
        id: "be610ed4-ebdc-4430-9bf8-a17b891c449e",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Take the agility shortcut from the Grand Tree to Otto's Grotto.",
        quests: ["Tree Gnome Village", "The Grand Tree"]
    }, {
        id: "bae73d73-058b-42a3-9445-b68b32aa4719",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Travel to the Tree Gnome Stronghold by Spirit tree.",
        quests: ["Tree Gnome Village"]
    }, {
        id: "8c594117-694a-413a-94ad-182c55ce567c",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Trap a Spined larupia.",
        quests: []
    }, {
        id: "915d2458-1874-4f93-8b18-304bf1e34550",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Fish some bass on Ape Atoll.",
        quests: ["Monkey Madness I"]
    }, {
        id: "b9a08083-3940-4764-b1ad-ef0c298dabb1",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Chop and burn some teak logs on Ape Atoll.",
        quests: ["Monkey Madness I"]
    }, {
        id: "85a3455a-f1ce-439a-a07e-c1dfe44b3d07",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Complete an intermediate game of Pest Control.",
        quests: []
    }, {
        id: "69bc4562-662c-4e1c-b54e-b2663652dfb4",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Travel to the Feldip Hills by Gnome glider.",
        quests: ["The Grand Tree", "One Small Favour"]
    }, {
        id: "fc9bda47-e96c-405a-a29d-37a1eb2fb1d9",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Claim a Chompy bird hat from Rantz after registering at least 125 kills.",
        quests: ["Big Chompy Bird Hunting"]
    }, {
        id: "fb820276-1a72-43a8-a412-e43b6c87c3a6",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Travel from Eagles' Peak to the Feldip Hills by Eagle.",
        quests: ["Eagles' Peak"]
    }, {
        id: "7ed482e3-2fc1-4047-88c2-3374da7dbc0b",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Make a Chocolate bomb at the Grand Tree.",
        quests: []
    }, {
        id: "0993df4e-8595-43de-99f4-5aff38f20d43",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Complete a delivery for the Gnome Restaurant.",
        quests: ["The Grand Tree"]
    }, {
        id: "7815f172-ba7b-4009-ba60-ddc8f72ac23c",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Turn your crystal saw seed into a crystal saw.",
        quests: ["The Eyes of Glouphrie"]
    }, {
        id: "6aaa1531-41a6-4540-8d04-ce63241e3365",
        tier: 2,
        diary: "western provinces",
        difficulty: "medium",
        task: "Mine some gold ore underneath the Grand Tree.",
        quests: ["The Grand Tree"]
    }, {
        id: "4abd42d1-c90a-482c-9d35-e52f16cfc067",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Kill an elf with a crystal bow.",
        quests: ["Roving Elves"]
    }, {
        id: "c006a630-8d6a-48c1-8426-a8b991e6a2fe",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Catch and cook a monkfish in Piscatoris.",
        weight: 3,
        quests: ["Swan Song"]
    }, {
        id: "2196e3f7-1924-491e-a208-0297602083d4",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Complete a veteran game of Pest Control.",
        quests: []
    }, {
        id: "a29316e8-4ccd-42a6-9b10-fb4c1498248f",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Catch a dashing kebbit.",
        quests: []
    }, {
        id: "a5e6dd6e-91cc-4b5e-8f20-fa45086274f3",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Complete a lap of the Ape Atoll Agility Course.",
        weight: 2,
        quests: ["Monkey Madness I"]
    }, {
        id: "e1f7b27e-fe29-4c69-a1aa-c7533b0425a3",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Chop and burn some mahogany logs on Ape Atoll.",
        quests: ["Monkey Madness I"]
    }, {
        id: "3de67a6e-7ce4-4a63-9568-22fd66ed7e68",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Mine some adamantite ore in Tirannwn.",
        quests: ["Regicide"]
    }, {
        id: "7cb5e2b5-f457-44e8-b4e3-8f04f8ea482d",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Check the health of your palm tree in Lletya",
        quests: ["Mourning's End Part I"]
    }, {
        id: "b1e36e3f-b40e-414f-ae09-b0a1e6e01d08",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Claim a Chompy bird hat from Rantz after registering at least 300 kills.",
        quests: ["Big Chompy Bird Hunting"]
    }, {
        id: "d6b6a881-6a9e-4ad5-a3f8-34c6a0a1870d",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Build an Isafdar painting in your POH Quest Hall.",
        quests: ["Roving Elves"]
    }, {
        id: "7349cb58-9f92-4566-89d4-1f0375bea594",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Kill Zulrah.",
        quests: ["Regicide"]
    }, {
        id: "14582f49-d7a0-4e35-af82-3df16b7c5e3a",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Teleport to Ape Atoll.",
        quests: ["Freeing King Awowogei"]
    }, {
        id: "af66ae31-536c-4391-96e1-0fd383644e8c",
        tier: 3,
        diary: "western provinces",
        difficulty: "hard",
        task: "Pickpocket a gnome.",
        quests: ["Tree Gnome Village"]
    }, {
        id: "84da3f6e-6579-42cd-804b-10f83589f780",
        tier: 5,
        diary: "western provinces",
        difficulty: "elite",
        task: "Fletch a magic longbow in Tirannwn.",
        quests: ["Mourning's End Part I"]
    }, {
        id: "1ccb585b-8205-4319-bff1-e9c7c4e91386",
        tier: 5,
        diary: "western provinces",
        difficulty: "elite",
        task: "Kill the Thermonuclear smoke devil.",
        quests: []
    }, {
        id: "d9932ed9-b0fc-4d27-a55e-474f2c0fdee8",
        tier: 4,
        diary: "western provinces",
        difficulty: "elite",
        task: "Have Prissy Scilla protect your magic tree.",
        quests: []
    }, {
        id: "848a8b47-c02f-49cb-908b-bb4c4bd5c10d",
        tier: 5,
        diary: "western provinces",
        difficulty: "elite",
        task: "Use the Elven overpass advanced cliffside shortcut.",
        quests: ["Underground Pass"]
    }, 
    // {
    //     id: "110c796a-b2b3-4176-8e22-57e7f283ecaa",
    //     tier: 5,
    //     diary: "western provinces",
    //     difficulty: "elite",
    //     task: "Equip any complete void set.",
    //     quests: []
    // }, 
    {
        id: "4ece3248-05d9-4985-b154-45f0858f8c6f",
        tier: 5,
        diary: "western provinces",
        difficulty: "elite",
        task: "Claim a Chompy bird hat from Rantz after registering at least 1,000 kills.",
        quests: ["Big Chompy Bird Hunting"]
    }, {
        id: "1f14b728-fb76-4605-8e7b-588b815f5270",
        tier: 5,
        diary: "western provinces",
        difficulty: "elite",
        task: "Pickpocket an elf.",
        quests: ["Mourning's End Part I"]
    }, {
        id: "412e6db5-ba82-4f52-86c1-92bdb33f864d",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Cast Low Alchemy at the Fountain of Rune.",
        quests: []
    }, {
        id: "20033818-230b-4e1d-a038-acfdef7fe717",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Enter the Wilderness from the Ardougne or Edgeville lever.",
        quests: []
    }, {
        id: "b17f0b28-d492-4146-b697-f567283229cd",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Pray at the Chaos Altar in level 38, Western Wilderness.",
        quests: []
    }, {
        id: "75c661bc-2982-4050-8296-6fdc6185fe3e",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Enter the Chaos Runecrafting temple.",
        quests: ["Enter the Abyss"]
    }, {
        id: "2c153570-c791-40ba-bbb6-64f962509915",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Kill a mammoth.",
        quests: []
    }, {
        id: "2afdcc46-15c9-4d7f-b425-157e383e54db",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Kill an earth warrior in the Wilderness beneath Edgeville.",
        quests: []
    }, {
        id: "0106cc6f-f47c-44a7-9256-e51ad5820ac1",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Restore some Prayer points at the Demonic Ruins.",
        quests: []
    }, {
        id: "cb6415ff-957a-449f-ab02-58913cb0813d",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Enter the King Black Dragon Lair.",
        quests: []
    }, {
        id: "fadc8155-a0fd-431c-a555-d91a522a8885",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Collect 5 red spider's eggs from the Wilderness.",
        quests: []
    }, {
        id: "94a72af5-4666-4d5b-8a74-235d2f9733d2",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Mine some iron ore in the Wilderness.",
        quests: []
    }, {
        id: "a220c919-fab7-4f4b-98a7-09695014b75d",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Have the Mage of Zamorak teleport you to the Abyss.",
        quests: ["Enter the Abyss"]
    }, {
        id: "41a76b59-07ce-4d6f-b0eb-09ece11d065a",
        tier: 1,
        diary: "wilderness",
        difficulty: "easy",
        task: "Equip any team cape in the Wilderness.",
        quests: []
    }, {
        id: "76e57690-b563-44a6-a5e8-2b6259f78841",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Mine some mithril ore in the Wilderness.",
        quests: []
    }, {
        id: "6ca9b198-cb14-4f95-a5e5-680e9fdcbe21",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Chop some yew logs from an ent.",
        quests: []
    }, {
        id: "de0c6808-0a9e-466b-8a88-45f7e3100252",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Enter the Wilderness God Wars Dungeon.",
        quests: []
    }, {
        id: "9e8ed7e7-022a-4f1a-a3b5-756555616c2b",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Complete a lap of the Wilderness Agility Course.",
        quests: []
    }, {
        id: "62d32a69-2a08-414d-95a8-28dbd9c6a941",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Kill a green dragon.",
        quests: []
    }, {
        id: "bad8b9c0-bbc8-4686-85b5-4c12b48c2c36",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Kill an ankou in the Wilderness.",
        quests: []
    }, {
        id: "30d1bf2e-d3dc-4b73-85bd-31ec07b2a257",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Charge an earth orb.",
        quests: []
    }, {
        id: "ccdd6686-b910-4aff-9807-b3b8019fa01a",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Kill a bloodveld in the Wilderness God Wars Dungeon.",
        quests: []
    }, {
        id: "62345fc9-e526-45af-b0a5-00d0583e67d7",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Talk to the Emblem Trader in Edgeville about emblems.",
        quests: []
    }, {
        id: "58c70a8b-29fd-4b21-b9d2-865db58b7af7",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Smith a Gold helmet in the Resource Area.",
        quests: ["Between a Rock..."]
    }, {
        id: "e1f2167d-dc02-4737-b81a-2d864506de62",
        tier: 2,
        diary: "wilderness",
        difficulty: "medium",
        task: "Open the muddy chest in the Lava Maze",
        quests: []
    }, {
        id: "8c1c53c5-8841-4cf5-8aad-c1045f067cf0",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Cast one of the 3 God spells against another player in the Wilderness.",
        quests: ["The Mage Arena"]
    }, {
        id: "78736268-2b51-4652-91ee-fca007387e31",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Charge an air orb.",
        quests: []
    }, {
        id: "90ab65e1-8f82-4a6e-adb5-22992813cb36",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Catch a black salamander.",
        quests: []
    }, {
        id: "6b87b5fa-5c81-467b-acd3-0843f0c5821e",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Smith an adamant scimitar in the Resource Area.",
        quests: []
    }, {
        id: "c3709785-86c8-4e79-87d2-4af6b98274c3",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Kill a lava dragon and bury the bones on Lava Dragon Isle.",
        weight: 4,
        quests: []
    }, {
        id: "b38516a4-679c-4737-90cb-f2250d5e1216",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Kill the Chaos Elemental.",
        weight: 2,
        quests: []
    }, {
        id: "6cdb6b7f-7660-43fb-a47d-b42c9f3dfa3b",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Kill the Crazy archaeologist, Chaos Fanatic & Scorpia.",
        weight: 3,
        quests: []
    }, {
        id: "02f75bca-3a2d-4ffc-b58d-c70812fb2e6d",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Take the Agility Shortcut from Trollheim into the Wilderness.",
        quests: ["Death Plateau"]
    }, {
        id: "10d8ec74-2842-491b-89a7-619b3ae24c88",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Kill a spiritual warrior in the Wilderness God Wars Dungeon.",
        quests: []
    }, {
        id: "6b4c674d-7394-48e5-9e11-b62b3c48d927",
        tier: 3,
        diary: "wilderness",
        difficulty: "hard",
        task: "Fish some raw lava eel in the Wilderness.",
        quests: []
    }, {
        id: "8466e71c-5f1f-4699-bcf5-1ca1eefd6385",
        tier: 4,
        diary: "wilderness",
        difficulty: "elite",
        task: "Kill Callisto, Venenatis & Vet'ion.",
        quests: []
    }, {
        id: "e50973ea-9a15-42fb-a8e0-c99e2d49244d",
        tier: 5,
        diary: "wilderness",
        difficulty: "elite",
        task: "Teleport to Ghorrock.",
        quests: ["Desert Treasure"]
    }, {
        id: "8ddc59fc-6d8b-4d5d-b296-d6913bfb1c3b",
        tier: 5,
        diary: "wilderness",
        difficulty: "elite",
        task: "Fish and cook a dark crab in the Resource Area.",
        quests: []
    }, {
        id: "1577d0af-f838-40e5-833a-f1d5968b44a8",
        tier: 5,
        diary: "wilderness",
        difficulty: "elite",
        task: "Smith a rune scimitar from scratch in the Resource Area.",
        quests: []
    }, {
        id: "d90adc7b-70e3-40a8-9a45-ed4892dacd96",
        tier: 5,
        diary: "wilderness",
        difficulty: "elite",
        task: "Steal from the Chest (Rogues' Castle).",
        quests: []
    }, {
        id: "109b602e-cf53-49bd-b4e3-aa5f06210774",
        tier: 4,
        diary: "wilderness",
        difficulty: "elite",
        task: "Slay a spiritual mage inside the Wilderness God Wars Dungeon.",
        quests: []
    }, {
        id: "618dfd88-0002-4272-bc1e-830ca764a841",
        tier: 4,
        diary: "wilderness",
        difficulty: "elite",
        task: "Cut and burn some magic logs in the Resource Area.",
        quests: []
    }
]