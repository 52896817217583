import _ from 'underscore';

import './CluePanel.scss';
import Table from '../Table/Table';
import { Task, ClueTier } from '../types';

interface Props {
  onMarkedComplete?: Function;
  completed?: Task[];
  currentOptions?: Task[];
  readOnly?: boolean;
}

const Clues: ClueTier[] = [
  {
    name: `Beginner Clues`,
    difficulty: "0",
    image: `https://oldschool.runescape.wiki/images/Clue_scroll_%28beginner%29.png?09bb0`
  },
  {
    name: `Easy Clues`,
    difficulty: "1",
    image: `https://oldschool.runescape.wiki/images/Clue_scroll_%28easy%29.png?e29cd`
  },
  {
    name: `Medium Clues`,
    difficulty: "2",
    image: `https://oldschool.runescape.wiki/images/Clue_scroll_%28medium%29.png?e18ce`
  },
  {
    name: `Hard Clues`,
    difficulty: "3",
    image: `https://oldschool.runescape.wiki/images/Clue_scroll_%28hard%29.png?e29cd`
  },
  {
    name: `Elite Clues`,
    difficulty: "4",
    image: `https://oldschool.runescape.wiki/images/Clue_scroll_%28elite%29.png?e29cd`
  },
  {
    name: `Master Clues`,
    difficulty: "5",
    image: `https://oldschool.runescape.wiki/images/Clue_scroll_%28master%29.png?09bb0`
  }
];


function CluePanel(props: Props) {
  return (
    <>
      <div>
        Use this table to check off when you have green logged the collection log for a clue type. You probably won't need this after beginners lol.
      </div>
      <br />
      <div className="Clue">
        <Table
          readOnly={props.readOnly || false}
          tableHeaders={["Clue", ""]}
          indexes={["name", "image"]}
          sortableCols={[false, false]}
          idIndex="difficulty"
          data={Clues}
          completedIds={props.completed ? _.pluck(props.completed, 'referenceId') : []}
          onMarkedComplete={(task: any) => props.onMarkedComplete ? props.onMarkedComplete(task) : null}
          defaultSort={{ index: 'difficulty', asc: true }}
          currentOptions={props.currentOptions}
        ></Table>
      </div>
    </>
  );
}

export default CluePanel;
