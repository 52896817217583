import _ from 'underscore';

import './QuestPanel.scss';
import { Quests } from '../data/quests';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Task } from '../types';
import Table from '../Table/Table';

interface Props {
  onMarkedComplete?: Function;
  completed?: Task[];
  currentOptions?: Task[];
  readOnly?: boolean;
}

function QuestPanel(props: Props) {
  
  return (
    <div className="QuestPanel">
      <Tabs>
        <TabList>
          <Tab>All Tiers</Tab>
          <Tab>Tier 1</Tab>
          <Tab>Tier 2</Tab>
          <Tab>Tier 3</Tab>
          <Tab>Tier 4</Tab>
        </TabList>

        {
          [0, 1, 2, 3, 4].map(num =>
            <TabPanel key={num} className="QuestPanel__container">
              <Table 
                readOnly={props.readOnly || false}
                tier={num} 
                data={Quests} 
                indexes={["name", "difficulty", "requirements.quests", "tier"]}
                sortableCols={[true, true, false, true]}
                tableHeaders={['Quest', 'Difficulty', 'Pre-reqs', 'Tier']}
                idIndex="name"
                onMarkedComplete={(task: any) => props.onMarkedComplete ? props.onMarkedComplete(task) : null}
                completedIds={props.completed ? _.pluck(props.completed, 'referenceId') : []}
                defaultSort={{ index: "name", asc: true }}
                currentOptions={props.currentOptions}
              ></Table>
            </TabPanel>
          )
        }
      </Tabs>
    </div>
  );
}

export default QuestPanel;
