import { Pet } from '../types'

export const Pets: Pet[] = [
    {
        name: "Abyssal orphan",
        altOption: "3 Unsireds",
        image: "https://oldschool.runescape.wiki/images/Abyssal_orphan.png?5bab3"
    },
    {
        name: "Abyssal protector",
        altOption: "30 Guardians of the Rift games",
        image: "https://oldschool.runescape.wiki/images/Abyssal_protector.png?1e68e"
    },
    {
        name: "Baby mole",
        altOption: "250 Giant Mole kills",
        image: "https://oldschool.runescape.wiki/images/Baby_mole.png?81388"
    },
    {
        name: "Callisto cub",
        altOption: "150 Callisto kills",
        image: "https://oldschool.runescape.wiki/images/Callisto_cub.png?4e200"
    },
    {
        name: "Hellpuppy",
        altOption: "300 Cerberus kills",
        image: "https://oldschool.runescape.wiki/images/Hellpuppy.png?80b36"
    },
    {
        name: "Ikkle hydra",
        altOption: "250 hydra kills",
        image: "https://oldschool.runescape.wiki/images/Ikkle_hydra.png?80b36"
    },
    {
        name: "Jal-nib-rek",
        altOption: "Skip this task enitrely",
        image: "https://oldschool.runescape.wiki/images/Jal-nib-rek.png?b3ec1"
    },
    {
        name: "Kalphite princess",
        altOption: "300 KQ kills",
        image: "https://oldschool.runescape.wiki/images/Kalphite_princess.png?d8722",
    },
    {
        name: "Lil' zik",
        altOption: "50 ToB kills",
        image: "https://oldschool.runescape.wiki/images/Lil%27_zik.png?c62f1",
    },
    {
        name: "Little nightmare",
        altOption: "100 Nightmare kc",
        image: "https://oldschool.runescape.wiki/images/Little_nightmare.png?f3f6a",
    },
    {
        name: "Nexling",
        altOption: "150 Nex kills",
        image: "https://oldschool.runescape.wiki/images/Nexling.png?23e0d",
    },
    {
        name: "Noon",
        altOption: "300 GG kills",
        image: "https://oldschool.runescape.wiki/images/Noon.png?4068d",
    },
    {
        name: "Olmlet",
        altOption: "1 CoX purple",
        image: "https://oldschool.runescape.wiki/images/Olmlet.png?d8722",
    },
    {
        name: "Pet chaos elemental",
        altOption: "100 Chaos Elemental kills",
        image: "https://oldschool.runescape.wiki/images/Pet_chaos_elemental.png?e2d9b",
    },
    {
        name: "Pet dagannoth prime",
        altOption: "300 Prime kills",
        image: "https://oldschool.runescape.wiki/images/Pet_dagannoth_prime.png?e2d9b",
    },
    {
        name: "Pet dagannoth rex",
        altOption: "300 Rex kills",
        image: "https://oldschool.runescape.wiki/images/Pet_dagannoth_rex.png?e2d9b",
    },
    {
        name: "Pet dagannoth supreme",
        altOption: "300 Supreme kills",
        image: "https://oldschool.runescape.wiki/images/Pet_dagannoth_supreme.png?e2d9b",
    },
    {
        name: "Pet dark core",
        altOption: "100 Corp kills",
        image: "https://oldschool.runescape.wiki/images/Pet_dark_core.png?85699",
    },
    {
        name: "Pet general graardor",
        altOption: "200 Graador kills",
        image: "https://oldschool.runescape.wiki/images/Pet_general_graardor.png?85699",
    },
    {
        name: "Pet k'ril tsutsaroth",
        altOption: "200 K'ril kills",
        image: "https://oldschool.runescape.wiki/images/Pet_k%27ril_tsutsaroth.png?fe846",
    },
    {
        name: "Pet kraken",
        altOption: "300 Kraken kills",
        image: "https://oldschool.runescape.wiki/images/Pet_kraken.png?85699",
    },
    {
        name: "Pet kree'arra",
        altOption: "200 Kree'arra kills",
        image: "https://oldschool.runescape.wiki/images/Pet_kree%27arra.png?f3f6a",
    },
    {
        name: "Pet smoke devil",
        altOption: "300 Thermy kills",
        image: "https://oldschool.runescape.wiki/images/Pet_smoke_devil.png?fe846",
    },
    {
        name: "Pet snakeling",
        altOption: "300 Zulrah kills",
        image: "https://oldschool.runescape.wiki/images/Pet_snakeling.png?c0946",
    },
    {
        name: "Pet zilyana",
        altOption: "250 Zilyana kills",
        image: "https://oldschool.runescape.wiki/images/Pet_zilyana.png?f3f6a",
    },
    {
        name: "Prince black dragon",
        altOption: "200 KBD kills",
        image: "https://oldschool.runescape.wiki/images/Prince_black_dragon.png?c0946",
    },
    {
        name: "Scorpia's offspring",
        altOption: "150 Scorpia kills",
        image: "https://oldschool.runescape.wiki/images/Scorpia%27s_offspring.png?85699",
    },
    {
        name: "Skotos",
        altOption: "5 Skotizo kills",
        image: "https://oldschool.runescape.wiki/images/Skotos.png?52875",
    },
    {
        name: "Sraracha",
        altOption: "300 Sarachnis kills",
        image: "https://oldschool.runescape.wiki/images/Sraracha.png?52875",
    },
    {
        name: "Tzrek-jad",
        altOption: "10 Jad kills",
        image: "https://oldschool.runescape.wiki/images/Tzrek-jad.png?420f4",
    },
    {
        name: "Venenatis spiderling",
        altOption: "150 Venenatis kills",
        image: "https://oldschool.runescape.wiki/images/Venenatis_spiderling.png?298ec",
    },
    {
        name: "Vet'ion jr.",
        altOption: "150 Vet'ion kills",
        image: "https://oldschool.runescape.wiki/images/Vet%27ion_jr..png?03aa0",
    },
    {
        name: "Vorki",
        altOption: "200 Vorkath kills",
        image: "https://oldschool.runescape.wiki/images/Vorki.png?03aa0",
    },
    {
        name: "Baby chinchompa",
        altOption: "1000 chinchompas",
        image: "https://oldschool.runescape.wiki/images/Baby_chinchompa_%28red%29.png?b5841",
    },
    {
        name: "Beaver",
        altOption: "1M Woodcutting XP",
        image: "https://oldschool.runescape.wiki/images/Beaver.png?0d4ed",
    },
    {
        name: "Giant squirrel",
        altOption: "300K Agility XP",
        image: "https://oldschool.runescape.wiki/images/Giant_squirrel.png?3b568",
    },
    {
        name: "Heron",
        altOption: "500K Fishing XP",
        image: "https://oldschool.runescape.wiki/images/Heron.png?80b36",
    },
    {
        name: "Rift guardian",
        altOption: "300K Runecrafting XP",
        image: "https://oldschool.runescape.wiki/images/Rift_guardian_%28fire%29.png?c0946",
    },
    {
        name: "Rock golem",
        altOption: "300K Mining XP",
        image: "https://oldschool.runescape.wiki/images/Rock_golem.png?77385",
    },
    {
        name: "Rocky",
        altOption: "1M Thieving XP",
        image: "https://oldschool.runescape.wiki/images/Rocky.png?3f71e",
    },
    {
        name: "Tangleroot",
        altOption: "Passive",
        image: "https://oldschool.runescape.wiki/images/Tangleroot.png?52875",
        passive: true
    },
    {
        name: "Bloodhound",
        altOption: "5 Master Clues",
        image: "https://oldschool.runescape.wiki/images/Bloodhound.png?0d4ed",
        passive: true
    },
    {
        name: "Chompy chick",
        altOption: "N/A",
        image: "https://oldschool.runescape.wiki/images/Chompy_chick.png?36657",
    },
    {
        name: "Herbi",
        altOption: "300 Herbiboar kills",
        image: "https://oldschool.runescape.wiki/images/Herbi.png?d8722",
    },
    {
        name: "Lil' creator",
        altOption: "10 Spoils of War",
        image: "https://oldschool.runescape.wiki/images/Lil%27_creator.png?60046",
    },
    {
        name: "Pet penance queen",
        altOption: "50 High-level gambles",
        image: "https://oldschool.runescape.wiki/images/Pet_penance_queen.png?c0946",
    },
    {
        name: "Phoenix",
        altOption: "10 Wintertodt solos",
        image: "https://oldschool.runescape.wiki/images/Phoenix.png?fe846",
    },
    {
        name: "Tiny tempor",
        altOption: "500 reward permits",
        image: "https://oldschool.runescape.wiki/images/Tiny_tempor.png?77385",
    },
    {
        name: "Youngllef",
        altOption: "50 CG kills / 125 gauntlet kills",
        image: "https://oldschool.runescape.wiki/images/Youngllef.png?1e205",
    },
    {
        name: "Smolcano",
        altOption: "200 Zalcano kills",
        image: "https://oldschool.runescape.wiki/images/Smolcano.png?52875",
    },
]