import { Clue } from "../types";

export let Clues: Clue[] = [
    {
        name: 'Beginner Clue',
        difficulty: 'beginner',
        tier: 1,
        obsoleteTier: 3,
        difficultyId: "0"
    },
    {
        name:  'Easy Clue',
        difficulty: 'easy',
        tier: 1,
        obsoleteTier: 3,
        difficultyId: "1"
    },
    {
        name: '5x Beginner Clue',
        difficulty: 'beginner',
        tier: 3,
        difficultyId: "0"
    },
    {
        name: '5x Easy Clue',
        difficulty: 'easy',
        tier: 3,
        obsoleteTier: 5,
        difficultyId: "1"
    },
    {
        name: 'Medium Clue',
        difficulty: 'medium',
        tier: 2,
        obsoleteTier: 4,
        difficultyId: "2"
    },
    {
        name: '5x Medium Clue',
        difficulty: 'medium',
        tier: 4,
        obsoleteTier: 5,
        difficultyId: "2"
    },
    {
        name: 'Hard Clue',
        difficulty: 'hard',
        tier: 3,
        obsoleteTier: 4,
        difficultyId: "3"
    },
    {
        name: '2x Hard Clue',
        difficulty: 'hard',
        tier: 4,
        obsoleteTier: 5,
        difficultyId: "3"
    },
    {
        name: 'Elite Clue',
        difficulty: 'hard',
        tier: 4,
        obsoleteTier: 5,
        difficultyId: "4"
    },
    {
        name: '2x Easy Clue Uniques',
        difficulty: 'easy',
        tier: 5,
        difficultyId: "1",
        weight: 4
    },
    {
        name: '2x Medium Clue Uniques',
        difficulty: 'medium',
        tier: 5,
        difficultyId: "2",
        weight: 4
    },
    {
        name: 'Hard Clue Unique',
        difficulty: 'hard',
        tier: 5,
        difficultyId: "3",
        weight: 3
    },
    {
        name: 'Elite Clue Unique',
        difficulty: 'elite',
        tier: 5,
        difficultyId: "4",
    },
    {
        name: '1x Master Clue',
        difficulty: 'master',
        tier: 5,
        difficultyId: "5",
        obsoleteTier: 6,
    },
    {
        name: 'Master Clue Unique',
        difficulty: 'master',
        tier: 6,
        difficultyId: "5"
    },
]