export function Rewards() {
    return (
        <>
            Completing a tier comes with a set of rewards. Each Tier Completion Reward is laid out here.
            <br />
            <h3>Tier 1</h3>
            <ul>
                <li>Completion of all Easy diaries, including the quests required to do so. <b>If you have to start a quest, you have to finish it too</b></li>
            </ul>
            <h3>Tier 2</h3>
            <ul>
                <li>Access to Fairy Rings <b>You do NOT need to complete Fairytale II</b></li>
                <li>4 Medium Diaries and the quests required to complete them</li>
            </ul>
            <h3>Tier 3</h3>
            <ul>
                <li>Fire Cape if not already obtained</li>
                <li>1 Hard Diary</li>
                <li>All Medium Diaries</li>
            </ul>
            <h3>Tier 4</h3>
            <ul>
                <li>All hard diaries (if not already completed)</li>
            </ul>
            <h3>Tier 5</h3>
            <ul>
                <li>Achievement Diary Cape</li>
            </ul>
            <h3>Tier 6</h3>
            <ul>
                <li>You win</li>
                <li>Go finish all pets LOL</li>
            </ul>
            <br />
            <br />
            <br />
        </>
    )
}