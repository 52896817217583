import { Challenge } from '../types';

export const Challenges: Challenge[] = [
    {
        text: "Move on to Tier 2",
        id: "weqfw83242h",
        questRequirements: [],
        tier: 1
    },
    {
        text: "Move on to Tier 3",
        id: "12321rewr32rw",
        questRequirements: [],
        tier: 2
    },
    {
        text: "Move on to Tier 4",
        id: "576546eterer2",
        questRequirements: [],
        tier: 3
    },
    {
        text: "Move on to Tier 5",
        id: "rweret436",
        questRequirements: [],
        tier: 4
    },
    {
        text: "Move on to Tier 6",
        id: "wewr2342",
        questRequirements: [],
        tier: 5
    },
]