import _ from 'underscore';

import './PetPanel.scss';
import { Pets } from '../data/pets';
import Table from '../Table/Table';
import { Task } from '../types';

interface Props {
  onMarkedComplete?: Function;
  completed?: Task[];
  currentOptions?: Task[];
  readOnly?: boolean;
}


function ItemPanel(props: Props) {
  return (
    <div className="PetPanel">
      <Table
        readOnly={props.readOnly || false}
        tableHeaders={["Pet", "", "Alternative"]}
        indexes={["name", "image", "altOption"]}
        sortableCols={[true, false, false]}
        colSizes={['', '', 'lgCell']}
        idIndex="name"
        data={Pets}
        tier={0}
        completedIds={props.completed ? _.pluck(props.completed, 'referenceId') : []}
        onMarkedComplete={(task: any) => props.onMarkedComplete ? props.onMarkedComplete(task, true) : ''}
        defaultSort={{ index: 'name', asc: true }}
        currentOptions={props.currentOptions}
      ></Table>
    </div>
  );
}

export default ItemPanel;
