import { useEffect, useState } from "react";
import _ from 'underscore';

import './Wheel.scss';
import { Quests } from '../data/quests';
import { DiarySteps } from '../data/diaries';
import { Items } from '../data/items';
import { Clues } from '../data/clues';
import WheelCanvas from "./WheelCanvas";
import { Clue, DiaryStep, Item, Task } from '../types';
import { Challenges } from "../data/challenges";
import { Weighting } from '../data/tierCompletion';
import { Pets } from "../data/pets";
import { stepsByTier, cluesByTier, itemsByTier, questsByTier, petsByTier } from '../data/wheelSlots'

interface Props {
    wheelSize: number;
    onWheelPopulated: Function;
    onTaskComplete: Function;
    onTaskGenerated: Function;
    completedTasks: Task[];
    points: number;
    currentTier: number;
    currentOptions: Task[];
    currentTask: Task | undefined;
    isMuted: boolean;
    wheelAngle: number;
}

const Wheel = (props: Props) => {
    const [newestOption, setNewestOption] = useState('');

    const populateWheel = (existingOptions = props.currentOptions) => {
        const completedQuests = props.completedTasks.filter(task => task.category === 'Quest');
        const completedSteps = props.completedTasks.filter(task => task.category === 'Diary');
        const completedItems = props.completedTasks.filter(task => task.category === 'Item');
        const completedPets = props.completedTasks.filter(task => task.category === 'Pet');
        const completedClues = props.completedTasks.filter(task => task.category === 'ClueTier');

        let newWheelOptions = existingOptions.filter(option => {
            if (!option) return false;
            if(option.category === 'Challenge') return false;
            return _.findIndex(props.completedTasks, completedTask => completedTask.referenceId === option.referenceId) < 0;
        });

        let eligibleQuests = Quests.filter(quest => {
            const questComplete = _.pluck(completedQuests, 'referenceId').includes(quest.name);
            const questHasPreReqs = areQuestReqsComplete(quest.requirements.quests, completedQuests);
            const questOnWheel = _.pluck(newWheelOptions, 'referenceId').includes(quest.name);
            const questPointsRequirement = quest.requirements.skills.find(req => req.skill === 'quest')?.level || 0;
            const questPointsDone = calculateQuestPoints() >= questPointsRequirement;

            return !questComplete && !questOnWheel && questHasPreReqs && questPointsDone && quest.tier <= props.currentTier;
        });

        let eligibleSteps = DiarySteps.filter(step => {
            const stepComplete = _.pluck(completedSteps, 'referenceId').includes(step.id);
            const stepQuestReqs = step.quests && areQuestReqsComplete(step.quests, completedQuests);
            const stepOnWheel = _.pluck(newWheelOptions, 'referenceId').includes(step.id);

            const qpcReqComplete = step.quests?.includes('All') ? calculateQuestPoints() >= getMaxQuestPoints() : true;

            return !stepComplete && !stepOnWheel && stepQuestReqs && qpcReqComplete && step.tier <= props.currentTier;
        });

        let eligibleItems = Items.filter(item => {
            const itemComplete = _.pluck(completedItems, 'referenceId').includes(item.id);
            const itemQuestReqs = item.quests ? areQuestReqsComplete([item.quests], completedQuests) : true;
            const itemPreReqsComplete = !item.items || areItemReqsComplete(item, completedItems);
            const itemOnWheel = _.pluck(newWheelOptions, 'referenceId').includes(item.id);

            return !itemComplete && !itemOnWheel && itemQuestReqs && itemPreReqsComplete && item.tier <= props.currentTier;
        });

        let eligiblePets = Pets.filter(pet => {
            const petComplete = _.pluck(completedPets, 'referenceId').includes(pet.name);
            const petOnWheel = _.pluck(newWheelOptions, 'referenceId').includes(pet.name);

            return !petComplete && !petOnWheel && !pet.passive;
        });

        // Items required to allow elite clues on the wheel. Only one need be completed.
        // Signet, Cudgel, Vorkath's Head
        const eliteClueItems = [
            "265ef290-2075-431f-a843-91d30bffeef1",
            "a749e4ad-9ee0-402e-a3d4-6aea4cfcff86",
            "bd767d22-a8b4-406f-b053-99cb287a50fd"
        ]
        const eliteClueAllowed = completedItems.find(task => task.category === 'Item' && eliteClueItems.includes(task.referenceId));

        let eligibleClues = Clues.filter(clue => {
            if(clue.name.includes('Elite Clue') && !eliteClueAllowed) return false;
            const clueOnWheel = _.pluck(newWheelOptions, 'referenceId').includes(clue.difficulty);
            const clueCompleted = _.pluck(completedClues, 'referenceId').includes(clue.difficultyId);
            return !clueOnWheel && !clueCompleted && clue.tier <= props.currentTier && (!clue.obsoleteTier || clue.obsoleteTier > props.currentTier);
        });

        let eligibleChallenges = Challenges.filter(challenge => {
            if(props.points < Weighting[props.currentTier-1]) return false;
            if(props.currentTier === 6) return false;

            return challenge.tier === props.currentTier;
        })

        const numQuests = Math.min(
            questsByTier[+props.currentTier-1] - newWheelOptions.filter(option => option.category === 'Quest').length,
            eligibleQuests.length
        );
        
        let maxSteps = stepsByTier[+props.currentTier-1] - newWheelOptions.filter(option => option.category === 'Diary').length;
        if(props.currentTier === 4 && Quests.length === completedQuests.length) {
            maxSteps = stepsByTier[+props.currentTier-1] + 1 - newWheelOptions.filter(option => option.category === 'Diary').length;
        }

        const stepCount = eligibleSteps.length + props.currentOptions.filter(opt => opt.category === 'Diary').length;

        let maxItems = itemsByTier[+props.currentTier-1] - newWheelOptions.filter(option => option.category === 'Item').length;
        if(props.currentTier === 5 && completedSteps.length > DiarySteps.length - stepsByTier[props.currentTier-1]) {
            maxItems = itemsByTier[+props.currentTier-1] + 1 - newWheelOptions.filter(option => option.category === 'Item').length;
        }
        else if(props.currentTier === 4 && stepCount < stepsByTier[props.currentTier-1]) {
            maxItems = itemsByTier[+props.currentTier-1] + 1 - newWheelOptions.filter(option => option.category === 'Item').length;
        }

        let maxClues = cluesByTier[+props.currentTier-1] - newWheelOptions.filter(option => option.category === 'Clue').length;
        if(props.currentTier === 4 && stepCount < stepsByTier[props.currentTier-1]) {
            maxClues = cluesByTier[+props.currentTier-1] + 1 - newWheelOptions.filter(option => option.category === 'Clue').length;
        }

        const numSteps = Math.min(
            maxSteps,
            eligibleSteps.length
        );
        const numItems = Math.min(
            maxItems,
            eligibleItems.length
        );
        const numPets = Math.min(
            petsByTier[+props.currentTier - 1] - newWheelOptions.filter(option => option.category === 'Pet').length,
            eligiblePets.length
        );
        const numClues = Math.min(
            maxClues,
            eligibleClues.length
        );

        const numChallenges = Math.min(
            1 - newWheelOptions.filter(option => option.category === 'Challenge').length,
            eligibleChallenges.length
        );

        const newQuestOptions: Task[] = numQuests > 0 ?
            new Array(numQuests).fill(0).map((el, i) => {
                let index = Math.floor(Math.random() * eligibleQuests.length);
                let quest = eligibleQuests[index];

                // Give another chance for a low tier quest to be spun
                if(quest.tier === props.currentTier && props.currentTier > 1) {
                    index = Math.floor(Math.random() * eligibleQuests.length);
                    quest = eligibleQuests[index];
                }
                eligibleQuests.splice(index, 1);

                setNewestOption(quest.name);

                return {
                    displayText: quest.name,
                    referenceId: quest.name,
                    category: 'Quest'
                }
            })
            : [];


        const newDiaryOptions: Task[] = numSteps > 0 ?
            new Array(numSteps).fill(0).map((el, i) => {
                const selectedTier = rollTier(eligibleSteps);

                const weightedArr: DiaryStep[] = genWeightedArr(eligibleSteps, selectedTier)

                let index = Math.floor(Math.random() * weightedArr.length);
                let step = weightedArr[index];

                eligibleSteps.splice(eligibleSteps.findIndex(i => i.id === step.id), 1);

                setNewestOption(step.id);

                return {
                    displayText: step.task,
                    referenceId: step.id,
                    category: 'Diary',
                }
            })
            : [];

        const newItemOptions: Task[] = numItems > 0 ?
            new Array(numItems).fill(0).map((el, i) => {
                const selectedTier = rollTier(eligibleItems);

                const weightedArr: Item[] = genWeightedArr(eligibleItems, selectedTier);

                let index = Math.floor(Math.random() * weightedArr.length);
                let item = weightedArr[index];

                eligibleItems.splice(eligibleItems.findIndex(i => i.id === item.id), 1);

                setNewestOption(item.id);

                return {
                    displayText: item.name,
                    referenceId: item.id,
                    category: 'Item',
                }
            })
            : [];

        const newPetOptions: Task[] = numPets > 0 ?
            new Array(numPets).fill(0).map((el, i) => {
                let index = Math.floor(Math.random() * eligiblePets.length);
                let pet = eligiblePets[index];

                eligiblePets.splice(eligiblePets.findIndex(p => p.name === pet.name), 1);

                setNewestOption(pet.name);

                return {
                    displayText: pet.name,
                    referenceId: pet.name,
                    category: 'Pet',
                }
            })
            : [];

        const newClueOptions: Task[] | undefined = numClues > 0 ?
            new Array(numClues).fill(0).map((el, i) => {
                const clue = selectWeightedClue(eligibleClues);

                let clueName = clue.name;

                if(clue.name === 'Master Clue Unique') {
                    const obtainingTier = Math.floor(Math.random()*3);
                    const tiers = ['Easy', 'Medium', 'Hard'];

                    clueName = `Master Clue Unique (${tiers[obtainingTier]} clues)`;
                }

                setNewestOption(clue.difficulty);

                return {
                    displayText: clueName,
                    referenceId: clue.difficulty,
                    category: 'Clue',
                }
            })
            : [];

        const challenge = eligibleChallenges[0];

        const newChallenge: Task | undefined = numChallenges ? 
            {
                displayText: challenge.text,
                referenceId: challenge.id,
                category: 'Challenge'
            }
            : undefined;

        newWheelOptions.push(...newQuestOptions, ...newDiaryOptions, ...newItemOptions, ...newPetOptions, ...newClueOptions);
        if(newChallenge) newWheelOptions.push(newChallenge);

        props.onWheelPopulated(newWheelOptions);
    }

    const rollTier = (arr: any[], debug=true) => {
        let selectedTier = 1;

        const allTiers = _.pluck(arr, 'tier');
        const tiers = _.union(allTiers, allTiers);
        const maxTier = Math.max(...tiers);

        while (selectedTier <= maxTier) {
            if (!tiers.includes(selectedTier)) {
                if(debug) console.log(`Tier ${selectedTier} has no items.`);
                selectedTier++;
                continue;
            }

            if(debug) console.log(`Rolling for Tier ${selectedTier}`);

            const rng = Math.floor(Math.random() * 2);
            const itemsInTier =  allTiers.filter(t => t === selectedTier).length;
            if (rng === 0 || selectedTier === maxTier || itemsInTier > 10){
                if (debug) console.log(`Selecting an element from Tier ${selectedTier}`);
                if(debug && itemsInTier >= 10) console.log(`items in tier: ${itemsInTier}`)
                break;
            }

            selectedTier++;
        }

        return Math.min(selectedTier, props.currentTier);
    }

    const genWeightedArr = (elilgibleArr: any[], tier: number) => {
        return elilgibleArr.map(el => {
            if (el.tier !== tier) return;

            if (!el.weight) return el;

            return new Array(el.weight).fill(el);
        }).flat().filter(el => Boolean(el));
    }

    const selectWeightedClue = (eligibleClues: Clue[]) => {
        const weightedArr = eligibleClues.map(el => {
            if (el.tier > props.currentTier) return;

            if (el.obsoleteTier && el.obsoleteTier <= props.currentTier) return;

            if (!el.weight) return el;

            return new Array(el.weight).fill(el);
        }).flat().filter(el => Boolean(el));

        const index = Math.floor(Math.random() * weightedArr.length);
        const clue = weightedArr[index];

        return clue;
    }

    const areQuestReqsComplete = (questReqs: string[], completedQuests: Task[], print = false) => {
        if(questReqs.includes(`All`)) return true;

        const completedIds = _.pluck(completedQuests, 'referenceId');
        if(print) console.log(`Quest reqs done? ${_.intersection(questReqs, completedIds).length === questReqs.length}`);
        return _.intersection(questReqs, completedIds).length === questReqs.length;
    }

    const areItemReqsComplete = (item: Item, completedItems: Task[], print = false) => {
        if(!item.items) return true;

        const itemReqs = item.items;
        const completedIds = _.pluck(completedItems, 'referenceId');
        if (print) console.log(`Item reqs done for ${item.name}? ${_.intersection(itemReqs, completedIds).length === itemReqs.length}`);
        return _.intersection(itemReqs, completedIds).length === itemReqs.length;
    }

    const calculateQuestPoints = (completedArr = props.completedTasks) => {
        const completedQuests = completedArr.map(task => {
          if(task.category !== 'Quest') return undefined;
          return _.find(Quests, (quest) => quest.name === task.referenceId);
        });
    
        const filteredQuests = completedQuests.filter(quest => quest ? true : false);
    
        return filteredQuests.reduce((points, quest) => points + (quest?.rewards.QuestPoints || 0), 1);
    }

    const getMaxQuestPoints = () => {
        return Quests.reduce<number>((last, current) => last + current.rewards.QuestPoints, 1);
    }

    const markCurrentTaskComplete = (gotPet=false) => {
        props.onTaskComplete(props.currentTask, gotPet);

        if (props.currentTask && ['Clue', 'Category'].includes(props.currentTask?.category || '')) {
            const newOptions = [...props.currentOptions];

            const index = _.pluck(newOptions, 'referenceId').indexOf(props.currentTask.referenceId);

            if(index < 0) return;

            newOptions.splice(index, 1);
            populateWheel(newOptions);
        }
    }

    const skipTask = () => {
        props.onTaskGenerated(undefined, true);
    }

    const setTask = (task: Task, angle: number) => {
        props.onTaskGenerated(task, angle);
        setNewestOption('');
    }


    function checkCurrentTasks() {
        let tasks = [...props.currentOptions];

        tasks = tasks.filter(task => {
            if(task.category !== 'Item') return true;

            return _.pluck(Items, 'id').includes(task.referenceId);
        });

        return tasks;
    }

    useEffect(() => {
        const tasks = checkCurrentTasks();
        populateWheel(tasks);
        if (_.pluck(props.completedTasks, 'referenceId').includes(props.currentTask?.referenceId || '')) {
            props.onTaskGenerated(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.completedTasks, props.points, props.currentTask])

    return (
        <div className="wheel">
            <div className="wheel__info">
                <div className="wheel__task">Current Task: {props.currentTask?.displayText || "N/A"}</div>
                <div>
                    { props.currentTask?.category === 'Pet' &&
                        <button 
                            onClick={() => markCurrentTaskComplete(true)} 
                            disabled={!props.currentTask}
                            className="info__pet"
                        >I GOT PET!</button>
                    }
                    <button onClick={() => markCurrentTaskComplete()} disabled={!props.currentTask}>Complete Task</button>
                    <button onClick={() => skipTask()} disabled={!props.currentTask} className="info__skip">Skip Task</button>
                </div>
            </div>
            <WheelCanvas
                options={props.currentOptions}
                onSpun={(task: Task, angle: number) => setTask(task, angle)}
                spinEnabled={!props.currentTask}
                isMuted={props.isMuted}
                newestOption={newestOption}
                wheelAngle={props.wheelAngle}
            ></WheelCanvas>
        </div>
    );
}

export default Wheel;
