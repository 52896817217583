import { cluesByTier, itemsByTier, petsByTier, questsByTier, stepsByTier } from "./data/wheelSlots";

function Rules() {
  return (
    <>
      <h3>General Rules</h3>
      <ul className="rules">
        <li>The game is easy: Spin the wheel, do the task.</li>
        <li>There are 5 types of task: Quest, Diary step, Item, and Clue Scroll, and Pet (but that comes way later).</li>
        <li>
          There are 6 tiers. When you've completed enough tasks in your current tier, a new option will appear on the wheel to move on to the next tier.
          When that option is rolled, the wheel will update to the next tier automatically. Each tier comes with it's own Completion Rewards. Make sure to take a look at those!
        </li>
        <li>Each tier has a certain threshold for "completion". This doesn't require completing every single task in the tier.</li>
        <li>
          All items in tier 6 are also passive items. That means if while completing another task you obtain a tier 6 item, you can 
          pick it up and mark that item as complete (this does not complete your current task). You do not need to avoid getting tier 6 items before they are your task.
          E.g. obtaining a Jar of Souls from Cerberus while hunting a crystal.
        </li>
        <li>Tasks from previous tiers that have yet to be completed will still be added to the wheel.</li>
      </ul>
      <h3>Quests</h3>
      <ul className="rules">
        <li>You can only do quests when rolled. If you get a clue scroll with a quest requirement, it must be dropped and a new clue obtained.</li>
      </ul>
      <h3>Diaries</h3>
      <ul className="rules">
        <li>You can only do diary steps when rolled, unless completion of that diary step is required to complete your current task. You can do diary steps to complete slayer tasks, clues, quests, etc.</li>
        <li>If a diary step is completed by accident, you cannot continue doing the action that triggered it. You must wait until you spin the step on the wheel. (e.g. Teleporting with an Ectophial may not be done until spun on the wheel, even if accidentally completed).</li>
      </ul>
      <h3>Clue Scrolls</h3>
      <ul className="rules">
        <li>
          You can only open caskets when rolled, but can stack caskets ahead of time. You must obtain clue scrolls in a way that does not risk completing other tasks.
          (e.g. Cannot do barrows for elite clue scrolls unless barrows is complete)
        </li>
        <li>
          If an item is required for a clue scroll, you can obtain it even if it is a possible wheel option ONLY IF the clue is your current task (THIS IS NOT REQUIRED! You can just drop the clue).
          The same goes for diary steps assuming it is <b>necessary</b> to complete the clue. Make sure to mark the diary step as completed.
          This is not the case for quests. If the clue requires a quest that has not been completed, it must be dropped.
        </li>
        <li>If a clue is dropped 3 times because it is not completeable (probably due to a quest requirement), the task can be <strong>skipped</strong>.</li>
      </ul>
      <h3>Items</h3>
      <ul className="rules">
        <li>
          Items that are obtained "unavoidably" can be picked up even if you didn't spin that specific item.
          For example, getting a granite maul while your task is Adamant boots. You can pick up the granite maul and mark that item as complete.
          This does not complete you current task. 
        </li>
        <li>
          Getting a monster as a slayer task is not considered unavoidable. That means if you are going for level 85 for a whip, you cannot
          go kill spiritual creatures for dragon boots if you are assigned them as a task (unless you're willing to watch the boots despawn).
        </li>
      </ul>
      <h3>Pets</h3>
      <ul className="rules">
        <li>
          Pets are only added to the wheel in Tier 6. However, pets obtained passively throughout your journey can be marked as complete.
        </li>
        <li>
          Each pet task has an <i>Alternative</i>. To complete the pet task when spun you must either a) obtain the pet, or b) complete the <i>Alternative</i> task.
          The alternative task is meant to be much faster than obtaining the pet, while still working towards the drop rate.
        </li>
      </ul>

      <h3>Exceptions</h3>
      Some diary steps are not on the wheel due mostly to overlap with the item table. These steps can be complete whenever you have the requirements to do so.
      <ul className="rules">
        <li><b>Falador Hard:</b> Enter the Mining Guild wearing full Prospector.</li>
        <li><b>Lumbridge Hard:</b> Purchase some Barrows gloves from the Culinaromancer's Chest.</li>
        <li><b>Morytania Hard:</b> Loot the Barrows chest while wearing any complete Barrows set.</li>
        <li><b>Western Provinces Elite:</b> Equip any complete void set.</li>
        <li><b>Karamja Elite:</b> Equip a fire cape in the TzHaar area.</li>
        <li><b>Desert Elite:</b> Speak to the Kq head in your POH.</li>
      </ul>

      <h3>Category Slots by Tier</h3>
      <ul className="rules">
        {[1,2,3,4,5,6].map((num,i) => 
          <li>
            <b>Tier {num}: </b>  
            {itemsByTier[i] > 0 && <span>{itemsByTier[i]} item(s)</span>}
            {questsByTier[i] > 0 && <span>, {questsByTier[i]} quest(s)</span>}
            {stepsByTier[i] > 0 && <span>, {stepsByTier[i]} diary step(s)</span>}
            {cluesByTier[i] > 0 && <span>, {cluesByTier[i]} clues</span>}
            {petsByTier[i] > 0 && <span>, {petsByTier[i]} pet</span>}
          </li>
        )}
        
      </ul>

      <h3>How the wheel works (this all happens automatically)</h3>
      <ul className="rules">
        <li>The wheel automatically adds tasks as needed. Each tier has a different number of diary, quest, item, and clue slots on the wheel at a time.</li>
        <li>Quests will only be added to the wheel if the prerequisite quests have been completed and/or quest points reached.</li>
        <li>Diary steps and items will only be added to the wheel if all quest requirements have been completed (e.g. Priest in Peril for a Grotesque Guardians task)</li>
        <li>Tasks are only added to the wheel if they are in the current tier are below.</li>
        <li>When a task on the wheel is complete, a new task of the same type takes its place.</li>
      </ul>
      <br />
      <br />
    </>
  );
}

export default Rules;