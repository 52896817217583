import _ from 'underscore';

import './ItemPanel.scss';
import { Items } from '../data/items';
import { Quests } from '../data/quests';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Table from '../Table/Table';
import { Item, Task } from '../types';
import { CheckPicker, SelectPicker } from 'rsuite';

import addIcon from '../img/add.png';
import { FormEvent, useEffect, useState } from 'react';
import { ItemDataType } from 'rsuite/esm/@types/common';

import 'rsuite/SelectPicker/styles/index.less';
import 'rsuite/CheckPicker/styles/index.less';
import { v4 } from 'uuid';


interface Props {
  onMarkedComplete?: Function;
  completed?: Task[];
  currentOptions?: Task[];
  readOnly?: boolean;
}


function ItemPanel(props: Props) {
  const [showItemForm, setShowItemForm] = useState(false);
  const [showItemJson, setShowItemJson] = useState(false);
  const [questData, setQuestData] = useState<Array<ItemDataType>>([]);
  const [itemData, setItemData] = useState<Array<ItemDataType>>([]);
  const [newItem, setNewItem] = useState<Item>({
    id: '',
    name: '',
    tier: 1,
    image: '',
    weight: 1,
    quests: '',
    items: [],
    points:50,
    notes: ''
  });


  useEffect(() => {
    const questItems: ItemDataType[] = Quests.map(quest => {
      return {
        value: quest.name,
        label: quest.name
      }
    });

    const itemItems: ItemDataType[] = Items.map(item => {
      return {
        value: item.id,
        label: item.name
      }
    });

    setQuestData(questItems);
    setItemData(itemItems);
  }, [setQuestData, setItemData, Quests, Items])

  const handleFormChange = (e: FormEvent<HTMLInputElement>) => {
    let item: Item = {...newItem}
    
    if(!Object.keys(item).includes(e.currentTarget.id)) return;

    // @ts-ignore
    item[e.currentTarget.id] = e.currentTarget.type === 'number' ? +e.currentTarget.value : e.currentTarget.value;

    setNewItem(item);
  }

  const handleQuestPicked = (quest: string) => {
    let item: Item = {...newItem};

    item.quests = quest;

    setNewItem(item);
  }

  const handleItemsPicked = (items: (string | number)[]) => {
    let item: Item = { ...newItem };

    item.items = items.map(i => i.toString());

    setNewItem(item);
  }

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    let item = {...newItem};

    item.id = v4();

    copyTextToClipboard(JSON.stringify(item).concat(','));
  }

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  return (
    <div className="ItemPanel">
      { process.env.NODE_ENV === 'development' && 
        <div className="addItem">
          <button className="addItem__button" onClick={() => setShowItemForm(true)}>
            <img src={addIcon} alt="Add item" className="addItem__icon"></img> Add item
          </button>

          { showItemForm && 
            <form className="addItem__form" onSubmit={handleFormSubmit}>
              <label>Item name</label>
              <input id='name' type="text" onChange={handleFormChange}></input>
              <label>Icon URL</label>
              <input id="image" type="text" onChange={handleFormChange}></input>
              <span style={{color: 'white'}}>Icon</span>
              <img src={newItem?.image} width="32"></img>
              <label>Item tier</label>
              <input id='tier' type="number" defaultValue={1} className="addItem__form-number" min={1} max={6} onChange={handleFormChange}></input>
              <label>Weighting</label>
              <input id='weight' type="number" defaultValue={1} className="addItem__form-number" onChange={handleFormChange}></input>
              <label>Points</label>
              <input id='points' type="number" defaultValue={50} className="addItem__form-number" onChange={handleFormChange}></input>
              <label>Notes</label>
              <input id='notes' type="text" onChange={handleFormChange}></input>
              <label>Quest Requirement</label>
              <SelectPicker id='quests' data={questData} onChange={(quest) => handleQuestPicked(quest.toString())} />
              <label>Item Requirements</label>
              <CheckPicker id='items' data={itemData} sticky={true} menuStyle={{ 'color': '#444' }} onChange={items => handleItemsPicked(items)}/>

              <button type="submit">Copy JSON to Clipboard</button>
              <button type="button" onClick={() => setShowItemForm(false)} className="addItem__form-close">Close</button>
            </form>
          }
        </div>
      }
      <Tabs>
        <TabList>
          <Tab>All Tiers</Tab>
          <Tab>Tier 1</Tab>
          <Tab>Tier 2</Tab>
          <Tab>Tier 3</Tab>
          <Tab>Tier 4</Tab>
          <Tab>Tier 5</Tab>
          <Tab>Tier 6</Tab>
        </TabList>

        {
            [0,1,2,3,4,5,6].map(num =>
                <TabPanel key={num} className="ItemPanel__container">
                    <Table
                      readOnly={props.readOnly || false}
                      tableHeaders={["Item", "", "Notes", "Quest Reqs", "Tier"]}
                      indexes={["name", "image", "notes", "quests", "tier"]}
                      sortableCols={[true, false, false, false, true]}
                      idIndex="id"
                      data={Items}
                      tier={num}
                      completedIds={props.completed ? _.pluck(props.completed, 'referenceId') : []}
                      onMarkedComplete={(task: any) => props.onMarkedComplete ? props.onMarkedComplete(task) : null}
                      defaultSort={{ index: 'name', asc: true }}
                      currentOptions={props.currentOptions}
                    ></Table>
                </TabPanel>
            )
        }
      </Tabs>
    </div>
  );
}

export default ItemPanel;
