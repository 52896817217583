import { Quest } from '../types';

export const Quests: Quest[] = [
    {
        "tier": 1,
        "miniquest": false,
        "name": "A Porcine of Interest",
        "shortName": "aPorcineOfInterest",
        "url": "https://oldschool.runescape.wiki/w/A_Porcine_of_Interest",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "slayer",
                    "amount": 1000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Getting Ahead",
        "shortName": "gettingAhead",
        "url": "https://oldschool.runescape.wiki/w/Getting_Ahead",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "construction",
                    "level": 26,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 4000
                },
                {
                    "skill": "construction",
                    "amount": 3200
                }
            ],
            "QuestPoints": 1
        }
    }, 
    {
        "tier": 1,
        "miniquest": false,
        "name": "Below Ice Mountain",
        "shortName": "belowIceMountain",
        "url": "https://oldschool.runescape.wiki/w/Below_Ice_Mountain",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "quest",
                    "level": 36,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "A Soul's Bane",
        "shortName": "aSoulsBane",
        "url": "https://oldschool.runescape.wiki/w/A_Soul%27s_Bane",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "defence",
                    "amount": 500
                },
                {
                    "skill": "hitpoints",
                    "amount": 500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "A Tail of Two Cats",
        "shortName": "aTailOfTwoCats",
        "url": "https://oldschool.runescape.wiki/w/A_Tail_of_Two_Cats",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Icthlarin's Little Helper"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        },
        "series": "Dragonkin"
    },
    {
        "tier": 3,
        "miniquest": false,
        "name": "A Taste of Hope",
        "shortName": "aTasteOfHope",
        "url": "https://oldschool.runescape.wiki/w/A_Taste_of_Hope",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Darkness of Hallowvale"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 48,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 45,
                    "boostable": false
                },
                {
                    "skill": "attack",
                    "level": 40,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 40,
                    "boostable": false
                },
                {
                    "skill": "slayer",
                    "level": 38,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Myreque"
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Animal Magnetism",
        "shortName": "animalMagnetism",
        "url": "https://oldschool.runescape.wiki/w/Animal_Magnetism",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "The Restless Ghost",
                "Ernest the Chicken",
                "Priest in Peril"
            ],
            "skills": [
                {
                    "skill": "slayer",
                    "level": 18,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 19,
                    "boostable": false
                },
                {
                    "skill": "ranged",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "woodcutting",
                    "level": 35,
                    "boostable": false
                },
                {
                    "skill": "prayer",
                    "level": 31,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 1000
                },
                {
                    "skill": "fletching",
                    "amount": 1000
                },
                {
                    "skill": "slayer",
                    "amount": 1000
                },
                {
                    "skill": "woodcutting",
                    "amount": 2500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Another Slice of H.A.M.",
        "shortName": "anotherSliceOfHAM",
        "url": "https://oldschool.runescape.wiki/w/Another_Slice_of_HAM",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Death to the Dorgeshuun",
                "The Giant Dwarf",
                "The Dig Site"
            ],
            "skills": [
                {
                    "skill": "attack",
                    "level": 15,
                    "boostable": false
                },
                {
                    "skill": "prayer",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "mining",
                    "amount": 3000
                },
                {
                    "skill": "prayer",
                    "amount": 3000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Dorgeshuun (#4)"
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Between a Rock...",
        "shortName": "betweenARock",
        "url": "https://oldschool.runescape.wiki/w/Between_a_Rock...",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Dwarf Cannon",
                "Fishing Contest"
            ],
            "skills": [
                {
                    "skill": "defence",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 40,
                    "boostable": true
                },
                {
                    "skill": "smithing",
                    "level": 50,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "defence",
                    "amount": 5000
                },
                {
                    "skill": "mining",
                    "amount": 5000
                },
                {
                    "skill": "smithing",
                    "amount": 5000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Rise of the Red Axe"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Big Chompy Bird Hunting",
        "shortName": "bigChompyBirdHunting",
        "url": "https://oldschool.runescape.wiki/w/Big_Chompy_Bird_Hunting",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "fletching",
                    "level": 5,
                    "boostable": false
                },
                {
                    "skill": "cooking",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "ranged",
                    "level": 30,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 1470
                },
                {
                    "skill": "fletching",
                    "amount": 262
                },
                {
                    "skill": "ranged",
                    "amount": 735
                }
            ],
            "QuestPoints": 2
        },
        "series": "Ogre"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Biohazard",
        "shortName": "biohazard",
        "url": "https://oldschool.runescape.wiki/w/Biohazard",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Plague City"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 1250
                }
            ],
            "QuestPoints": 3
        },
        "series": "Elf"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Black Knights' Fortress",
        "shortName": "blackKnightsFortress",
        "url": "https://oldschool.runescape.wiki/w/Black_Knights%27_Fortress",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "quest",
                    "level": 12,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 3
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Bone Voyage",
        "shortName": "boneVoyage",
        "url": "https://oldschool.runescape.wiki/w/Bone_Voyage",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [
                "The Dig Site",
                "Demon Slayer",
                "Rune Mysteries",
                "Shield of Arrav",
                "The Grand Tree",
                "Hazeel Cult"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        }
    },
    {
        "tier": 3,
        "miniquest": false,
        "name": "Cabin Fever",
        "shortName": "cabinFever",
        "url": "https://oldschool.runescape.wiki/w/Cabin_Fever",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Pirate's Treasure",
                "Rum Deal",
                "Priest in Peril"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 42,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 45,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "ranged",
                    "level": 40,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 7000
                },
                {
                    "skill": "crafting",
                    "amount": 7000
                },
                {
                    "skill": "smithing",
                    "amount": 7000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Pirate"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Client of Kourend",
        "shortName": "clientOfKourend",
        "url": "https://oldschool.runescape.wiki/w/Client_of_Kourend",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium (15-20 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Great Kourend"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Clock Tower",
        "shortName": "clockTower",
        "url": "https://oldschool.runescape.wiki/w/Clock_Tower",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Cold War",
        "shortName": "coldWar",
        "url": "https://oldschool.runescape.wiki/w/Cold_War",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "hunter",
                    "level": 10,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "construction",
                    "level": 34,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 15,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 5000
                },
                {
                    "skill": "construction",
                    "amount": 1500
                },
                {
                    "skill": "crafting",
                    "amount": 2000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Penguin"
    },
    {
        "tier": 3,
        "miniquest": false,
        "name": "Contact!",
        "shortName": "contact",
        "url": "https://oldschool.runescape.wiki/w/Contact!",
        "members": true,
        "difficulty": "Master",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Prince Ali Rescue",
                "Icthlarin's Little Helper"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 7000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Desert"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Cook's Assistant",
        "shortName": "cooksAssistant",
        "url": "https://oldschool.runescape.wiki/w/Cook%27s_Assistant",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short (<5 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 300
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Creature of Fenkenstrain",
        "shortName": "creatureOfFenkenstrain",
        "url": "https://oldschool.runescape.wiki/w/Creature_of_Fenkenstrain",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Priest in Peril",
                "The Restless Ghost"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 1000
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Darkness of Hallowvale",
        "shortName": "darknessOfHallowvale",
        "url": "https://oldschool.runescape.wiki/w/Darkness_of_Hallowvale",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "In Aid of the Myreque"
            ],
            "skills": [
                {
                    "skill": "construction",
                    "level": 5,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 20,
                    "boostable": true
                },
                {
                    "skill": "thieving",
                    "level": 22,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 26,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 32,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 33,
                    "boostable": false
                },
                {
                    "skill": "strength",
                    "level": 40,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 7000
                },
                {
                    "skill": "construction",
                    "amount": 2000
                },
                {
                    "skill": "thieving",
                    "amount": 6000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Myreque"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Death Plateau",
        "shortName": "deathPlateau",
        "url": "https://oldschool.runescape.wiki/w/Death_Plateau",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 3000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Troll"
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Death to the Dorgeshuun",
        "shortName": "deathToTheDorgeshuun",
        "url": "https://oldschool.runescape.wiki/w/Death_to_the_Dorgeshuun",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "The Lost Tribe"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 23,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 23,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "ranged",
                    "amount": 2000
                },
                {
                    "skill": "thieving",
                    "amount": 2000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Dorgeshuun"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Demon Slayer",
        "shortName": "demonSlayer",
        "url": "https://oldschool.runescape.wiki/w/Demon_Slayer",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 3
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Desert Treasure",
        "shortName": "desertTreasure",
        "url": "https://oldschool.runescape.wiki/w/Desert_Treasure",
        "members": true,
        "difficulty": "Master",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "The Dig Site",
                "Temple of Ikov",
                "The Tourist Trap",
                "Troll Stronghold",
                "Priest in Peril",
                "Waterfall Quest"
            ],
            "skills": [
                {
                    "skill": "thieving",
                    "level": 53,
                    "boostable": true
                },
                {
                    "skill": "firemaking",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "slayer",
                    "level": 10,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 50,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "magic",
                    "amount": 20000
                }
            ],
            "QuestPoints": 3
        },
        "series": "The Return of Zaros and Mahjarrat"
    },
    {
        "tier": 3,
        "miniquest": false,
        "name": "Devious Minds",
        "shortName": "deviousMinds",
        "url": "https://oldschool.runescape.wiki/w/Devious_Minds",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Wanted!",
                "Troll Stronghold",
                "Doric's Quest",
                "Enter the Abyss"
            ],
            "skills": [
                {
                    "skill": "smithing",
                    "level": 65,
                    "boostable": true
                },
                {
                    "skill": "runecraft",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "fletching",
                    "level": 50,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "fletching",
                    "amount": 5000
                },
                {
                    "skill": "runecraft",
                    "amount": 5000
                },
                {
                    "skill": "smithing",
                    "amount": 6500
                }
            ],
            "QuestPoints": 1
        },
        "series": "The Return of Zaros"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Doric's Quest",
        "shortName": "doricsQuest",
        "url": "https://oldschool.runescape.wiki/w/Doric%27s_Quest",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "mining",
                    "level": 15,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "mining",
                    "amount": 1300
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Dragon Slayer",
        "shortName": "dragonSlayer",
        "url": "https://oldschool.runescape.wiki/w/Dragon_Slayer",
        "members": false,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "quest",
                    "level": 32,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 8,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "defence",
                    "amount": 18650
                },
                {
                    "skill": "strength",
                    "amount": 18650
                }
            ],
            "QuestPoints": 2
        },
        "series": "Guild"
    },
    {
        "tier": 4,
        "miniquest": false,
        "name": "Dragon Slayer II",
        "shortName": "dragonSlayerII",
        "url": "https://oldschool.runescape.wiki/w/Dragon_Slayer_II",
        "members": true,
        "difficulty": "Grandmaster",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "Legends' Quest",
                "Dream Mentor",
                "A Tail of Two Cats",
                "Animal Magnetism",
                "Ghosts Ahoy",
                "Bone Voyage",
                "Client of Kourend"
            ],
            "skills": [
                {
                    "skill": "quest",
                    "level": 200,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 15000
                },
                {
                    "skill": "mining",
                    "amount": 18000
                },
                {
                    "skill": "smithing",
                    "amount": 25000
                },
                {
                    "skill": "thieving",
                    "amount": 15000
                }
            ],
            "QuestPoints": 5
        },
        "series": "Dragonkin"
    },
    {
        "tier": 3,
        "miniquest": false,
        "name": "Dream Mentor",
        "shortName": "dreamMentor",
        "url": "https://oldschool.runescape.wiki/w/Dream_Mentor",
        "members": true,
        "difficulty": "Master",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [
                "Lunar Diplomacy",
                "Eadgar's Ruse"
            ],
            "skills": [
                {
                    "skill": "combat",
                    "level": 85,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "hitpoints",
                    "amount": 15000
                },
                {
                    "skill": "magic",
                    "amount": 10000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Fremennik"
    },
    {
        "tier": 3,
        "miniquest": false,
        "name": "A Kingdom Divided",
        "shortName": "kingdomDivided",
        "url": "https://oldschool.runescape.wiki/w/A_Kingdom_Divided",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "X Marks the Spot",
                "The Queen of Thieves",
                "The Depths of Despair",
                "The Ascent of Arceuus",
                "The Forsaken Tower",
                "Tale of the Righteous"
            ],
            "skills": [
                {
                    "skill": "combat",
                    "level": 85,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        },
        "series": "Great Kourend"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Druidic Ritual",
        "shortName": "druidicRitual",
        "url": "https://oldschool.runescape.wiki/w/Druidic_Ritual",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "herblore",
                    "amount": 250
                }
            ],
            "QuestPoints": 4
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Dwarf Cannon",
        "shortName": "dwarfCannon",
        "url": "https://oldschool.runescape.wiki/w/Dwarf_Cannon",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 750
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Eadgar's Ruse",
        "shortName": "eadgarsRuse",
        "url": "https://oldschool.runescape.wiki/w/Eadgar%27s_Ruse",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Druidic Ritual",
                "Troll Stronghold"
            ],
            "skills": [
                {
                    "skill": "herblore",
                    "level": 31,
                    "boostable": true
                },
                {
                    "skill": "agility",
                    "level": 44,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "herblore",
                    "amount": 11000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Troll"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Eagles' Peak",
        "shortName": "eaglesPeak",
        "url": "https://oldschool.runescape.wiki/w/Eagles%27_Peak",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "hunter",
                    "level": 27,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "hunter",
                    "amount": 2500
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Elemental Workshop I",
        "shortName": "elementalWorkshopI",
        "url": "https://oldschool.runescape.wiki/w/Elemental_Workshop_I",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "mining",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 5000
                },
                {
                    "skill": "smithing",
                    "amount": 5000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Elemental Workshop"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Elemental Workshop II",
        "shortName": "elementalWorkshopII",
        "url": "https://oldschool.runescape.wiki/w/Elemental_Workshop_II",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Elemental Workshop I"
            ],
            "skills": [
                {
                    "skill": "magic",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 30,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 7500
                },
                {
                    "skill": "smithing",
                    "amount": 7500
                }
            ],
            "QuestPoints": 1
        },
        "series": "Elemental Workshop (#2)"
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Enakhra's Lament",
        "shortName": "enakhrasLament",
        "url": "https://oldschool.runescape.wiki/w/Enakhra%27s_Lament",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 45,
                    "boostable": false
                },
                {
                    "skill": "prayer",
                    "level": 43,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 39,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 45,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 7000
                },
                {
                    "skill": "firemaking",
                    "amount": 7000
                },
                {
                    "skill": "magic",
                    "amount": 7000
                },
                {
                    "skill": "mining",
                    "amount": 7000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Mahjarrat"
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Enlightened Journey",
        "shortName": "enlightenedJourney",
        "url": "https://oldschool.runescape.wiki/w/Enlightened_Journey",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short - Long",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "quest",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "farming",
                    "level": 30,
                    "boostable": true
                },
                {
                    "skill": "crafting",
                    "level": 36,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 2000
                },
                {
                    "skill": "farming",
                    "amount": 3000
                },
                {
                    "skill": "firemaking",
                    "amount": 4000
                },
                {
                    "skill": "woodcutting",
                    "amount": 1500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": true,
        "name": "Enter the Abyss",
        "shortName": "enterTheAbyss",
        "url": "https://oldschool.runescape.wiki/w/Enter_the_Abyss",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short \u00e2\u20ac\u201c Long",
        "requirements": {
            "quests": [
                "Rune Mysteries"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Ernest the Chicken",
        "shortName": "ernestTheChicken",
        "url": "https://oldschool.runescape.wiki/w/Ernest_the_Chicken",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 4
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Fairytale I - Growing Pains",
        "shortName": "fairytaleIGrowingPains",
        "url": "https://oldschool.runescape.wiki/w/Fairytale_I_-_Growing_Pains",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Lost City",
                "Nature Spirit"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 2000
                },
                {
                    "skill": "farming",
                    "amount": 3500
                },
                {
                    "skill": "magic",
                    "amount": 1000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Fairy Tale (#2)"
    },
    {
        "tier": 3,
        "miniquest": false,
        "name": "Fairytale II - Cure a Queen",
        "shortName": "fairytaleIICureAQueen",
        "url": "https://oldschool.runescape.wiki/w/Fairytale_II_-_Cure_a_Queen",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [
                "Fairytale I - Growing Pains"
            ],
            "skills": [
                {
                    "skill": "thieving",
                    "level": 40,
                    "boostable": true
                },
                {
                    "skill": "farming",
                    "level": 49,
                    "boostable": true
                },
                {
                    "skill": "herblore",
                    "level": 57,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "herblore",
                    "amount": 3500
                },
                {
                    "skill": "thieving",
                    "amount": 2500
                }
            ],
            "QuestPoints": 2
        },
        "series": "Fairy Tale (#2)"
    },
    {
        "tier": 2,
        "miniquest": false,
        "name": "Family Crest",
        "shortName": "familyCrest",
        "url": "https://oldschool.runescape.wiki/w/Family_Crest",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "mining",
                    "level": 40,
                    "boostable": true
                },
                {
                    "skill": "smithing",
                    "level": 40,
                    "boostable": true
                },
                {
                    "skill": "magic",
                    "level": 59,
                    "boostable": true
                },
                {
                    "skill": "crafting",
                    "level": 40,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Fight Arena",
        "shortName": "fightArena",
        "url": "https://oldschool.runescape.wiki/w/Fight_Arena",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 12175
                },
                {
                    "skill": "thieving",
                    "amount": 2175
                }
            ],
            "QuestPoints": 2
        },
        "series": "Mahjarrat"
    },
    {
        "tier": 1,
        "miniquest": false,
        "name": "Fishing Contest",
        "shortName": "fishingContest",
        "url": "https://oldschool.runescape.wiki/w/Fishing_Contest",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "fishing",
                    "level": 10,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "fishing",
                    "amount": 2437
                }
            ],
            "QuestPoints": 1
        },
        "series": "Dwarf"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "forgettableTale",
        "name": "Forgettable Tale...",
        "url": "https://oldschool.runescape.wiki/w/Forgettable_Tale...",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "The Giant Dwarf",
                "Fishing Contest"
            ],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 22,
                    "boostable": false
                },
                {
                    "skill": "farming",
                    "level": 17,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 5000
                },
                {
                    "skill": "farming",
                    "amount": 5000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Rise of the Red Axe"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "gardenOfTranquillity",
        "name": "Garden of Tranquillity",
        "url": "https://oldschool.runescape.wiki/w/Garden_of_Tranquillity",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "Creature of Fenkenstrain"
            ],
            "skills": [
                {
                    "skill": "farming",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "farming",
                    "amount": 5000
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "gertrudesCat",
        "name": "Gertrude's Cat",
        "url": "https://oldschool.runescape.wiki/w/Gertrude%27s_Cat",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 1525
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "ghostsAhoy",
        "name": "Ghosts Ahoy",
        "url": "https://oldschool.runescape.wiki/w/Ghosts_Ahoy",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Priest in Peril",
                "The Restless Ghost"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 25,
                    "boostable": true
                },
                {
                    "skill": "cooking",
                    "level": 20,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "prayer",
                    "amount": 2400
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "goblinDiplomacy",
        "name": "Goblin Diplomacy",
        "url": "https://oldschool.runescape.wiki/w/Goblin_Diplomacy",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 200
                }
            ],
            "QuestPoints": 5
        }
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "grimTales",
        "name": "Grim Tales",
        "url": "https://oldschool.runescape.wiki/w/Grim_Tales",
        "members": true,
        "difficulty": "Master",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Witch's House"
            ],
            "skills": [
                {
                    "skill": "farming",
                    "level": 45,
                    "boostable": true
                },
                {
                    "skill": "herblore",
                    "level": 52,
                    "boostable": true
                },
                {
                    "skill": "thieving",
                    "level": 58,
                    "boostable": true
                },
                {
                    "skill": "agility",
                    "level": 59,
                    "boostable": true
                },
                {
                    "skill": "woodcutting",
                    "level": 71,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 6000
                },
                {
                    "skill": "farming",
                    "amount": 4000
                },
                {
                    "skill": "herblore",
                    "amount": 5000
                },
                {
                    "skill": "hitpoints",
                    "amount": 5000
                },
                {
                    "skill": "thieving",
                    "amount": 6000
                },
                {
                    "skill": "woodcutting",
                    "amount": 14000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "hauntedMine",
        "name": "Haunted Mine",
        "url": "https://oldschool.runescape.wiki/w/Haunted_Mine",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Priest in Peril"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 15,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 35,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "strength",
                    "amount": 22000
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "hazeelCult",
        "name": "Hazeel Cult",
        "url": "https://oldschool.runescape.wiki/w/Hazeel_Cult",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 1500
                }
            ],
            "QuestPoints": 1
        },
        "series": "Mahjarrat"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "heroesQuest",
        "name": "Heroes' Quest",
        "url": "https://oldschool.runescape.wiki/w/Heroes%27_Quest",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Shield of Arrav",
                "Lost City",
                "Merlin's Crystal",
                "Dragon Slayer",
                "Druidic Ritual"
            ],
            "skills": [
                {
                    "skill": "quest",
                    "level": 55,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 3075
                },
                {
                    "skill": "cooking",
                    "amount": 2825
                },
                {
                    "skill": "defence",
                    "amount": 3075
                },
                {
                    "skill": "firemaking",
                    "amount": 1575
                },
                {
                    "skill": "fishing",
                    "amount": 2725
                },
                {
                    "skill": "herblore",
                    "amount": 1325
                },
                {
                    "skill": "hitpoints",
                    "amount": 3075
                },
                {
                    "skill": "mining",
                    "amount": 2575
                },
                {
                    "skill": "ranged",
                    "amount": 2075
                },
                {
                    "skill": "smithing",
                    "amount": 2257
                },
                {
                    "skill": "strength",
                    "amount": 3075
                },
                {
                    "skill": "woodcutting",
                    "amount": 1575
                }
            ],
            "QuestPoints": 1
        },
        "series": "Guild"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "holyGrail",
        "name": "Holy Grail",
        "url": "https://oldschool.runescape.wiki/w/Holy_Grail",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Merlin's Crystal"
            ],
            "skills": [
                {
                    "skill": "attack",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "defence",
                    "amount": 15300
                },
                {
                    "skill": "prayer",
                    "amount": 11000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Camelot (#2)"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "horrorFromTheDeep",
        "name": "Horror from the Deep",
        "url": "https://oldschool.runescape.wiki/w/Horror_from_the_Deep",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "agility",
                    "level": 35,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "magic",
                    "amount": 4662
                },
                {
                    "skill": "ranged",
                    "amount": 4662.5
                },
                {
                    "skill": "strength",
                    "amount": 4662.5
                }
            ],
            "QuestPoints": 2
        },
        "series": "Fremennik"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "icthlarinsLittleHelper",
        "name": "Icthlarin's Little Helper",
        "url": "https://oldschool.runescape.wiki/w/Icthlarin%27s_Little_Helper",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Gertrude's Cat"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 4000
                },
                {
                    "skill": "thieving",
                    "amount": 4500
                },
                {
                    "skill": "woodcutting",
                    "amount": 4000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Desert"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "impCatcher",
        "name": "Imp Catcher",
        "url": "https://oldschool.runescape.wiki/w/Imp_Catcher",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short (<5-15 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "magic",
                    "amount": 875
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "inAidOfTheMyreque",
        "name": "In Aid of the Myreque",
        "url": "https://oldschool.runescape.wiki/w/In_Aid_of_the_Myreque",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "In Search of the Myreque"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 25,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 15,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 7,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 2000
                },
                {
                    "skill": "crafting",
                    "amount": 2000
                },
                {
                    "skill": "defence",
                    "amount": 2000
                },
                {
                    "skill": "strength",
                    "amount": 2000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Myreque"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "inSearchOfTheMyreque",
        "name": "In Search of the Myreque",
        "url": "https://oldschool.runescape.wiki/w/In_Search_of_the_Myreque",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Nature Spirit"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 600
                },
                {
                    "skill": "crafting",
                    "amount": 600
                },
                {
                    "skill": "defence",
                    "amount": 600
                },
                {
                    "skill": "hitpoints",
                    "amount": 600
                },
                {
                    "skill": "strength",
                    "amount": 600
                }
            ],
            "QuestPoints": 2
        },
        "series": "Myreque"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "junglePotion",
        "name": "Jungle Potion",
        "url": "https://oldschool.runescape.wiki/w/Jungle_Potion",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [
                "Druidic Ritual"
            ],
            "skills": [
                {
                    "skill": "herblore",
                    "level": 3,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "herblore",
                    "amount": 775
                }
            ],
            "QuestPoints": 1
        },
        "series": "Karamja"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "kingsRansom",
        "name": "King's Ransom",
        "url": "https://oldschool.runescape.wiki/w/King%27s_Ransom",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Black Knights' Fortress",
                "Holy Grail",
                "Murder Mystery",
                "One Small Favour"
            ],
            "skills": [
                {
                    "skill": "magic",
                    "level": 45,
                    "boostable": false
                },
                {
                    "skill": "defence",
                    "level": 65,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "defence",
                    "amount": 33000
                },
                {
                    "skill": "magic",
                    "amount": 5000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Camelot (#3)"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "legendsQuest",
        "name": "Legends' Quest",
        "url": "https://oldschool.runescape.wiki/w/Legends%27_Quest",
        "members": true,
        "difficulty": "Master",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "Family Crest",
                "Heroes' Quest",
                "Shilo Village",
                "Underground Pass",
                "Waterfall Quest"
            ],
            "skills": [
                {
                    "skill": "quest",
                    "level": 107,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 50,
                    "boostable": true
                },
                {
                    "skill": "crafting",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 45,
                    "boostable": true
                },
                {
                    "skill": "magic",
                    "level": 56,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 52,
                    "boostable": true
                },
                {
                    "skill": "prayer",
                    "level": 42,
                    "boostable": true
                },
                {
                    "skill": "smithing",
                    "level": 50,
                    "boostable": true
                },
                {
                    "skill": "strength",
                    "level": 50,
                    "boostable": true
                },
                {
                    "skill": "thieving",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "woodcutting",
                    "level": 50,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 4
        },
        "series": "Karamja, Guild"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "lostCity",
        "name": "Lost City",
        "url": "https://oldschool.runescape.wiki/w/Lost_City",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 31,
                    "boostable": false
                },
                {
                    "skill": "woodcutting",
                    "level": 36,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 3
        },
        "series": "Fairy Tale"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "lunarDiplomacy",
        "name": "Lunar Diplomacy",
        "url": "https://oldschool.runescape.wiki/w/Lunar_Diplomacy",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "The Fremennik Trials",
                "Lost City",
                "Rune Mysteries",
                "Shilo Village"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 61,
                    "boostable": false
                },
                {
                    "skill": "defence",
                    "level": 40,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 49,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 65,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 60,
                    "boostable": false
                },
                {
                    "skill": "woodcutting",
                    "level": 55,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "magic",
                    "amount": 5000
                },
                {
                    "skill": "runecraft",
                    "amount": 5000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Fremennik"
    },
    {
        "tier": 4,
        "miniquest": false,
        "shortName": "makingFriendsWithMyArm",
        "name": "Making Friends with My Arm",
        "url": "https://oldschool.runescape.wiki/w/Making_Friends_with_My_Arm",
        "members": true,
        "difficulty": "Master",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "My Arm's Big Adventure",
                "Swan Song",
                "Cold War",
                "Romeo & Juliet"
            ],
            "skills": [
                {
                    "skill": "firemaking",
                    "level": 66,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 72,
                    "boostable": false
                },
                {
                    "skill": "construction",
                    "level": 35,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 68,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 10000
                },
                {
                    "skill": "construction",
                    "amount": 2000
                },
                {
                    "skill": "firemaking",
                    "amount": 5000
                },
                {
                    "skill": "mining",
                    "amount": 10000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Troll"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "makingHistory",
        "name": "Making History",
        "url": "https://oldschool.runescape.wiki/w/Making_History",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short - Long",
        "requirements": {
            "quests": [
                "Priest in Peril",
                "The Restless Ghost"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 1000
                },
                {
                    "skill": "prayer",
                    "amount": 1000
                }
            ],
            "QuestPoints": 3
        },
        "series": "Enchanted Key"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "merlinsCrystal",
        "name": "Merlin's Crystal",
        "url": "https://oldschool.runescape.wiki/w/Merlin%27s_Crystal",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium-Long (Under 10 minutes with teleports)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 6
        },
        "series": "Camelot"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "misthalinMystery",
        "name": "Misthalin Mystery",
        "url": "https://oldschool.runescape.wiki/w/Misthalin_Mystery",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 600
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "monksFriend",
        "name": "Monk's Friend",
        "url": "https://oldschool.runescape.wiki/w/Monk%27s_Friend",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Very Short (5 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "woodcutting",
                    "amount": 2000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "monkeyMadnessI",
        "name": "Monkey Madness I",
        "url": "https://oldschool.runescape.wiki/w/Monkey_Madness_I",
        "members": true,
        "difficulty": "Master",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "The Grand Tree",
                "Tree Gnome Village"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 3
        },
        "series": "Gnome, Monkey"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "monkeyMadnessII",
        "name": "Monkey Madness II",
        "url": "https://oldschool.runescape.wiki/w/Monkey_Madness_II",
        "members": true,
        "difficulty": "Grandmaster",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "Enlightened Journey",
                "The Eyes of Glouphrie",
                "Defeating the Culinaromancer",
                "Troll Stronghold",
                "Watchtower"
            ],
            "skills": [
                {
                    "skill": "slayer",
                    "level": 69,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "hunter",
                    "level": 60,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 55,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 55,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 60,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 20000
                },
                {
                    "skill": "hunter",
                    "amount": 15000
                },
                {
                    "skill": "slayer",
                    "amount": 25000
                },
                {
                    "skill": "thieving",
                    "amount": 15000
                }
            ],
            "QuestPoints": 4
        },
        "series": "Gnome, Monkey"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "mountainDaughter",
        "name": "Mountain Daughter",
        "url": "https://oldschool.runescape.wiki/w/Mountain_Daughter",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "agility",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 1000
                },
                {
                    "skill": "prayer",
                    "amount": 2000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Fremennik"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "mourningsEndPartI",
        "name": "Mourning's End Part I",
        "url": "https://oldschool.runescape.wiki/w/Mourning%27s_Ends_Part_I",
        "members": true,
        "difficulty": "Master",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Roving Elves",
                "Big Chompy Bird Hunting",
                "Sheep Herder"
            ],
            "skills": [
                {
                    "skill": "ranged",
                    "level": 60,
                    "boostable": true
                },
                {
                    "skill": "thieving",
                    "level": 50,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "hitpoints",
                    "amount": 25000
                },
                {
                    "skill": "thieving",
                    "amount": 25000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Elf"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "mourningsEndPartII",
        "name": "Mourning's End Part II",
        "url": "https://oldschool.runescape.wiki/w/Mourning%27s_Ends_Part_II",
        "members": true,
        "difficulty": "Master",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Mourning's End Part I"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 20000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Elf"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "murderMystery",
        "name": "Murder Mystery",
        "url": "https://oldschool.runescape.wiki/w/Murder_Mystery",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 1406
                }
            ],
            "QuestPoints": 3
        },
        "series": "Camelot"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "myArmsBigAdventure",
        "name": "My Arm's Big Adventure",
        "url": "https://oldschool.runescape.wiki/w/My_Arm%27s_Big_Adventure",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Eadgar's Ruse",
                "The Feud",
                "Jungle Potion"
            ],
            "skills": [
                {
                    "skill": "woodcutting",
                    "level": 10,
                    "boostable": false
                },
                {
                    "skill": "farming",
                    "level": 29,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "farming",
                    "amount": 5000
                },
                {
                    "skill": "herblore",
                    "amount": 10000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Troll"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "natureSpirit",
        "name": "Nature Spirit",
        "url": "https://oldschool.runescape.wiki/w/Nature_Spirit",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Priest in Peril",
                "The Restless Ghost"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 18,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 3000
                },
                {
                    "skill": "defence",
                    "amount": 2000
                },
                {
                    "skill": "hitpoints",
                    "amount": 2000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Myreque"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "observatoryQuest",
        "name": "Observatory Quest",
        "url": "https://oldschool.runescape.wiki/w/Observatory_Quest",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 10,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 2250
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "olafsQuest",
        "name": "Olaf's Quest",
        "url": "https://oldschool.runescape.wiki/w/Olaf%27s_Quest",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short - Long",
        "requirements": {
            "quests": [
                "The Fremennik Trials"
            ],
            "skills": [
                {
                    "skill": "firemaking",
                    "level": 40,
                    "boostable": false
                },
                {
                    "skill": "woodcutting",
                    "level": 50,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "defence",
                    "amount": 12000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Fremennik"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "oneSmallFavour",
        "name": "One Small Favour",
        "url": "https://oldschool.runescape.wiki/w/One_Small_Favour",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "Rune Mysteries",
                "Shilo Village",
                "Druidic Ritual"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 36,
                    "boostable": true
                },
                {
                    "skill": "crafting",
                    "level": 25,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 18,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 30,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        },
        "series": "Karamja"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "piratesTreasure",
        "name": "Pirate's Treasure",
        "url": "https://oldschool.runescape.wiki/w/Pirate%27s_Treasure",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        },
        "series": "Pirate"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "plagueCity",
        "name": "Plague City",
        "url": "https://oldschool.runescape.wiki/w/Plague_City",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "mining",
                    "amount": 2425
                }
            ],
            "QuestPoints": 1
        },
        "series": "Elf"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "priestInPeril",
        "name": "Priest in Peril",
        "url": "https://oldschool.runescape.wiki/w/Priest_in_Peril",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "prayer",
                    "amount": 1406
                }
            ],
            "QuestPoints": 1
        },
        "series": "Myreque"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "princeAliRescue",
        "name": "Prince Ali Rescue",
        "url": "https://oldschool.runescape.wiki/w/Prince_Ali_Rescue",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 3
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "ragAndBoneManI",
        "name": "Rag and Bone Man",
        "url": "https://oldschool.runescape.wiki/w/Rag_and_Bone_Man",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 500
                },
                {
                    "skill": "prayer",
                    "amount": 500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "ragAndBoneManII",
        "name": "Rag and Bone Man II",
        "url": "https://oldschool.runescape.wiki/w/Rag_and_Bone_Man_II",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Rag and Bone Man",
                "Skippy and the Mogres",
                "Creature of Fenkenstrain",
                "Zogre Flesh Eaters",
                "Waterfall Quest"
            ],
            "skills": [
                {
                    "skill": "slayer",
                    "level": 40,
                    "boostable": false
                },
                {
                    "skill": "defence",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "prayer",
                    "amount": 5000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "ratcatchers",
        "name": "Ratcatchers",
        "url": "https://oldschool.runescape.wiki/w/Ratcatchers",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long - Very long",
        "requirements": {
            "quests": [
                "Icthlarin's Little Helper",
                "The Giant Dwarf"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 4500
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "recruitmentDrive",
        "name": "Recruitment Drive",
        "url": "https://oldschool.runescape.wiki/w/Recruitment_Drive",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Black Knights' Fortress",
                "Druidic Ritual"
            ],
            "skills": [
                {
                    "skill": "quest",
                    "level": 12,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 1000
                },
                {
                    "skill": "herblore",
                    "amount": 1000
                },
                {
                    "skill": "prayer",
                    "amount": 1000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Temple Knight"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "regicide",
        "name": "Regicide",
        "url": "https://oldschool.runescape.wiki/w/Regicide",
        "members": true,
        "difficulty": "Master",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Underground Pass"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 56,
                    "boostable": true
                },
                {
                    "skill": "crafting",
                    "level": 10,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 13750
                }
            ],
            "QuestPoints": 3
        },
        "series": "Elf"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "romeoJuliet",
        "name": "Romeo & Juliet",
        "url": "https://oldschool.runescape.wiki/w/Romeo_%26_Juliet",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 5
        }
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "rovingElves",
        "name": "Roving Elves",
        "url": "https://oldschool.runescape.wiki/w/Roving_Elves",
        "members": true,
        "difficulty": "Master",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [
                "Regicide",
                "Waterfall Quest"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "strength",
                    "amount": 10000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Elf, Gnome"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "royalTrouble",
        "name": "Royal Trouble",
        "url": "https://oldschool.runescape.wiki/w/Royal_Trouble",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Throne of Miscellania"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 40,
                    "boostable": true
                },
                {
                    "skill": "slayer",
                    "level": 40,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 5000
                },
                {
                    "skill": "hitpoints",
                    "amount": 5000
                },
                {
                    "skill": "slayer",
                    "amount": 5000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Fremennik"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "rumDeal",
        "name": "Rum Deal",
        "url": "https://oldschool.runescape.wiki/w/Rum_Deal",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Zogre Flesh Eaters",
                "Priest in Peril"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 42,
                    "boostable": false
                },
                {
                    "skill": "fishing",
                    "level": 50,
                    "boostable": true
                },
                {
                    "skill": "farming",
                    "level": 40,
                    "boostable": true
                },
                {
                    "skill": "prayer",
                    "level": 47,
                    "boostable": true
                },
                {
                    "skill": "slayer",
                    "level": 42,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "farming",
                    "amount": 7000
                },
                {
                    "skill": "fishing",
                    "amount": 7000
                },
                {
                    "skill": "prayer",
                    "amount": 7000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Pirate"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "runeMysteries",
        "name": "Rune Mysteries",
        "url": "https://oldschool.runescape.wiki/w/Rune_Mysteries",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short (<5 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "scorpionCatcher",
        "name": "Scorpion Catcher",
        "url": "https://oldschool.runescape.wiki/w/Scorpion_Catcher",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "prayer",
                    "level": 31,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "strength",
                    "amount": 6625
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "seaSlug",
        "name": "Sea Slug",
        "url": "https://oldschool.runescape.wiki/w/Sea_Slug",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "firemaking",
                    "level": 30,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "fishing",
                    "amount": 7175
                }
            ],
            "QuestPoints": 1
        },
        "series": "Sea Slug"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "shadesOfMortton",
        "name": "Shades of Mort'ton",
        "url": "https://oldschool.runescape.wiki/w/Shades_of_Mort%27ton",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Priest in Peril"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 15,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 5,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 2000
                },
                {
                    "skill": "herblore",
                    "amount": 2000
                }
            ],
            "QuestPoints": 3
        },
        "series": "Mort'ton"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "shadowOfTheStorm",
        "name": "Shadow of the Storm",
        "url": "https://oldschool.runescape.wiki/w/Shadow_of_the_Storm",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "The Golem",
                "Demon Slayer"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 30,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "sheepHerder",
        "name": "Sheep Herder",
        "url": "https://oldschool.runescape.wiki/w/Sheep_Herder",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short/Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 4
        },
        "series": "Plague/Elf"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "sheepShearer",
        "name": "Sheep Shearer",
        "url": "https://oldschool.runescape.wiki/w/Sheep_Shearer",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 150
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "shieldOfArrav",
        "name": "Shield of Arrav",
        "url": "https://oldschool.runescape.wiki/w/Shield_of_Arrav",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Mahjarrat"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "shiloVillage",
        "name": "Shilo Village",
        "url": "https://oldschool.runescape.wiki/w/Shilo_Village",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Jungle Potion"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 32,
                    "boostable": true
                },
                {
                    "skill": "smithing",
                    "level": 4,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 3875
                }
            ],
            "QuestPoints": 2
        },
        "series": "Karamja"
    },
    {
        "tier": 4,
        "miniquest": false,
        "shortName": "songOfTheElves",
        "name": "Song of the Elves",
        "url": "https://oldschool.runescape.wiki/w/Song_of_the_Elves",
        "members": true,
        "difficulty": "Grandmaster",
        "questLength": "Very long",
        "requirements": {
            "quests": [
                "Mourning's End Part II",
                "Making History"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "construction",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "farming",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "hunter",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "woodcutting",
                    "level": 70,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 20000
                },
                {
                    "skill": "construction",
                    "amount": 20000
                },
                {
                    "skill": "farming",
                    "amount": 20000
                },
                {
                    "skill": "herblore",
                    "amount": 20000
                },
                {
                    "skill": "hunter",
                    "amount": 20000
                },
                {
                    "skill": "mining",
                    "amount": 20000
                },
                {
                    "skill": "smithing",
                    "amount": 20000
                },
                {
                    "skill": "woodcutting",
                    "amount": 20000
                }
            ],
            "QuestPoints": 4
        },
        "series": "Elf"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "spiritsOfTheElid",
        "name": "Spirits of the Elid",
        "url": "https://oldschool.runescape.wiki/w/Spirits_of_the_Elid",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "magic",
                    "level": 33,
                    "boostable": true
                },
                {
                    "skill": "ranged",
                    "level": 37,
                    "boostable": true
                },
                {
                    "skill": "mining",
                    "level": 37,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 37,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "magic",
                    "amount": 1000
                },
                {
                    "skill": "prayer",
                    "amount": 8000
                },
                {
                    "skill": "thieving",
                    "amount": 1000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Desert"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "swanSong",
        "name": "Swan Song",
        "url": "https://oldschool.runescape.wiki/w/Swan_Song",
        "members": true,
        "difficulty": "Master",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "One Small Favour",
                "Garden of Tranquillity"
            ],
            "skills": [
                {
                    "skill": "quest",
                    "level": 100,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 66,
                    "boostable": true
                },
                {
                    "skill": "cooking",
                    "level": 62,
                    "boostable": true
                },
                {
                    "skill": "fishing",
                    "level": 62,
                    "boostable": true
                },
                {
                    "skill": "smithing",
                    "level": 45,
                    "boostable": true
                },
                {
                    "skill": "firemaking",
                    "level": 42,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 40,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "fishing",
                    "amount": 10000
                },
                {
                    "skill": "magic",
                    "amount": 15000
                },
                {
                    "skill": "prayer",
                    "amount": 10000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Wise Old Man"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "taiBwoWannaiTrio",
        "name": "Tai Bwo Wannai Trio",
        "url": "https://oldschool.runescape.wiki/w/Tai_Bwo_Wannai_Trio",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Jungle Potion"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 15,
                    "boostable": false
                },
                {
                    "skill": "cooking",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "fishing",
                    "level": 5,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 2500
                },
                {
                    "skill": "cooking",
                    "amount": 5000
                },
                {
                    "skill": "fishing",
                    "amount": 5000
                },
                {
                    "skill": "strength",
                    "amount": 2500
                }
            ],
            "QuestPoints": 2
        },
        "series": "Karamja"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "taleOfTheRighteous",
        "name": "Tale of the Righteous",
        "url": "https://oldschool.runescape.wiki/w/Tale_of_the_Righteous",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Client of Kourend"
            ],
            "skills": [
                {
                    "skill": "strength",
                    "level": 16,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 10,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Great Kourend"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "tearsOfGuthix",
        "name": "Tears of Guthix",
        "url": "https://oldschool.runescape.wiki/w/Tears_of_Guthix_(quest)",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "quest",
                    "level": 43,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 49,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 1000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "templeOfIkov",
        "name": "Temple of Ikov",
        "url": "https://oldschool.runescape.wiki/w/Temple_of_Ikov",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "thieving",
                    "level": 42,
                    "boostable": true
                },
                {
                    "skill": "ranged",
                    "level": 40,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "fletching",
                    "amount": 8000
                },
                {
                    "skill": "ranged",
                    "amount": 10500
                }
            ],
            "QuestPoints": 1
        },
        "series": "Mahjarrat"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theAscentOfArceuus",
        "name": "The Ascent of Arceuus",
        "url": "https://oldschool.runescape.wiki/w/The_Ascent_of_Arceuus",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Client of Kourend"
            ],
            "skills": [
                {
                    "skill": "hunter",
                    "level": 12,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "hunter",
                    "amount": 1500
                },
                {
                    "skill": "runecraft",
                    "amount": 500
                }
            ],
            "QuestPoints": 1
        },
        "series": "Great Kourend"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theCorsairCurse",
        "name": "The Corsair Curse",
        "url": "https://oldschool.runescape.wiki/w/The_Corsair_Curse",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theDepthsOfDespair",
        "name": "The Depths of Despair",
        "url": "https://oldschool.runescape.wiki/w/The_Depths_of_Despair",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Client of Kourend"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 18,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 1500
                }
            ],
            "QuestPoints": 1
        },
        "series": "Great Kourend"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theDigSite",
        "name": "The Dig Site",
        "url": "https://oldschool.runescape.wiki/w/The_Dig_Site",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Druidic Ritual"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 10,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 10,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "herblore",
                    "amount": 2000
                },
                {
                    "skill": "mining",
                    "amount": 15300
                }
            ],
            "QuestPoints": 2
        },
        "series": "The Return of Zaros"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theEyesOfGlouphrie",
        "name": "The Eyes of Glouphrie",
        "url": "https://oldschool.runescape.wiki/w/The_Eyes_of_Glouphrie",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "The Grand Tree"
            ],
            "skills": [
                {
                    "skill": "construction",
                    "level": 5,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 46,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "construction",
                    "amount": 250
                },
                {
                    "skill": "magic",
                    "amount": 12000
                },
                {
                    "skill": "runecraft",
                    "amount": 6000
                },
                {
                    "skill": "woodcutting",
                    "amount": 2500
                }
            ],
            "QuestPoints": 2
        },
        "series": "Gnome"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "theFeud",
        "name": "The Feud",
        "url": "https://oldschool.runescape.wiki/w/The_Feud",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "thieving",
                    "level": 30,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 15000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Desert"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theForsakenTower",
        "name": "The Forsaken Tower",
        "url": "https://oldschool.runescape.wiki/w/The_Forsaken_Tower",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Client of Kourend"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "mining",
                    "amount": 500
                },
                {
                    "skill": "smithing",
                    "amount": 500
                }
            ],
            "QuestPoints": 1
        },
        "series": "Great Kourend"
    },
    {
        "tier": 4,
        "miniquest": false,
        "shortName": "theFremennikExiles",
        "name": "The Fremennik Exiles",
        "url": "https://oldschool.runescape.wiki/w/The_Fremennik_Exiles",
        "members": true,
        "difficulty": "Master",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "The Fremennik Isles",
                "Lunar Diplomacy",
                "Mountain Daughter",
                "Heroes' Quest"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 65,
                    "boostable": false
                },
                {
                    "skill": "slayer",
                    "level": 60,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 60,
                    "boostable": false
                },
                {
                    "skill": "fishing",
                    "level": 60,
                    "boostable": false
                },
                {
                    "skill": "runecraft",
                    "level": 55,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 15000
                },
                {
                    "skill": "runecraft",
                    "amount": 5000
                },
                {
                    "skill": "slayer",
                    "amount": 15000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Fremennik"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "theFremennikIsles",
        "name": "The Fremennik Isles",
        "url": "https://oldschool.runescape.wiki/w/The_Fremennik_Isles",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "The Fremennik Trials"
            ],
            "skills": [
                {
                    "skill": "construction",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 40,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "construction",
                    "amount": 5000
                },
                {
                    "skill": "crafting",
                    "amount": 5000
                },
                {
                    "skill": "woodcutting",
                    "amount": 10000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Fremennik"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "theFremennikTrials",
        "name": "The Fremennik Trials",
        "url": "https://oldschool.runescape.wiki/w/The_Fremennik_Trials",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 2812
                },
                {
                    "skill": "attack",
                    "amount": 2812
                },
                {
                    "skill": "crafting",
                    "amount": 2812
                },
                {
                    "skill": "defence",
                    "amount": 2812
                },
                {
                    "skill": "fishing",
                    "amount": 2812
                },
                {
                    "skill": "fletching",
                    "amount": 2812
                },
                {
                    "skill": "hitpoints",
                    "amount": 2812
                },
                {
                    "skill": "strength",
                    "amount": 2812
                },
                {
                    "skill": "thieving",
                    "amount": 2812
                },
                {
                    "skill": "woodcutting",
                    "amount": 2812
                }
            ],
            "QuestPoints": 3
        },
        "series": "Fremennik"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "theGiantDwarf",
        "name": "The Giant Dwarf",
        "url": "https://oldschool.runescape.wiki/w/The_Giant_Dwarf",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 12,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 16,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 33,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 14,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 2500
                },
                {
                    "skill": "firemaking",
                    "amount": 1500
                },
                {
                    "skill": "magic",
                    "amount": 1500
                },
                {
                    "skill": "mining",
                    "amount": 2500
                },
                {
                    "skill": "smithing",
                    "amount": 2500
                },
                {
                    "skill": "thieving",
                    "amount": 1500
                }
            ],
            "QuestPoints": 2
        },
        "series": "Rise of the Red Axe"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theGolem",
        "name": "The Golem",
        "url": "https://oldschool.runescape.wiki/w/The_Golem",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 20,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 1000
                },
                {
                    "skill": "thieving",
                    "amount": 1000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theGrandTree",
        "name": "The Grand Tree",
        "url": "https://oldschool.runescape.wiki/w/The_Grand_Tree",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "agility",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 7900
                },
                {
                    "skill": "attack",
                    "amount": 18400
                },
                {
                    "skill": "magic",
                    "amount": 2150
                }
            ],
            "QuestPoints": 5
        },
        "series": "Gnome"
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "theGreatBrainRobbery",
        "name": "The Great Brain Robbery",
        "url": "https://oldschool.runescape.wiki/w/The_Great_Brain_Robbery",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Creature of Fenkenstrain",
                "Cabin Fever",
                "Freeing Pirate Pete"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 16,
                    "boostable": false
                },
                {
                    "skill": "construction",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "prayer",
                    "level": 50,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "construction",
                    "amount": 2000
                },
                {
                    "skill": "crafting",
                    "amount": 3000
                },
                {
                    "skill": "prayer",
                    "amount": 6000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Pirate"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "theHandInTheSand",
        "name": "The Hand in the Sand",
        "url": "https://oldschool.runescape.wiki/w/The_Hand_in_the_Sand",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "thieving",
                    "level": 17,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 49,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 9000
                },
                {
                    "skill": "thieving",
                    "amount": 1000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theKnightsSword",
        "name": "The Knight's Sword",
        "url": "https://oldschool.runescape.wiki/w/The_Knight%27s_Sword",
        "members": false,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "mining",
                    "level": 10,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "smithing",
                    "amount": 12725
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theLostTribe",
        "name": "The Lost Tribe",
        "url": "https://oldschool.runescape.wiki/w/The_Lost_Tribe",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Goblin Diplomacy",
                "Rune Mysteries"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 13,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 13,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 17,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "mining",
                    "amount": 3000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Dorgeshuun"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theQueenOfThieves",
        "name": "The Queen of Thieves",
        "url": "https://oldschool.runescape.wiki/w/The_Queen_of_Thieves",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Client of Kourend"
            ],
            "skills": [
                {
                    "skill": "thieving",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 2000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Great Kourend"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theRestlessGhost",
        "name": "The Restless Ghost",
        "url": "https://oldschool.runescape.wiki/w/The_Restless_Ghost",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short (<10 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "prayer",
                    "amount": 1125
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 4,
        "miniquest": false,
        "shortName": "sinsOfTheFather",
        "name": "Sins of the Father",
        "url": "https://oldschool.runescape.wiki/w/Sins_of_the_Father",
        "members": true,
        "difficulty": "Master",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Vampyre Slayer",
                "A Taste of Hope"
            ],
            "skills": [
                {
                    "skill": "woodcutting",
                    "level": 62,
                    "boostable": false
                },
                {
                    "skill": "fletching",
                    "level": 60,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 56,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 52,
                    "boostable": false
                },
                {
                    "skill": "attack",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "slayer",
                    "level": 50,
                    "boostable": false
                },
                {
                    "skill": "magic",
                    "level": 49,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        },
        "series": "Myreque"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "theSlugMenace",
        "name": "The Slug Menace",
        "url": "https://oldschool.runescape.wiki/w/The_Slug_Menace",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium - Long",
        "requirements": {
            "quests": [
                "Wanted!",
                "Sea Slug"
            ],
            "skills": [
                {
                    "skill": "crafting",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "runecraft",
                    "level": 30,
                    "boostable": true
                },
                {
                    "skill": "slayer",
                    "level": 30,
                    "boostable": true
                },
                {
                    "skill": "thieving",
                    "level": 30,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "crafting",
                    "amount": 3500
                },
                {
                    "skill": "runecraft",
                    "amount": 3500
                },
                {
                    "skill": "thieving",
                    "amount": 3500
                }
            ],
            "QuestPoints": 1
        },
        "series": "Sea Slug"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "theTouristTrap",
        "name": "The Tourist Trap",
        "url": "https://oldschool.runescape.wiki/w/The_Tourist_Trap",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "fletching",
                    "level": 10,
                    "boostable": false
                },
                {
                    "skill": "smithing",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        },
        "series": "Desert"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "throneOfMiscellania",
        "name": "Throne of Miscellania",
        "url": "https://oldschool.runescape.wiki/w/Throne_of_Miscellania",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Heroes' Quest",
                "The Fremennik Trials"
            ],
            "skills": [
                {
                    "skill": "woodcutting",
                    "level": 45,
                    "boostable": false
                },
                {
                    "skill": "farming",
                    "level": 10,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 30,
                    "boostable": false
                },
                {
                    "skill": "fishing",
                    "level": 35,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Fremennik"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "towerOfLife",
        "name": "Tower of Life",
        "url": "https://oldschool.runescape.wiki/w/Tower_of_Life",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "construction",
                    "level": 10,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "construction",
                    "amount": 1000
                },
                {
                    "skill": "crafting",
                    "amount": 500
                },
                {
                    "skill": "thieving",
                    "amount": 500
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "treeGnomeVillage",
        "name": "Tree Gnome Village",
        "url": "https://oldschool.runescape.wiki/w/Tree_Gnome_Village",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 11450
                }
            ],
            "QuestPoints": 2
        },
        "series": "Gnome"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "tribalTotem",
        "name": "Tribal Totem",
        "url": "https://oldschool.runescape.wiki/w/Tribal_Totem",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "thieving",
                    "level": 21,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "thieving",
                    "amount": 1775
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "trollRomance",
        "name": "Troll Romance",
        "url": "https://oldschool.runescape.wiki/w/Troll_Romance",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Troll Stronghold"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 8000
                },
                {
                    "skill": "strength",
                    "amount": 4000
                }
            ],
            "QuestPoints": 2
        },
        "series": "Troll"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "trollStronghold",
        "name": "Troll Stronghold",
        "url": "https://oldschool.runescape.wiki/w/Troll_Stronghold",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [
                "Death Plateau"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 15,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Troll"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "undergroundPass",
        "name": "Underground Pass",
        "url": "https://oldschool.runescape.wiki/w/Underground_Pass",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Biohazard"
            ],
            "skills": [
                {
                    "skill": "ranged",
                    "level": 25,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 3000
                },
                {
                    "skill": "attack",
                    "amount": 3000
                }
            ],
            "QuestPoints": 5
        },
        "series": "Elf"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "vampyreSlayer",
        "name": "Vampyre Slayer",
        "url": "https://oldschool.runescape.wiki/w/Vampyre_Slayer",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 4825
                }
            ],
            "QuestPoints": 3
        }
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "nightAtTheTheatre",
        "name": "A Night at the Theatre",
        "url": "https://oldschool.runescape.wiki/w/A_Night_at_the_Theatre",
        "members": true,
        "difficulty": "Master",
        "questLength": "Medium",
        "requirements": {
            "quests": ["A Taste of Hope"],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 2
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "wanted",
        "name": "Wanted!",
        "url": "https://oldschool.runescape.wiki/w/Wanted!",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Recruitment Drive",
                "The Lost Tribe",
                "Priest in Peril",
                "Enter the Abyss"
            ],
            "skills": [
                {
                    "skill": "quest",
                    "level": 32,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "slayer",
                    "amount": 5000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Temple Knight"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "watchtower",
        "name": "Watchtower",
        "url": "https://oldschool.runescape.wiki/w/Watchtower",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Long",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "magic",
                    "level": 15,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 15,
                    "boostable": false
                },
                {
                    "skill": "agility",
                    "level": 25,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 14,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 40,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "magic",
                    "amount": 15250
                }
            ],
            "QuestPoints": 4
        },
        "series": "Ogre"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "waterfallQuest",
        "name": "Waterfall Quest",
        "url": "https://oldschool.runescape.wiki/w/Waterfall_Quest",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium (10-20 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "attack",
                    "amount": 13750
                },
                {
                    "skill": "strength",
                    "amount": 13750
                }
            ],
            "QuestPoints": 1
        },
        "series": "Elf, Gnome"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "whatLiesBelow",
        "name": "What Lies Below",
        "url": "https://oldschool.runescape.wiki/w/What_Lies_Below",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short - Medium",
        "requirements": {
            "quests": [
                "Rune Mysteries"
            ],
            "skills": [
                {
                    "skill": "runecraft",
                    "level": 35,
                    "boostable": false
                },
                {
                    "skill": "mining",
                    "level": 42,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "defence",
                    "amount": 2000
                },
                {
                    "skill": "runecraft",
                    "amount": 8000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "witchsHouse",
        "name": "Witch's House",
        "url": "https://oldschool.runescape.wiki/w/Witch%27s_House",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "hitpoints",
                    "amount": 6325
                }
            ],
            "QuestPoints": 4
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "witchsPotion",
        "name": "Witch's Potion",
        "url": "https://oldschool.runescape.wiki/w/Witch%27s_Potion",
        "members": false,
        "difficulty": "Novice",
        "questLength": "Short (<5 minutes)",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "magic",
                    "amount": 325
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "xMarksTheSpot",
        "name": "X Marks the Spot",
        "url": "https://oldschool.runescape.wiki/w/X_Marks_the_Spot",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Great Kourend"
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "zogreFleshEaters",
        "name": "Zogre Flesh Eaters",
        "url": "https://oldschool.runescape.wiki/w/Zogre_Flesh_Eaters",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Big Chompy Bird Hunting",
                "Jungle Potion"
            ],
            "skills": [
                {
                    "skill": "smithing",
                    "level": 4,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 8,
                    "boostable": false
                },
                {
                    "skill": "ranged",
                    "level": 30,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "fletching",
                    "amount": 2000
                },
                {
                    "skill": "herblore",
                    "amount": 2000
                },
                {
                    "skill": "ranged",
                    "amount": 2000
                }
            ],
            "QuestPoints": 1
        },
        "series": "Ogre"
    },
    {
        "tier": 1,
        "miniquest": true,
        "shortName": "bearYourSoul",
        "name": "Bear your Soul",
        "url": "https://oldschool.runescape.wiki/w/Bear_your_Soul",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 2,
        "miniquest": true,
        "shortName": "curseOfTheEmptyLord",
        "name": "Curse of the Empty Lord",
        "url": "https://oldschool.runescape.wiki/w/Curse_of_the_Empire_Lord",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Desert Treasure",
                "The Restless Ghost"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 1,
        "miniquest": true,
        "shortName": "daddysHome",
        "name": "Daddy's Home",
        "url": "https://oldschool.runescape.wiki/w/Daddy%27s_Home",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Medium",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "construction",
                    "amount": 400
                }
            ],
            "QuestPoints": 0
        }
    },
    {
        "tier": 1,
        "miniquest": true,
        "shortName": "enchantedKey",
        "name": "Enchanted Key",
        "url": "https://oldschool.runescape.wiki/w/Enchanted_Key",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Making History"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 3,
        "miniquest": true,
        "shortName": "familyPest",
        "name": "Family Pest",
        "url": "https://oldschool.runescape.wiki/w/Family_Pest",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Family Crest"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 2,
        "miniquest": true,
        "shortName": "theGeneralsShadow",
        "name": "The General's Shadow",
        "url": "https://oldschool.runescape.wiki/w/The_General%27s_Shadow",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Fight Arena",
                "Curse of the Empty Lord"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "slayer",
                    "amount": 2000
                }
            ],
            "QuestPoints": 0
        }
    },
    {
        "tier": 2,
        "miniquest": true,
        "shortName": "inSearchOfKnowledge",
        "name": "In Search of Knowledge",
        "url": "https://oldschool.runescape.wiki/w/In_Search_of_Knowledge",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Very Long",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 2,
        "miniquest": true,
        "shortName": "lairOfTarnRazorlor",
        "name": "Lair of Tarn Razorlor",
        "url": "https://oldschool.runescape.wiki/w/Lair of Tarn Razorlor",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Very Long",
        "requirements": {
            "quests": [
                "Haunted Mine"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "slayer",
                    "amount": 5000
                }
            ],
            "QuestPoints": 0
        }
    },
    {
        "tier": 2,
        "miniquest": true,
        "shortName": "theMageArena",
        "name": "The Mage Arena",
        "url": "https://oldschool.runescape.wiki/w/The_Mage_Arena",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Very Short",
        "requirements": {
            "quests": [],
            "skills": []
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 4,
        "miniquest": true,
        "shortName": "theMageArenaII",
        "name": "The Mage Arena II",
        "url": "https://oldschool.runescape.wiki/w/The_Mage_Arena_II",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "The Mage Arena"
            ],
            "skills": [
                {
                    "skill": "magic",
                    "level": 75,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 1,
        "miniquest": true,
        "shortName": "skippyAndTheMogres",
        "name": "Skippy and the Mogres",
        "url": "https://oldschool.runescape.wiki/w/Skippy_and_the_Mogres",
        "members": true,
        "difficulty": "n/a",
        "questLength": "Very Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "freeingPiratePete",
        "name": "Freeing Pirate Pete",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Pirate_Pete",
        "members": true,
        "difficulty": "Special",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Cook's Assistant"
            ],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 31,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 1
        },
        "series": "Pirate"
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "freeingTheMountainDwarf",
        "name": "Freeing the Mountain Dwarf",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Mountain_Dwarf",
        "members": true,
        "difficulty": "Special",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Fishing Contest",
                "Cook's Assistant"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "slayer",
                    "amount": 1000
                },
                {
                    "skill": "cooking",
                    "amount": 1000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "freeingTheGoblinGenerals",
        "name": "Freeing the Goblin Generals",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Goblin_generals",
        "members": true,
        "difficulty": "Special",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Goblin Diplomacy",
                "Cook's Assistant"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 1000
                },
                {
                    "skill": "crafting",
                    "amount": 1000
                },
                {
                    "skill": "farming",
                    "amount": 1000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "freeingTheLumbridgeGuide",
        "name": "Freeing the Lumbridge Guide",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Lumbridge_Guide",
        "members": true,
        "difficulty": "Special",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Big Chompy Bird Hunting",
                "Biohazard",
                "Demon Slayer",
                "Murder Mystery",
                "Nature Spirit",
                "Witch's House",
                "Cook's Assistant"
            ],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 40,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 2500
                },
                {
                    "skill": "magic",
                    "amount": 2500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "freeingEvilDave",
        "name": "Freeing Evil Dave",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Evil_Dave",
        "members": true,
        "difficulty": "Special",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Gertrude's Cat",
                "Shadow of the Storm",
                "Cook's Assistant"
            ],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 25,
                    "boostable": true
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 7000
                },
                {
                    "skill": "magic",
                    "amount": 2500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "freeingKingAwowogei",
        "name": "Freeing King Awowogei",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_King_Awowogei",
        "members": true,
        "difficulty": "Special",
        "questLength": "Long",
        "requirements": {
            "quests": [
                "Monkey Madness I",
                "Cook's Assistant"
            ],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 70,
                    "boostable": true
                },
                {
                    "skill": "agility",
                    "level": 48,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 10000
                },
                {
                    "skill": "agility",
                    "amount": 10000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "freeingSirAmikVarze",
        "name": "Freeing Sir Amik Varze",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Sir_Amik_Varze",
        "members": true,
        "difficulty": "Special",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Family Crest",
                "Heroes' Quest",
                "Shilo Village",
                "Underground Pass",
                "Waterfall Quest",
                "Cook's Assistant",
                "Legends' Quest"
            ],
            "skills": []
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 4000
                },
                {
                    "skill": "hitpoints",
                    "amount": 4000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "freeingSkrachUglogwee",
        "name": "Freeing Skrach Uglogwee",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Skrach_Uglogwee",
        "members": true,
        "difficulty": "Special",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Big Chompy Bird Hunting",
                "Cook's Assistant"
            ],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 41,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 1500
                },
                {
                    "skill": "woodcutting",
                    "amount": 1500
                },
                {
                    "skill": "crafting",
                    "amount": 1500
                },
                {
                    "skill": "ranged",
                    "amount": 1500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "defeatingTheCulinaromancer",
        "name": "Defeating the Culinaromancer",
        "url": "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Defeating_the_Culinaromancer",
        "members": true,
        "difficulty": "Special",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Desert Treasure",
                "Horror from the Deep",
                "Freeing Skrach Uglogwee",
                "Freeing Sir Amik Varze",
                "Freeing King Awowogei",
                "Freeing Evil Dave",
                "Freeing the Lumbridge Guide",
                "Freeing the Goblin Generals",
                "Freeing the Mountain Dwarf",
                "Freeing Pirate Pete"
            ],
            "skills": [
                {
                    "skill": "cooking",
                    "level": 41,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 20,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "cooking",
                    "amount": 1500
                },
                {
                    "skill": "woodcutting",
                    "amount": 1500
                },
                {
                    "skill": "crafting",
                    "amount": 1500
                },
                {
                    "skill": "ranged",
                    "amount": 1500
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 4,
        "miniquest": true,
        "shortName": "theFrozenDoor",
        "name": "The Frozen Door",
        "url": "https://oldschool.runescape.wiki/w/The_Frozen_Door",
        "members": true,
        "difficulty": "Master",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Desert Treasure"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "strength",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "ranged",
                    "level": 70,
                    "boostable": false
                },
                {
                    "skill": "hitpoints",
                    "level": 70,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [],
            "QuestPoints": 0
        }
    },
    {
        "tier": 2,
        "miniquest": false,
        "shortName": "landOfTheGoblins",
        "name": "Land of the Goblins",
        "url": "https://oldschool.runescape.wiki/w/Land_of_the_Goblins",
        "members": true,
        "difficulty": "Experienced",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Another Slice of H.A.M.",
                "Fishing Contest"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 38,
                    "boostable": false
                },
                {
                    "skill": "fishing",
                    "level": 40,
                    "boostable": false
                },
                {
                    "skill": "thieving",
                    "level": 45,
                    "boostable": false
                },
                {
                    "skill": "herblore",
                    "level": 48,
                    "boostable": false
                }
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 8000
                },
                {
                    "skill": "herblore",
                    "amount": 8000
                },
                {
                    "skill": "thieving",
                    "amount": 8000
                },
                {
                    "skill": "fishing",
                    "amount": 8000
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 2,
        "miniquest": true,
        "shortName": "hopespearsWill",
        "name": "Hopespear's Will",
        "url": "https://oldschool.runescape.wiki/w/Hopespear%27s_Will",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "The Restless Ghost",
                "Desert Treasure",
                "Fairytale II - Cure a Queen",
                "Land of the Goblins",
            ],
            "skills": [
                {
                    "skill": "prayer",
                    "level": 50,
                    "boostable": false
                },
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "prayer",
                    "amount": 38750
                }
            ],
            "QuestPoints": 0
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "templeOfTheEye",
        "name": "Temple of the Eye",
        "url": "https://oldschool.runescape.wiki/w/Temple_of_the_Eye",
        "members": true,
        "difficulty": "Novice",
        "questLength": "Short",
        "requirements": {
            "quests": [
                "Enter the Abyss"
            ],
            "skills": [
                {
                    "skill": "runecraft",
                    "level": 10,
                    "boostable": false
                },
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "runecraft",
                    "amount": 5000
                }
            ],
            "QuestPoints": 1
        }
    },
    {
        "tier": 3,
        "miniquest": false,
        "shortName": "beneathCursedSands",
        "name": "Beneath Cursed Sands",
        "url": "https://oldschool.runescape.wiki/w/Beneath_Cursed_Sands",
        "members": true,
        "difficulty": "Master",
        "questLength": "Medium",
        "requirements": {
            "quests": [
                "Contact!"
            ],
            "skills": [
                {
                    "skill": "agility",
                    "level": 62,
                    "boostable": false
                },
                {
                    "skill": "firemaking",
                    "level": 55,
                    "boostable": false
                },
                {
                    "skill": "crafting",
                    "level": 55,
                    "boostable": false
                },
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "agility",
                    "amount": 20000
                }
            ],
            "QuestPoints": 2
        }
    },
    {
        "tier": 1,
        "miniquest": false,
        "shortName": "sleepingGiants",
        "name": "Sleeping Giants",
        "url": "https://oldschool.runescape.wiki/w/Sleeping_Giants",
        "members": true,
        "difficulty": "Intermediate",
        "questLength": "Short",
        "requirements": {
            "quests": [],
            "skills": [
                {
                    "skill": "smithing",
                    "level": 15,
                    "boostable": false
                },
            ]
        },
        "rewards": {
            "Experience": [
                {
                    "skill": "smithing",
                    "amount": 6000
                }
            ],
            "QuestPoints": 1
        }
    },
]