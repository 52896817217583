import './App.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useCallback, useEffect, useState } from 'react';
import _ from 'underscore';
import AWS from 'aws-sdk';

import QuestPanel from './QuestPanel/QuestPanel';
import ItemPanel from './ItemPanel/ItemPanel';
import DiaryPanel from './DiaryPanel/DiaryPanel';
import PetPanel from './PetPanel/PetPanel';
import Wheel from './Wheel/Wheel';
import { Weighting } from './data/tierCompletion';
import { Items } from './data/items';
import { Task, Quest, DiaryStep, Item, WheelData, Pet, ClueTier } from './types';
import Rules from './Rules';
import { Rewards } from './Rewards';
import ReactTooltip from 'react-tooltip';
import mute from './img/mute.svg';
import unmute from './img/audio-speaker-on.svg';
import show from './img/icons8-eye-30.png';
import hide from './img/icons8-hide-30.png';
import CluePanel from './CluePanel/CluePanel';
import Balance from  './Balance/Balance';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

const s3 = new AWS.S3();

function App() {
  const [wheelId, setWheelId] = useState('');
  const [validId, setValidId] = useState(false);
  const [completedTasks, setCompletedTasks] = useState<Task[]>([]);
  const [points, setPoints] = useState(0);
  const [currentOptions, setCurrentOptions] = useState<Task[]>([]);
  const [currentTask, setCurrentTask] = useState<Task | undefined>();
  const [showId, setShowId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTier, setCurrentTier] = useState<number>(1);
  const [showError, setShowError] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(true);
  const [lastStateUpdate, setLastStateUpdate] = useState<number>(0);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [wheelAngle, setWheelAngle] = useState(0);

  const handleChecked = (task: Task, gotPet=false) => {
    if (task.category === 'Clue') {
      setCurrentTask(undefined);
      uploadWheelData({ clearTask: true });
      return;
    }

    if (task.category === 'Pet' && !gotPet) {
      setCurrentTask(undefined);
      let newOptions = [...currentOptions]
      newOptions.splice(newOptions.findIndex(t => t.referenceId === task.referenceId), 1);
      setCurrentOptions(newOptions);
      return;
    }

    if (task.category === 'Challenge') {
      setCurrentTier(currentTier + 1);
      setCurrentTask(undefined);
      uploadWheelData({ clearTask: true });
      return;
    }
    updateCompletedArray([...completedTasks], task);
  }

  const updateCompletedArray = (array: Task[], task: Task) => {
    if (task.category === 'Clue' || task.category === 'Challenge') return;

    let index = _.findIndex(array, (completedTask) => completedTask.referenceId === task.referenceId);

    if (index >= 0) array.splice(index, 1);
    else array.push(task);

    setCompletedTasks(array);
    recalculatePoints(array);

    // if task was not on the wheel (i.e. wheel will not auto repopulate)
    if (!_.pluck(currentOptions, 'referenceId').includes(task.referenceId)) uploadWheelData({ newCompleted: array });
  }

  const recalculatePoints = (arr?: Task[]) => {
    let tasksCopy = arr ? arr : completedTasks;

    const itemPoints = +tasksCopy.reduce((val, task) => {
      const item = _.find(Items, (item) => item.id === task.referenceId);
      if (!item) return val;

      return item.points + val;
    }, 0);

    const questPoints = tasksCopy.filter(task => task.category === 'Quest').length * 20;
    const diaryPoints = tasksCopy.filter(task => task.category === 'Diary').length * 10;

    const newPoints = questPoints + diaryPoints + itemPoints;

    setPoints(newPoints);
  }

  const createWheel = () => {
    const id = Math.random().toString().slice(2, 10);
    localStorage.setItem('wheelId', id);
    setWheelId(id);
    setValidId(true);
  }

  const getWheelData = useCallback((id: string) => {
    if (!process.env.REACT_APP_BUCKET) return false;
    if (!id) return;

    setShowError(false);
    if (!validId) setLoading(true);

    const parseWheelData = (wheelData: WheelData, id: string) => {
      setCompletedTasks(wheelData.completedTasks);
      setCurrentTier(wheelData.currentTier);
      setCurrentOptions(wheelData.currentOptions);
      setCurrentTask(wheelData.currentTask);
      if(wheelData.angle) setWheelAngle(wheelData.angle);

      setValidId(true);
      setWheelId(id);
      setLastStateUpdate(Date.now());
      localStorage.setItem('wheelId', id);
  
      return true;
    }

    if(process.env.NODE_ENV === 'development') {
      if (!localStorage.getItem(id)) return; 

      let wheelData: WheelData = JSON.parse(localStorage.getItem(id) || '{}');

      parseWheelData(wheelData, id);

      setLoading(false);

      return;
    }

    s3.getObject({ Bucket: process.env.REACT_APP_BUCKET, Key: `${id}.json`, }, (err, data) => {
      setLoading(false);
      if (err) {
        setShowError(true)
        throw err;
      }

      if (!data.Body) return false;

      const wheelData: WheelData | undefined = JSON.parse(data.Body?.toString());

      if (!wheelData) return false;

      if (wheelData.uploadTime && (wheelData.uploadTime >= lastStateUpdate && lastStateUpdate > 0)) return console.log('Skipping state update because local is up to date with database.');

      parseWheelData(wheelData, id);
    });
  }, [lastStateUpdate, validId]);

  const uploadWheelData = useCallback(({ newOptions = currentOptions, newTask = currentTask, newTier = currentTier, newCompleted = completedTasks, id = wheelId, clearTask = false, angle = 0}) => {
    if (!process.env.REACT_APP_BUCKET) {
      throw new Error(`Error connecting to AWS: No bucket provided. This is a developer problem.`);
    }

    if (!id) throw new Error("No wheel id found. Try refreshing the page.");

    setUploadSuccessful(false);
    setLastStateUpdate(Date.now());

    let payload: WheelData = {
      currentOptions: newOptions,
      currentTier: newTier,
      currentTask: clearTask ? undefined : newTask,
      completedTasks: newCompleted,
      angle: angle,
      uploadTime: Date.now()
    };

    if(process.env.NODE_ENV === "development") {
      localStorage.setItem(id, JSON.stringify(payload));
      setUploadSuccessful(true);

      return;
    }

    s3.putObject({ Bucket: process.env.REACT_APP_BUCKET, Key: `${id}.json`, Body: JSON.stringify(payload) }, (err, data) => {
      if (err) {
        setUploadSuccessful(false);
        throw err;
      }

      setUploadSuccessful(true);
    });
  }, [currentOptions, currentTask, currentTier, completedTasks, wheelId])

  const handleTaskGenerated = (task: Task, angle: number, skipped = false) => {
    setCurrentTask(task);

    if (task || skipped) uploadWheelData({ newTask: task, clearTask: skipped, angle: angle });
  }

  const handleWheelPopulated = (tasks: Task[]) => {
    const oldTasks = _.pluck([...currentOptions], 'referenceId');
    const newTasks = _.pluck([...tasks], 'referenceId');

    setCurrentOptions(tasks);

    if (_.difference(newTasks, oldTasks).length === 0) return;
    uploadWheelData({ newOptions: tasks });
  }

  const clearId = () => {
    if(process.env.NODE_ENV !== "development") localStorage.clear();
    else localStorage.removeItem(`wheelId`);
    setCompletedTasks([]);
    setCurrentOptions([]);
    setCurrentTask(undefined);
    setCurrentTier(1);
    setWheelId('');
    setValidId(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!wheelId || uploadSuccessful) return;
      uploadWheelData({});
    }, 60000);
    return () => clearInterval(interval);
  }, [wheelId, uploadSuccessful, uploadWheelData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!wheelId || (Date.now() - lastStateUpdate) < 1800 * 1000) return console.log('Not getting state, recent action on webpage');
      getWheelData(wheelId);
    }, 600000);
    return () => clearInterval(interval);
  }, [wheelId, lastStateUpdate, getWheelData]);

  useEffect(() => {
    if (!validId) {
      const localId = localStorage.getItem('wheelId');

      if (!localId) return setLoading(false);

      getWheelData(localId);

      let timeout: NodeJS.Timeout | null;

      if(loading) timeout = setTimeout(() => {   
        if(loading && !validId){
          clearId();
          setShowError(true);
        }
      }, 5000);

      return () => { if(timeout) clearTimeout(timeout) };
    }

    recalculatePoints();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [validId, loading, clearId]);

  return (
    <div className="App">
      <div className="title">
        {validId && <button className="title__back" onClick={() => clearId()}>←</button>}
        <h1>WHEELISM</h1>
        {validId &&
          <div className="wheelId">WHEEL ID:
            <span
              className={`wheelId__hide ${showId ? 'active' : ''}`}
            >
              {wheelId}
            </span>
            <button
              className="wheelId__toggle"
              onClick={() => setShowId(!showId)}
            >
              {showId && <img src={hide} alt="hide id"></img>}
              {!showId && <img src={show} alt="show id"></img>}
            </button>
          </div>
        }
      </div>
      {
        loading &&
        <div className="loader">
          <div className="lds-hourglass"></div>
        </div>
      }
      {
        !validId && !loading &&
        <div className="frontPage">
          <div className="form">
            <form className="form__existing">
              <input
                type="text"
                onChange={(e) => setWheelId(e.target.value)}
                value={wheelId}
                placeholder="Enter wheel id..."
                maxLength={8}
                required={true}
              ></input>
              {showError && <div className="error">Could not find wheel with that ID.</div>}
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  getWheelData(wheelId);
                }
                }>
                Submit</button>
            </form>

            <div className="form__divider">-OR-</div>

            <button onClick={() => createWheel()}>New Wheel</button>
          </div>
            <Tabs>
              <TabList className="tabList">
                <Tab>Rules</Tab>
                <Tab>Rewards</Tab>
                <Tab>Quests</Tab>
                <Tab>Diaries</Tab>
                <Tab>Items</Tab>
                <Tab>Pets</Tab>
                <Tab>Clues</Tab>
                {process.env.NODE_ENV === 'development' && <Tab>Balancing</Tab>}
              </TabList>

              <div className="panelContainer">
                <TabPanel className="panel">
                  <Rules></Rules>
                </TabPanel>
                <TabPanel className="panel">
                  <Rewards></Rewards>
                </TabPanel>
                <TabPanel className="panel">
                  <QuestPanel readOnly={true}></QuestPanel>
                </TabPanel>
                <TabPanel className="panel">
                  <DiaryPanel readOnly={true} ></DiaryPanel>
                </TabPanel>
                <TabPanel className="panel">
                  <ItemPanel readOnly={true}></ItemPanel>
                </TabPanel>
                <TabPanel className="panel">
                  <PetPanel readOnly={true}></PetPanel>
                </TabPanel>
                <TabPanel className="panel">
                  <CluePanel readOnly={true}></CluePanel>
                </TabPanel>
                {process.env.NODE_ENV === 'development' && <TabPanel className="panel"><Balance></Balance></TabPanel>}
              </div>
            </Tabs>
        </div>
      }
      {
        validId && !loading &&
        <>
          <div className="mainContainer">
            <button className="mute" onClick={() => setIsMuted(!isMuted)}>
              <img src={isMuted ? mute : unmute} alt="toggle mute" className="mute__icon"></img>
            </button>
            <div className="wheelContainer">
              <Wheel
                wheelSize={6}
                onTaskComplete={(task: Task, gotPet=false) => handleChecked(task, gotPet)}
                onWheelPopulated={(tasks: Task[]) => handleWheelPopulated(tasks)}
                onTaskGenerated={(task: Task, angle: number, skipped = false) => handleTaskGenerated(task, angle, skipped)}
                completedTasks={completedTasks}
                points={points}
                currentTier={currentTier}
                currentOptions={currentOptions}
                currentTask={currentTask}
                isMuted={isMuted}
                wheelAngle={wheelAngle}
              ></Wheel>
            </div>
            <div className="tierContainer">
              <div className="tierInfo">
                <label htmlFor="tier" className="tierInfo__label">Tier:</label>
                { process.env.NODE_ENV === 'development' && 
                  <input
                    type="number"
                    id="tier"
                    className="tierInfo__number"
                    min={1}
                    max={6}
                    value={currentTier}
                    onChange={(e) => setCurrentTier(+e.target.value)}
                  ></input>
                }
                { process.env.NODE_ENV !== 'development' && <span>{ currentTier }</span> }
              </div>
              <div className="tierProgress" data-tip data-for="percent">
                {
                  currentTier === 1 &&
                  Math.floor(points / Weighting[currentTier - 1] * 100)
                }
                {
                  currentTier > 1 &&
                  Math.floor((points - Weighting[currentTier - 2]) / (Weighting[currentTier - 1] - Weighting[currentTier - 2]) * 100)
                }
                %

                <div className="tooltip" >?</div>
                <ReactTooltip id='percent' delayShow={1000}>
                  The progress towards the next tier. Not all tasks need be completed to move on. 
                  <br/>Points: {points}/{Weighting[currentTier - 1]}
                </ReactTooltip>
              </div>
            </div>
          </div>
          <Tabs>
            <TabList className="tabList">
              <Tab>Rules</Tab>
              <Tab>Rewards</Tab>
              <Tab>Quests</Tab>
              <Tab>Diaries</Tab>
              <Tab>Items</Tab>
              <Tab>Pets</Tab>
              <Tab>Clues</Tab>
              {process.env.NODE_ENV === 'development' && <Tab>Balancing</Tab>}
            </TabList>

            <div className="panelContainer">
              <TabPanel className="panel">
                <Rules></Rules>
              </TabPanel>
              <TabPanel className="panel">
                <Rewards></Rewards>
              </TabPanel>
              <TabPanel className="panel">
                <QuestPanel
                  onMarkedComplete={(quest: Quest) => handleChecked({
                    displayText: quest.name,
                    category: 'Quest',
                    referenceId: quest.name
                  })}
                  completed={completedTasks.filter(task => task.category === 'Quest')}
                  currentOptions={currentOptions}
                ></QuestPanel>
              </TabPanel>
              <TabPanel className="panel">
                <DiaryPanel
                  onMarkedComplete={(step: DiaryStep) => handleChecked({
                    category: 'Diary',
                    referenceId: step.id,
                    displayText: step.task
                  })}
                  completed={completedTasks.filter(task => task.category === 'Diary')}
                  currentOptions={currentOptions}
                ></DiaryPanel>
              </TabPanel>
              <TabPanel className="panel">
                <ItemPanel
                  onMarkedComplete={(item: Item) => handleChecked({
                    category: 'Item',
                    referenceId: item.id,
                    displayText: item.name
                  })}
                  completed={completedTasks.filter(task => task.category === 'Item')}
                  currentOptions={currentOptions}
                ></ItemPanel>
              </TabPanel>
              <TabPanel className="panel">
                <PetPanel
                  onMarkedComplete={(pet: Pet) => handleChecked({
                    category: 'Pet',
                    referenceId: pet.name,
                    displayText: pet.name
                  }, true)}
                  completed={completedTasks.filter(task => task.category === 'Pet')}
                  currentOptions={currentOptions}
                ></PetPanel>
              </TabPanel>
              <TabPanel className="panel">
                <CluePanel
                  onMarkedComplete={(clue: ClueTier) => handleChecked({
                    category: 'ClueTier',
                    referenceId: clue.difficulty.toString(),
                    displayText: clue.name
                  }, true)}
                  completed={completedTasks.filter(task => task.category === 'ClueTier')}
                  currentOptions={currentOptions}
                ></CluePanel>
              </TabPanel>
              {process.env.NODE_ENV === 'development' && <TabPanel className="panel"><Balance></Balance></TabPanel>}
            </div>
          </Tabs>
        </>
      }
    </div>
  );
}

export default App;