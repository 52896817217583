import React from 'react';
import _ from 'underscore';

import './DiaryPanel.scss';
import { DiarySteps } from '../data/diaries';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Table from '../Table/Table';
import { Task } from '../types';

interface Props {
  onMarkedComplete?: Function;
  completed?: Task[];
  currentOptions?: Task[];
  readOnly?: boolean;
}

function DiaryPanel(props: Props) {
  return (
    <div className="DiaryPanel">
      <Tabs>
        <TabList>
          <Tab>All Tiers</Tab>
          <Tab>Tier 1</Tab>
          <Tab>Tier 2</Tab>
          <Tab>Tier 3</Tab>
          <Tab>Tier 4</Tab>
          <Tab>Tier 5</Tab>
        </TabList>

        {
          [0,1,2,3,4,5].map(num =>
            <TabPanel key={num} className="DiaryPanel__container">
              <Table
                readOnly={props.readOnly || false}
                tableHeaders={['Task', 'Region', 'Quest Reqs', 'Difficulty', 'Tier']}
                indexes={['task', 'diary', 'quests', 'difficulty', 'tier']}
                sortableCols={[true, true, false, true, true]}
                idIndex="id"
                data={DiarySteps}
                tier={num}
                onMarkedComplete={(task: any) => props.onMarkedComplete ? props.onMarkedComplete(task) : null}
                completedIds={props.completed ? _.pluck(props.completed, 'referenceId'): []}
                colSizes={['lgCell', 'mdCell', 'mdCell', 'smCell', 'smCell']}
                currentOptions={props.currentOptions}
              ></Table>
            </TabPanel>
          )
        }
      </Tabs>
    </div>
  );
}

export default DiaryPanel;
