import React, { useEffect, useRef, useState } from "react";
import useSound from 'use-sound';
import boopSfx from './boop.mp3';
import ReactTooltip from 'react-tooltip';

import { Task, WheelTask } from '../types';

interface Props {
    options: Task[];
    onSpun: Function;
    isMuted: boolean;
    spinEnabled: boolean;
    newestOption: string;
    wheelAngle?: number;
};

const SEG_COLOR_MAP = {
    'Quest': '#0099ff',
    'Diary': '#00c210',
    'Item': '#ff9d00',
    'Clue': '#fc2c03',
    'Challenge': '#ff00f2',
    'Pet': '#9000ff',
    'ClueTier': '#000'
}

const COLORS = {
    'Item': [`#ffa600`, `#ff9a00`, `#ff9000`, `#ff8000`, `#ff6f00`],
    'Quest': [`#00aaff`, `#0099ff`, `#008cff`, `#007bff`],
    'Diary': [`#00ab0e`, `#009c00`, `#008a0b`, `#00800b`],
    'Clue': [`#fc0303`, `#d90f00`, `#b90e00`],
    'Challenge': ['#ff00f2'],
    'Pet': ['#9000ff'],
    'ClueTier': ['#000']
}

function WheelCanvas(props: Props) {
    const [wheelAngle, setWheelAngle] = useState(props.wheelAngle || 0);
    const [spinning, setSpinning] = useState(false);
    const [volume, setVolume] = useState(0.25);
    const [play] = useSound(boopSfx, { volume: volume });
    const size = useWindowSize();
    const [options, setOptions] = useState<WheelTask[]>([]);
    const isMounted = useRef(false)

    const canvasEl = useRef<HTMLCanvasElement>(null);
    const tot = props.options.length;
    const dia = Math.min(Math.max(size.width - 500, 500), 500);
    const rad = (dia-10) / 2;
    const PI = Math.PI;
    const TAU = 2 * PI;
    const arc = TAU / tot;

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    useEffect(() => {
        if(props.isMuted) setVolume(0)
        else setVolume(0.25);
    }, [props.isMuted, setVolume]);

    useEffect(() => {
        let numCategories = {
            'Quest': 0,
            'Diary': 0,
            'Item': 0,
            'Clue': 0,
            'Challenge': 0,
            'Pet': 0,
            'ClueTier': 0
        }

        let newOptions: WheelTask[] = props.options.map(op => {
            let task = {
                task: op,
                color: COLORS[op.category][numCategories[op.category]],
            }
            numCategories[op.category]++;

            return task;
        })

        newOptions.sort((a, b) => {
            if (a.color && b.color) {
                return a.task.category > b.task.category ? 1 : b.task.category > a.task.category ? -1 : a.color > b.color ? 1 : -1;
            }
            return a.task.category >= b.task.category ? 1 : -1;
        });

        setOptions(newOptions);
    }, [props.options]);

    useEffect(() => {
        if (!canvasEl.current) return;
        canvasEl.current.style.transform = `rotate(${wheelAngle - PI / 2}rad)`;

        const ctx: CanvasRenderingContext2D = canvasEl.current.getContext('2d')!;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        let numCategories = {
            'Quest': 0,
            'Diary': 0,
            'Item': 0,
            'Clue': 0,
            'Challenge': 0,
            'Pet': 0,
            'ClueTier': 0
        }

        function drawSector(option: Task, color: string, i: number) {
            const ang = arc * i;

            ctx?.save();
            // COLOR
            ctx?.beginPath();
            ctx.translate(5, 5);
            ctx.fillStyle = color || SEG_COLOR_MAP[option.category];
            ctx.strokeStyle = props.newestOption === option.referenceId ? "#F2FF48" : "#000";
            ctx.lineWidth = 2.5;
            ctx?.moveTo(rad, rad);
            ctx?.arc(rad, rad, rad, ang, ang + arc);
            ctx?.lineTo(rad, rad);
            ctx?.fill();
            ctx.stroke();
            // TEXT
            ctx.lineWidth = 3;
            const moveAng = i === 0 ? ang : ang/i;
            const move = ang < Math.PI ? [rad+ang, rad-moveAng] : [rad-(ang-Math.PI), rad-(ang-Math.PI)];
            ctx?.translate(move[0], move[1]);
            ctx?.rotate(ang + arc / 2);
            ctx.textAlign = "right";
            
            ctx.fillStyle = "#fff";
            let fontSize = 30;
            const minSize = 14;
            let text = option.displayText
            if(text.length > 35) text = text.slice(0, 32).concat('...');
            ctx.font = `${fontSize}px Roboto`;
            while (ctx.measureText(text).width > rad - 40 || fontSize === minSize) {
                fontSize--;
                ctx.font = `${fontSize}px Roboto`;
            }
            ctx.strokeStyle = "#000";
            ctx?.strokeText(text, rad - 10, 10);
            ctx?.fillText(text, rad - 10, 10);
            //
            ctx?.restore();
        };

        let newestTask;
        
        options.forEach((option, i) => {
            if(option.task.referenceId === props.newestOption) return newestTask = [option.task, option.color, i];
            drawSector(option.task, option.color, i);
        });
        if(newestTask) drawSector(newestTask[0], newestTask[1], newestTask[2]);


    }, [options, props.newestOption, arc, rad, PI, wheelAngle]);

    const spinWheel = () => {
        if (!canvasEl.current) return;

        setSpinning(true);

        const ctx: CanvasRenderingContext2D = canvasEl.current.getContext('2d')!;
        const friction = 0.99; // 0.995=soft, 0.99=mid, 0.98=hard
        let angVel = Math.random() * (0.35 - 0.25) + 0.25; // Angular velocity
        let ang = wheelAngle // Angle in radians

        var fps = 60;
        var now;
        var then = performance.now();
        var interval = 1000/fps;
        var delta;

        const getIndex = () => Math.floor(tot - ang / TAU * tot) % tot;

        let currentIndex = getIndex();

        function rotate() {
            const sector = options[getIndex()];
            ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;

            if(currentIndex !== getIndex()) play();

            currentIndex = getIndex();

            if (angVel === 0) {
                if(!isMounted.current) return;

                setWheelAngle(ang);
                setSpinning(false);
                props.onSpun(sector.task, ang);
            }
        }

        function frame() {
            if (!angVel) return;
            angVel *= friction; // Decrement velocity by friction
            if (angVel < 0.001) angVel = 0; // Bring to stop
            ang += angVel; // Update angle
            ang %= TAU; // Normalize angle
            rotate();
        }

        function engine() {
            if(!isMounted.current) return;

            requestAnimationFrame(engine);


            now = performance.now();
            delta = now - then;
            
            if (delta > interval) {
                frame();
                then = now - (delta % interval);
            }

        }
        engine(); // Start engine
    }

    return (
        <>
            <div className="wheel" data-tip data-for="wheel">
                <div className="wheel__arrowHead">▼</div>
                <button className="wheel__spin" onClick={() => spinWheel()} disabled={!props.spinEnabled || spinning}>
                    {!props.spinEnabled &&
                        <div className="wheel__disclaimer">
                            Complete the current task before spinning again
                        </div>
                    }
                    <canvas ref={canvasEl} width={dia} height={dia}></canvas>
                </button>
            </div>
            <ReactTooltip id="wheel" place="left" effect="solid" delayShow={250}>
                <ul className="tooltip">
                {
                    options.map(task => <li key={task.task.referenceId} className={task.task.category}>{ task.task.displayText }</li>)
                }
                </ul>
            </ReactTooltip>
        </>
    )
}

// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
  
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      
      // Add event listener
      window.addEventListener("resize", handleResize);
      
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
  
    return windowSize;
  }

export default WheelCanvas;
